import React, { Component, Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col, Card, Form, FormGroup, ButtonGroup, Button } from 'react-bootstrap';
import ReactToPrint from 'react-to-print';

import config from '../../../../config.js';

import { getLoggedUser } from '../../UserActions.js';
import { getUserGroup } from '../../UserGroupActions.js';
import { memoizedGetProducts } from '../../../Product/ProductActions.js';
import { getTranslation } from '../../../Intl/IntlHelper.js';
import { getReferral } from '../../../App/AppActions.js';

import LazyLoader from '../../../../components/Content/LazyLoader.js';
import AppLogo from '../../../App/components/view/AppLogo.js';
import Icon from '../../../../components/Content/Icon.js';
import OrderForm from '../../../Order/components/view/OrderForm.js';

function UserGroupPanel({ intl, user = null, userGroup = null, products = [], referral = null }) {
	const [isMounted, setIsMounted] = useState(false);
	const [hidePrice, setHidePrice] = useState(false);
	const orderFormRef = useRef();
	const productListRef = useRef();

	useEffect(() => {
		setIsMounted(true);
	}, []);

    const renderGroupContent = (forceGroup = null) => {
        const groupIdentifier = forceGroup || (userGroup || {}).identifier;
        switch(groupIdentifier) {
            case 'asso':
            case 'ce':
                return (
                    <Fragment>
                        <Row className="g-0 mb-2">
                            <Col>
                                <span className="d-block h2 w-100 w-lg-75">{getTranslation(userGroup, 'name', intl.locale)}</span>
                            </Col>
                            <Col xs="3">
								<AppLogo suffix="D-alt" className="d-none d-lg-inline w-100" loading="lazy" />
                            </Col>
                        </Row>

                        {/* <Row>
                            <Col>
                                <span className="d-block mb-1">
                                    <FormattedMessage id="orderCatalogText" defaultMessage="Would you like to receive our catalog?" />
                                </span>
                            </Col>
                            <Col className="text-end">
                                <Button variant="superlight" outline size="sm" as={Link} to={`/${intl.locale}/page/contact/ce?message=Demande de catalogue&autoSubmit=1`}><Icon icon="mail" /> <FormattedMessage id="pageContactUsLink" defaultMessage="Contact us" /></Button>
                            </Col>
                        </Row>
                        <hr /> */}
                        <Row>
                            <Col>
                                <span className="d-block mb-1">
                                    <FormattedMessage id="orderPosterDownloadText" defaultMessage="Download the posters to display in your common rooms" />
                                </span>
                            </Col>
                            <Col className="text-end">
                                <ButtonGroup size="sm">
                                    {[1, 2, 3].map(index => (
                                        <Button key={index} variant="outline-superlight" as="a" href={`https://s3.eu-west-3.amazonaws.com/assets.directos.eu/communication/${groupIdentifier}/${groupIdentifier}-affiche-${index}.pdf`} target="_blank"><FormattedMessage id="orderPosterName" defaultMessage="Poster n°{index}" values={{ index }} /></Button>
                                    ))}
                                </ButtonGroup>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col xs="12" md="8">
                                <span className="d-block mb-1">
                                    <FormattedMessage id="orderFormDownloadText" defaultMessage="Download the order form to distribute to your members" />
                                </span>
                                <FormGroup>
                                    <Form.Label>
                                        <Form.Check type="checkbox" checked={hidePrice} onChange={event => setHidePrice(event.target.checked)} />
                                        <FormattedMessage id="orderFormHidePriceLabel" defaultMessage="Hide prices on form?" />
                                    </Form.Label>
                                </FormGroup>
                            </Col>
                            <Col md="4" className="text-end">
                                <ReactToPrint
                                    trigger={() => {
                                        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                        // to the root node of the returned component as it will be overwritten.
                                        return <Button variant="outline-superlight"  size="sm" as="a" href="#" className="rounded-sm"><Icon icon="software-download" /> <FormattedMessage id="download" defaultMessage="Download" /></Button>;
                                        }}
                                        content={() => orderFormRef.current}
                                />
                            </Col>
                        </Row>

                        <div className="d-none">
                            <OrderForm ref={orderFormRef} products={products} user={user} userGroup={userGroup} hidePrice={hidePrice} referral={referral} locale={intl.locale} />
                        </div>
                    </Fragment>
                );

            case 'pro':
            case 'semi-pro':
            case 'mini-pro':
                return (
                    <Fragment>
                        <Row className="g-0">
                            <Col>
                                <span className="d-block h2 w-100 w-lg-75"><FormattedMessage id="contactPageTitle" defaultMessage="Contact us" /></span>
                            </Col>
                            <Col xs="3">
								<AppLogo suffix="D-alt" className="d-none d-lg-inline w-100" loading="lazy" />
                            </Col>
                        </Row>
                        <ButtonGroup className="d-flex justify-content-between mt-5 rounded-sm">
                            <Button variant="superlight" as="a" href={`tel:${config.application.phone}`} rel="nofollow"><Icon icon="phone" /> {config.application.phone}</Button>
                            <Button variant="superlight" as={Link} to={`/${intl.locale}/page/contact/pro`}><Icon icon="mail" /> <FormattedMessage id="contactPageTitle" defaultMessage="Contact us" /></Button>
                        </ButtonGroup>
                        <hr />
                        <Row>
                            <Col xs="12" md="8">
                                <span className="d-block mb-1">
                                    <FormattedMessage id="orderFormDownloadText" defaultMessage="Download the products prices" />
                                </span>
                            </Col>
                            <Col md="4" className="text-end">
                                <ReactToPrint
                                    trigger={() => {
                                        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                        // to the root node of the returned component as it will be overwritten.
                                        return <Button variant="outline-superlight" size="sm" as="a" href="#" className="rounded-sm"><Icon icon="software-download" /> <FormattedMessage id="download" defaultMessage="Download" /></Button>;
                                        }}
                                        content={() => productListRef.current}
                                />
                            </Col>
                        </Row>

                        <div className="d-none">
                            <OrderForm ref={productListRef} products={products} user={user} userGroup={userGroup} hidePrice={false} hideForm hidePacks referral={referral} locale={intl.locale} />
                        </div>
                        <span className="d-block h2 w-100 w-lg-75 mt-3">{getTranslation(userGroup, 'name', intl.locale)}</span>
                    </Fragment>
                );

            case 'individual':
            default:
                return (
                    <Row className="g-0">
                        <Col>
                            <span className="d-block h2 w-100 w-lg-75"><FormattedMessage id="appProContactTitle" defaultMessage="Are you a PRO?" /></span>
                            <span className="d-block mb-3">
                                <FormattedMessage id="appProContactText1" defaultMessage="Resellers, grocers, associations, works councils, ..." />
                            </span>
                            <Button variant="superlight" size="lg" as={Link} to={`/${intl.locale}/page/contact/pro`} className="rounded-sm"><Icon icon="mail" /> <FormattedMessage id="contactPageTitle" defaultMessage="Contact us" /></Button>
                            <p className="h6 mt-2"><FormattedMessage id="appProContactText2" defaultMessage="and benefit from customer benefits!" /></p>
                        </Col>
                        <Col xs="3">
							<AppLogo suffix="D-alt" className="d-none d-lg-inline w-100" loading="lazy" />
                        </Col>
                    </Row>
                );
        }
    };

	return isMounted && (
		<Card body bg="danger" className="me-lg-3 p-3 p-lg-5 text-white">
			{renderGroupContent()}
			{true && process.env.NODE_ENV === 'development' && (
				<Fragment>
					{renderGroupContent('ce')}
				</Fragment>
			)}
		</Card>
	);
}

function mapStateToProps(store, props) {
    return {
        user: getLoggedUser(store),
        userGroup: getUserGroup(store),
        products: memoizedGetProducts(store, { isActive: true, isVariation: false }),
        referral: getReferral(store),
    };
}

UserGroupPanel.propTypes = {
    intl: PropTypes.object.isRequired,
    user: PropTypes.object,
    userGroup: PropTypes.object,
    products: PropTypes.arrayOf(PropTypes.object),
    referral: PropTypes.string,
};

export default connect(mapStateToProps)(injectIntl(UserGroupPanel));
