import { createSelector } from 'reselect';
import callApi from '../../util/apiCaller.js';
import { getPriceFromCent, getPriceCent } from '../../util/price.js';
import { toDate } from '../../util/date.js';

import { getCartItemFromProduct, getSubtotal } from '../Cart/CartActions.js';
import { USER_GROUP_DEFAULT } from '../User/UserGroupActions.js';
import { getUserOption } from '../User/UserActions.js';
import { addError } from '../Error/ErrorActions.js';

// Export Constants
export const SET_VOUCHERS = 'SET_VOUCHERS';
export const APPLY_VOUCHER = 'APPLY_VOUCHER';
export const REMOVE_VOUCHER = 'REMOVE_VOUCHER';
export const REMOVE_VOUCHERS = 'REMOVE_VOUCHERS';

// Export Actions

export function getVouchersRequest() {
    return dispatch => {
        return callApi('vouchers').then(res => {
            if(res.vouchers) {
                dispatch(setVouchers(res.vouchers));
            }
            return res.vouchers;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function getPublicVouchersRequest(userId = null) {
    return dispatch => {
        return callApi(`vouchers/public${userId ? `/${userId}` : ''}`).then(res => {
            if(res.vouchers) {
                dispatch(setVouchers(res.vouchers));
            }
            return res.vouchers;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function editVoucherRequest(voucher) {
    return dispatch => {
        return callApi('voucher/edit', 'post', { voucher }).then(res => {
            if(res.voucher) {
                dispatch(setVouchers([res.voucher]));
            }
            return res.voucher;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function applyVoucherRequest(code, isPrioritary = false) {
    return dispatch => {
        return callApi('voucher/apply', 'post', { voucher: { code: `${code || ''}`.toUpperCase() } }).then(res => {
            if(res.voucher) {
                dispatch(applyVoucher(res.voucher, isPrioritary));
            }
            return res.voucher;
        }).catch(error => {
            // dispatch(addError(error));
            return null;
        });
    };
}

export function removeVoucherRequest(voucherId) {
    return dispatch => {
        return callApi('voucher/remove', 'delete', { voucher: { _id: voucherId } }).then(res => {
            res && res.ok && dispatch(getVouchersRequest());
            return res && res.ok;
        }).catch(error => {
            // dispatch(addError(error));
            return null;
        });
    };
}

export function getVoucher(store, voucherId) {
    return store.vouchers.data.find(voucher => voucher._id === voucherId);
}

export function getVoucherByCode(store, voucherCode) {
    return store.vouchers.data.find(voucher => {
        return voucher.code === (voucherCode && voucherCode.toUpperCase());
    });
}

// export function getVouchers(store, isActive = null) {
//     let vouchers = store.vouchers.data;
//     if(isActive !== null) {
//         vouchers = vouchers.filter(voucher => voucher.isActive === isActive);
//     }
//     return vouchers;
// }
export const getVouchers = createSelector(
	[
		store => store.vouchers.data,
		(store, isActive) => isActive,
	],
	(vouchers, isActive = null) => {
		if(isActive !== null) {
			vouchers = vouchers.filter(voucher => voucher.isActive === isActive);
		}
		return vouchers;
	},
);

// export function checkVoucherConditionsFromStore(store, voucher, user = null) {
//     const subtotal = getSubtotal(getCartItems(store));
//     user = user || getLoggedUser(store);
//     const userGroup = getUserGroup(store);
//     const orders = user ? getOrdersByUser(store, user._id, ['onpreparation', 'shipped', 'complete']) : [];
//     return checkVoucherConditions(voucher, user, userGroup, orders, subtotal /* , !!getCartSubscription(store) */);
// }

export function checkVoucherConditions(voucher, user = null, userGroup = null, orders = [], cart = {}) {
    // console.log('checkVoucherConditions', voucher, user, userGroup, orders, cart);
    return voucher && (voucher.conditions || []).every(condition => {
        let value = '';
        let conditionValue = condition.value;
        // console.log(condition);

        switch(condition.key) {
            case 'use':
                value = voucher.useCount;
                break;

            case 'usePerUser':
                value = orders ? orders.filter(order => ['onpreparation', 'shipped', 'complete', 'archived'].includes(order.status)).filter(order => order.vouchers && order.vouchers.find(voucherUsed => voucherUsed && voucherUsed._id === voucher._id)).length : 0;
                break;

            case 'ordersCount':
                value = (orders || []).length;
                break;

            case 'user':
                value = user && user._id;
                break;

            case 'userGroup':
                value = (userGroup || {}).identifier || userGroup || USER_GROUP_DEFAULT;
                break;

            case 'subtotal':
                value = getSubtotal(cart.items, null, !getUserOption(user, 'exemptVAT')) || 0;
                break;

            case 'suborder':
                value = ((cart.multiorder || {}).user && (cart.multiorder || {}).order) || (cart.options || {}).isMultiorder ? '1' : '0';
                conditionValue = parseInt(condition.value, 10) ? '1' : '0';
                break;

            case 'subscription':
                value = cart.subscription && (cart.subscription.isActive || (cart.subscription.periodUnit && cart.subscription.periodValue)) ? '1' : '0';
                break;

            case 'date':
                value = toDate();
                conditionValue = toDate(condition.value);
                break;

            default:
                break;
        }

        let result = false;
        switch(condition.operator) {
            case '==':
                result = value === conditionValue;
                break;

            case '!=':
                result = value !== conditionValue;
                break;

            case '<=':
                result = value <= conditionValue;
                break;

            case '<':
                result = value < conditionValue;
                break;

            case '>=':
                result = value >= conditionValue;
                break;

            case '>':
                result = value > conditionValue;
                break;

            default:
                break;
        }
        // !result && console.log(condition.key, `${value}(${condition.value}) ${condition.operator} ${conditionValue}`, result);
        return result;
    });
}

export function getVoucherDiscountValue(voucher, items, shippingFee, options = {}) {
    return getPriceFromCent((voucher ? (voucher.actions || []) : []).reduce((discount, action) => {
        if(!['product', 'conditionRemover'].includes(action.key)) {
            if(action.type === 'fixed') {
                return discount + getPriceCent(action.value);
            } if(action.type === 'percentage') {
                let reference = 0;
                switch(action.key) {
                    case 'subtotal':
                        reference = (items || []).reduce((total, item) => {
                            if(item.basePriceTTC && parseFloat(item.basePriceTTC) !== item.priceTTC) { // Discount on product => Check witch discount is better for customer
                                const itemDiscountPercentage = 100 - Math.round((item.priceTTC * 100) / item.basePriceTTC);
                                if(itemDiscountPercentage > parseFloat(action.value)) {
                                    return total;
                                }
                            }
                            return total + (parseFloat(item[options.exemptVAT ? 'priceHT' : 'priceTTC']) * item.quantity);
                        }, 0);
                        break;

                    case 'shipping':
                        reference = shippingFee;
                        break;

                    default:
                        break;
                }

                // console.log('Voucher calculation', action.type, action.key, `(${action.value} * ${reference}) / 100 = ${(action.value * reference) / 100}`);
                return discount + getPriceCent((action.value * reference) / 100);
            }
        }
        return discount;
    }, 0));
}

export function getAvailableVoucherConditionKeys() {
    return ['use', 'usePerUser', 'user', 'userGroup', 'subtotal', 'date', 'ordersCount', 'suborder', 'subscription'];
}

export function getAvailableConditionOperators() {
    return ['<', '<=', '==', '>=', '>', '!='];
}

export function getAvailableActionKeys() {
    return ['subtotal', 'shipping', 'product', 'conditionRemover'];
}

export function getAvailableActionTypes() {
    return ['fixed', 'percentage'];
}

export function getVoucherProductOfferItem(product, locale) {
    return getCartItemFromProduct(
        product,
        1,
        locale,
        null,
        {
            priceTTC: 0,
            priceHT: 0,
            forceUseItemData: true,
            flags: ['offered'],
        },
    );
}

export function setVouchers(vouchers) {
    return {
        type: SET_VOUCHERS,
        vouchers,
    };
}

export function applyVoucher(voucher = {}, isPrioritary = false) {
    return {
        type: APPLY_VOUCHER,
        voucher,
        isPrioritary,
    };
}

export function removeVoucher(code) {
    return {
        type: REMOVE_VOUCHER,
        code,
    };
}

export function removeVouchers() {
    return {
        type: REMOVE_VOUCHERS,
    };
}
