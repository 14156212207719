import { enabledLanguages, localizationData, defaultLanguage } from '../../../Intl/setup.js';
import { SWITCH_LANGUAGE } from './IntlActions.js';

export const initialState = {
    isInit: false,
    locale: defaultLanguage,
    enabledLanguages,
    messages: (localizationData[defaultLanguage] || {}).messages || {},
};

const IntlReducer = (state = initialState, action) => {
    switch(action.type) {
        case SWITCH_LANGUAGE: {
            const { type, locale, messages } = action; // eslint-disable-line
                return {
                    ...state,
                    isInit: true,
                    locale,
                    messages,
                };
        }

        default:
            return state;
    }
};

export default IntlReducer;
