import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import exitIntent from 'exit-intent-quattro';

import { getCartItems } from '../../../Cart/CartActions.js';
import { getOrders } from '../../../Order/OrderActions.js';
import { getLoggedUser } from '../../../User/UserActions.js';

import withRouter from '../../../../components/Router/WithRouter.js';
import Icon from '../../../../components/Content/Icon.js';
import CmsPanel from '../../../Cms/components/content/CmsPanel.js';
import { isPopinActive, setPopinActive } from '../../AppActions.js';
import { getUserGroup, USER_GROUP_DEFAULT } from '../../../User/UserGroupActions.js';
import ReviewSummary from '../../../Review/components/view/ReviewSummary.js';

function ExitIntent({ dispatch, intl, user = null, userGroup = null, cartItems = [], orders = [], popinActive = false }) {
    // const [isInit, setIsInit] = useState(false);
	const [isDisplayed, setIsDisplayed] = useState(false);
	const [modalAlreadyDisplayed, setModalAlreadyDisplayed] = useState(false);

    useEffect(() => {
		// Initialize
		const removeExitIntent = exitIntent({
			edges: {
				top: true,
				right: false,
				bottom: false,
				left: false,
			},
			threshold: 5,
			maxDisplays: 5,
			eventThrottle: 100,
			onExitIntent: ({ side, position: { x, y } }) => {
				// console.log(`exit-intent triggered on ${side} side at position ${x}, ${y}`);
				if(!popinActive && !modalAlreadyDisplayed) {
					setIsDisplayed(true);
				}
			},
		});
		// Destroy
		// removeExitIntent();
	}, []);

    // componentDidUpdate(prevProps, prevState) {
    //     if(!this.state.init && (true || process.env.NODE_ENV === 'production')) {
    //         this.setState({
    //             init: true,
    //         }, () => {
    //             this.toggle(null, true);
    //         });
    //     }
    // }

    const toggle = (event, display = null) => {
        const displayModal = display !== null ? display : !isDisplayed;
		setIsDisplayed(displayModal);
		dispatch(setPopinActive(displayModal));
		!displayModal && setModalAlreadyDisplayed(true);
    };

    const getNextStepLink = () => {
        if(cartItems.length) {
            return `/${intl.locale}/cart`;
        }
        return '#';
    };

	if(
		!modalAlreadyDisplayed
		&& (!user || process.env.NODE_ENV === 'development')
		&& userGroup?.identifier === USER_GROUP_DEFAULT
	) {
		return (
			<Modal size="lg" show={isDisplayed} onHide={toggle} centered contentClassName="bg-texture text-center">
				<div className="my-1 p-3">
					<span className="d-block h3 mb-3 border-bottom">🎁 Toujours plus de cadeaux !</span>
					<Row className="g-0 mb-0 mb-md-2">
						<Col xs="12" md="5">
							<p className="mb-0">
								<span className="h4">
									un Saucisson surprise<br />
									<strong className="text-danger">en CADEAU</strong>
								</span>
								<br />
								<em>Renseignez le code promo <strong className="h6">"SURPRISE"</strong> sur votre panier pour bénéficier de l'offre.</em>
								<br />
								dès 30€ d'achat !
							</p>
						</Col>
						<Col xs="12" md="2">
							<p className="m-0 mt-2 mb-2 mb-md-0 h4 text-center text-secondary">
								OU
							</p>
						</Col>
						<Col xs="12" md="5">
							<p>
								<span className="h4">
									une Noix de jambon <br />
									<strong className="text-danger">OFFERTE</strong>
								</span>
								<br />
								(nature ou au poivre, 400-500g)<br />
								<em>Ajoutée automatiquement à votre panier</em>
								<br />
								dès 90€ d'achat !<br />
							</p>
						</Col>
					</Row>
					<em><small>Offres valables une fois par client, non cumulables et reservées aux particuliers</small></em>
				</div>

				<span className="d-block h1 text-danger text-center m-0 p-0">+</span>

				<div className="my-1 p-3">
					<span className="d-block h1 text-secondary">Livraison <strong className="text-danger">OFFERTE</strong></span>
					<p>
						Faites vous livrer en point-relais <br />
						en France métropolitaine ou en Belgique,<br />
						<strong className="h5 text-danger">c'est GRATUIT !</strong><br />
						<em><small>Offre reservée aux particuliers, à partir de 20€ d'achat</small></em>
					</p>
				</div>

				{false && <CmsPanel slug="exit-intent" />}

				<div className="mb-1 px-3 text-center">
					<Button variant="danger" size="lg" className="w-100" as={Link} to={getNextStepLink()} onClick={toggle}>Poursuivre ma visite 👉</Button>
				</div>

				<ReviewSummary hideReviews hideTitle hideLogo />

				<Button size="sm" variant="link" className="w-100 mt-2" onClick={toggle}><Icon icon="close" /> <FormattedMessage id="close" defaultMessage="Close" /></Button>
			</Modal>
		);
	}

	if(process.env.NODE_ENV === 'development') {
		return <Button onClick={toggle}>Display exit intent popin {modalAlreadyDisplayed && 'already displayed'} {userGroup?.identifier !== USER_GROUP_DEFAULT && 'group not allowed'}</Button>;
	}

	return null;
}

function mapStateToProps(store, props) {
    return {
        user: getLoggedUser(store),
		userGroup: getUserGroup(store),
        cartItems: getCartItems(store),
        orders: getOrders(store),
        popinActive: isPopinActive(store),
    };
}

ExitIntent.propTypes = {
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    user: PropTypes.object,
    userGroup: PropTypes.object,
    cartItems: PropTypes.arrayOf(PropTypes.object),
    orders: PropTypes.arrayOf(PropTypes.object),
    popinActive: PropTypes.bool,
};

export default connect(mapStateToProps)(withRouter(injectIntl(ExitIntent)));
