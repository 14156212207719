import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import config from '../../../../../config.js';

import { getContentBlockItemValue } from '../../../CmsActions.js';

import HtmlComponent from '../../../../../components/Text/HtmlComponent.js';

function PanelShipping({ panel }) {
    const delivery = getContentBlockItemValue(panel.blocks, 'delivery');
    const shipping = getContentBlockItemValue(panel.blocks, 'shipping');
    const order = getContentBlockItemValue(panel.blocks, 'order');

    const renderPart = (content, icon, color) => {
        return (
            <Col xs="12" md="4">
                <Row className="g-0">
                    <Col xs="4" md="12">
                        <span className={`mb-2 icon-box icon-box-${color} icon-box-lg`}>
                            <img src={`${config.url}assets/images/home/${icon}.png`} alt={icon} width="45" height="45" className="mw-100 mh-100 d-inline-block" loading="lazy" />
                        </span>
                    </Col>
                    <Col className="pe-2 pe-md-0 text-end text-md-center">
                        <HtmlComponent text={content} />
                    </Col>
                </Row>
            </Col>
        );
    };

    return (
        <Row className="text-center">
            {renderPart(shipping, 'delivery-truck', 'successlight')}
            {renderPart(delivery, 'delivery-man-icon', 'warning')}
            {renderPart(order, 'box-love-icon', 'danger')}
        </Row>
    );
}

PanelShipping.propTypes = {
    panel: PropTypes.object.isRequired,
};

export default PanelShipping;
