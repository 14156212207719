export default [
  [0.5, 4.4],
  [1, 4.9],
  [2, 6.3],
  [3, 6.5],
  [4, 6.9],
  [5, 9.9],
  [7, 11.9],
  [10, 13.5],
  [15, 17.9],
  [20, 19.9],
  [30, 24.9],
];
