import React from 'react';
import PropTypes from 'prop-types';

import config from '../../../../config.js';

function AppLogo({ suffix = '', ...props }) {
    return (
        <picture>
            {false && <source srcSet={`${config.url}assets/images/logo${suffix ? `-${suffix}` : ''}.webp`} />}
            <img src={`${config.url}assets/images/logo${suffix ? `-${suffix}` : ''}.png`} alt={config.application.name} {...props} />
        </picture>
    );
}

AppLogo.propTypes = {
    suffix: PropTypes.string,
};

export default AppLogo;
