/* eslint-disable global-require */
import React, { lazy, Suspense, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Routes, Route, Navigate } from 'react-router';
import { Helmet } from 'react-helmet';

import { createBrowserRouter } from 'react-router-dom';
import authorization from './components/Auth/AuthorizationComponent.js';

import Loader from './components/Loader/Loader.js';

// Layout
import App from './modules/App/App.js';

// Generic pages

// Import of async pages with need of async data load
// import CmsPageAsync from './modules/Cms/pages/async/CmsPageAsync.js';

// Role Filter
// const adminAuth = authorization(['admin']);
// const customerAuth = authorization(['customer']);
// const loggedInAuth = authorization([]);

// Set permissions by page
// Config page
// const configPageAuth = admin(require('./modules/Config/pages/ConfigPage/ConfigPage').default);

// const ErrorPage = lazy(() => import('./modules/Error/pages/ErrorPage'));
// const TestPage = lazy(() => import('./modules/App/Test'));
import CmsPage from './modules/Cms/pages/view/CmsPage';

const ContactPage = lazy(() => import('./modules/Contact/pages/view/ContactPage'));
const PrinterPage = lazy(() => import('./modules/App/pages/print/PrintPage'));
// const ProductOrCmsPage = asyncComponent({
//     LoadingComponent: () => <Loader hideContent />,
//     resolve: () => System.import('./modules/App/pages/view/ProductOrCmsPage'),
// });

const UserRegisterPage = lazy(() => import('./modules/User/pages/auth/UserRegisterPage'));
const UserLoginPage = lazy(() => import('./modules/User/pages/auth/UserLoginPage'));
const UserProfilePage = lazy(() => import('./modules/User/pages/view/UserProfilePage'));
const AddressListPage = lazy(() => import('./modules/Address/pages/list/AddressListPage'));
const AddressEditPage = lazy(() => import('./modules/Address/pages/edit/AddressEditPage'));
const ProductListPage = lazy(() => import('./modules/Product/pages/list/ProductListPage'));
const CartPage = lazy(() => import('./modules/Cart/pages/view/CartPage'));
const OrderListPage = lazy(() => import('./modules/Order/pages/list/OrderListPage'));
const OrderViewPage = lazy(() => import('./modules/Order/pages/view/OrderViewPage'));
const AffiliationListPage = lazy(() => import('./modules/Affiliation/pages/list/AffiliationListPage'));

// Console components
const AdminDashboard = lazy(() => import('./modules/User/pages/dashboard/Dashboard'));
const UserListPage = lazy(() => import('./modules/User/pages/list/UserListPage'));
const UserEditPage = lazy(() => import('./modules/User/pages/edit/UserEditPage'));
const ShippingListPage = lazy(() => import('./modules/Shipping/pages/list/ShippingListPage'));
const ShippingEditPage = lazy(() => import('./modules/Shipping/pages/edit/ShippingEditPage'));
const ProductListAdminPage = lazy(() => import('./modules/Product/pages/list/ProductListAdminPage'));
const ProductStockPage = lazy(() => import('./modules/Product/pages/edit/ProductStockPage'));
const ProductEditPage = lazy(() => import('./modules/Product/pages/edit/ProductEditPage'));
const OrderListAdminPage = lazy(() => import('./modules/Order/pages/list/OrderListAdminPage'));
const OrderViewAdminPage = lazy(() => import('./modules/Order/pages/view/OrderViewAdminPage'));
const OrderAddPage = lazy(() => import('./modules/Order/pages/edit/OrderAddPage'));
// const OrderPreparePage = lazy(() => import('./modules/Order/pages/edit/OrderPreparePage'));
const VoucherListPage = lazy(() => import('./modules/Voucher/pages/list/VoucherListPage'));
const VoucherEditPage = lazy(() => import('./modules/Voucher/pages/edit/VoucherEditPage'));
const ReviewListAdminPage = lazy(() => import('./modules/Review/pages/list/ReviewListAdminPage'));
const ReviewEditPage = lazy(() => import('./modules/Review/pages/edit/ReviewEditPage'));
const CmsListPage = lazy(() => import('./modules/Cms/pages/list/CmsListPage'));
const CmsEditPage = lazy(() => import('./modules/Cms/pages/edit/CmsEditPage'));
const ConfigPage = lazy(() => import('./modules/Config/pages/view/ConfigPage'));

const baseRoute = '/:lang';

function getPath(path = '', isConsole = false) {
    return `${baseRoute}/${isConsole ? 'console' : ''}${path && isConsole ? '/' : ''}${path || ''}`;
}

const setAdminAuth = adminRoute => ({ auth: ['admin'], ...adminRoute, children: (adminRoute.children || []).map(setAdminAuth) });
const setAdminWrapper = adminRoute => ({ ...adminRoute, component: props => <Fragment><Helmet><body data-header-contrast="false" /></Helmet><adminRoute.component {...props} /></Fragment>, children: (adminRoute.children || []).map(setAdminWrapper) });

export const routesData = []
    .concat([
        // User mgmt
        {
            path: getPath('', true),
            component: AdminDashboard,
            auth: ['manager', 'admin'],
        },
        {
            path: getPath('users', true),
            component: UserListPage,
        },
        {
            path: getPath('user', true),
            component: UserEditPage,
            children: [
                {
                    path: ':id',
                    component: UserEditPage,
                },
            ],
        },
        {
            path: getPath('orders', true),
            component: OrderListAdminPage,
            auth: ['manager', 'admin'],
            children: [
                {
                    path: ':status',
                    component: OrderListAdminPage,
                    auth: ['manager', 'admin'],
                },
                {
                    path: 'user',
                    component: OrderListAdminPage,
                    auth: ['manager', 'admin'],
                    children: [
                        {
                            path: ':userId',
                            component: OrderListAdminPage,
                            auth: ['manager', 'admin'],
                        },
                    ],
                },
            ],
        },
        {
            path: getPath('order', true),
            component: OrderAddPage,
        },
        {
            path: getPath('order/:id', true),
            component: OrderViewAdminPage,
            auth: ['manager', 'admin'],
        },
        {
            path: getPath('shippings', true),
            component: ShippingListPage,
        },
        {
            path: getPath('shipping', true),
            component: ShippingEditPage,
            children: [
                {
                    path: ':id',
                    component: ShippingEditPage,
                },
            ],
        },
        {
            path: getPath('products', true),
            component: ProductListAdminPage,
        },
        {
            path: getPath('products/stock', true),
            component: ProductStockPage,
        },
        {
            path: getPath('product', true),
            component: ProductEditPage,
            children: [
                {
                    path: ':id',
                    component: ProductEditPage,
                },
            ],
        },

        // Voucher mgmt
        {
            path: getPath('vouchers', true),
            component: VoucherListPage,
        },
        {
            path: getPath('voucher', true),
            component: VoucherEditPage,
            children: [
                {
                    path: ':id',
                    component: VoucherEditPage,
                },
            ],
        },

        // Review mgmt
        {
            path: getPath('reviews', true),
            component: ReviewListAdminPage,
            children: [
                {
                    path: ':status',
                    component: ReviewListAdminPage,
                },
            ],
        },
        {
            path: getPath('review', true),
            component: ReviewEditPage,
            children: [
                {
                    path: ':id',
                    component: ReviewEditPage,
                },
            ],
        },
        // Order mgmt
        // Testimonial mgmt
        {
            path: getPath('contents', true),
            component: CmsListPage,
        },
        {
            path: getPath('content', true),
            component: CmsEditPage,
            children: [
                {
                    path: ':id',
                    component: CmsEditPage,
                },
            ],
        },
        {
            path: getPath('config', true),
            component: ConfigPage,
        },

    ]).map(setAdminAuth).map(setAdminWrapper)
    .concat([
        {
            path: getPath('cart'),
            component: CartPage,
            children: [
                {
                    path: ':step',
                    component: CartPage,
                    children: [
                        {
                            path: ':orderId',
                            component: CartPage,
                        },
                    ],
                },
            ],
        },
        {
            path: getPath('orders'),
            component: OrderListPage,
            auth: [],
			children: [
				{
					path: ':multiorder',
					component: OrderListPage,
					auth: [],
				},
			],
        },
        {
            path: getPath('order/:orderCode'),
            component: OrderViewPage,
            auth: [],
        },
        {
            path: getPath('affiliations'),
            component: AffiliationListPage,
            auth: [],
        },
        {
            path: getPath('products'),
            component: ProductListPage,
        },
        {
            path: getPath('address'),
            component: AddressEditPage,
            auth: [],
            children: [
                {
                    path: ':id',
                    component: AddressEditPage,
                },
            ],
        },
        {
            path: getPath('addresses'),
            component: AddressListPage,
            auth: [],
        },
        {
            path: getPath('user/register'),
            component: UserRegisterPage,
            children: [
                {
                    path: ':group',
                    component: UserRegisterPage,
                },
            ],
        },
        {
            path: getPath('user/login'),
            component: UserLoginPage,
        },
        {
            path: getPath('user/resetpassword'),
            component: UserLoginPage,
        },
        {
            path: getPath('user/profile'),
            component: UserProfilePage,
        },
        {
            path: getPath('page/printer'),
            component: PrinterPage,
        },
        {
            path: getPath('page/contact'),
            component: ContactPage,
            children: [
                {
                    path: ':group',
                    component: ContactPage,
                },
            ],
        },
        // {
        //     path: '/test',
        //     component: TestPage
        // },
    ]).map(route => ({
        ...route,
        component: route.auth ? authorization(route.auth)(route.component) : route.component,
    }))
    .concat([
        // Product
        // CMS
        // Homepage
        // {
        //     path: getPath('blog/*'),
        //     component: CmsPage,
        //     prefetch: (slug, lang, query) => dispatch => {
        //         const slugParts = slug.split('/');
        //         if(slugParts.length === 2) {
        //             const index = slugParts[slugParts.length - 1];
        //             return dispatch(getCmsContentsRequest('post', lang, index === `${parseInt(index, 10)}` ? index : null));
        //         }
        //         return Promise.resolve();
        //     }
        // },
        {
            path: getPath(':slug'),
            component: CmsPage,
			noSuspense: true,
        },
        {
			path: getPath('*'),
            component: CmsPage,
			noSuspense: true,
        },
        {
			path: '',
            component: CmsPage,
			noSuspense: true,
        },
    ]);

export const layoutComponent = App;

// const AnimatedSwitch = pageAnimator(Switch);

// console.log(routesData);

const getRoutes = (routes, prefix = '') => {
    return (routes || []).reduce((allRoutes, route) => {
        const path = prefix ? `${prefix}/${route.path}` : route.path;
        return allRoutes.concat(({ ...route, path })).concat(getRoutes(route.children || [], path));
    }, []);
};

const renderRoutes = (routes, prefix = '', asObject = false) => {
    return routes.map((route, index) => {
        const path = `${prefix ? `${prefix}/` : ''}${route.path}`;
        const key = route.path || index;
		let element = null;

        if(route.redirect) {
			element = <Navigate replace to={route.redirect} />;
            return asObject ? { ...route, path, element } : <Route key={key} path={path} element={element} />;
        }
        // if(route.pagePath) {
        //     return (
        //         <Route key={index} {...route} render={props => <AsyncComponent path={route.pagePath} {...props} />} />
        //     );
        // }
        // prefix && console.log(prefix, route.path, path);
		element = route.noSuspense ? <route.component /> : <Suspense fallback={<Loader />}><route.component /></Suspense>;
		if(asObject) {
			return {
				...route,
				path,
				element,
				index: !route.path,
				children: route.children?.length ? renderRoutes(route.children, path, true) : [],
			};
		}
        return (
            <Route key={key} path={path} index={!route.path} element={element}>
                {route.children && route.children.length > 0 && renderRoutes(route.children, path)}
            </Route>
        );
    });
};

// react-router setup with code-splitting
function AppRoutes({ ssr = false }) {
    // console.log('AppRoutes::: Render');
    return (
		<Routes>
			<Route path="/" element={<App />}>
				{renderRoutes(routesData)}
				{/* <Route
					path={getPath('*')}
					element={<TestComponent />}
				/> */}
				{/* <Route path="*" element={<TestComponent />} /> */}
			</Route>
		</Routes>
    );
}

export function getRouter(isSsr) {
	return createBrowserRouter(renderRoutes(routesData, '', true));
}

function TestComponent(props) {
	console.log('TestComponent::: Render');
	return (
		<div>test</div>
	);
}

AppRoutes.propTypes = {
    ssr: PropTypes.bool,
 };

export default AppRoutes;
