import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { getCartItems } from '../../CartActions';

function CartItemsBubble({ cartItems = [] }) {
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		setMounted(true);
	}, []);

    const getCartItemsCount = () => {
        return (cartItems || []).reduce((total, item) => total + (item.quantity || 1), 0);
    };

	return mounted && cartItems.length > 0 && (
		<span
			className={classNames('d-inline-block', 'bg-danger', 'text-white', 'heartbeat', 'wobble-hor-bottom', 'text-center', 'cart-icon')}
			style={{ width: '2em', height: '2em', borderRadius: '2em', padding: '0.1em', lineHeight: '2em', fontSize: '0.8em' }}
		>
			{getCartItemsCount()}
		</span>
	);
}

CartItemsBubble.propTypes = {
	cartItems: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = (store, props) => ({
	cartItems: getCartItems(store),
});

export default connect(mapStateToProps)(CartItemsBubble);
