import React from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { parseQuery } from '../../util/parser';

// import { parseQuery } from '../../util/parser.js';
const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

export default function withRouter(Component, needs = null) {
	function ComponentWithRouterProp(props) {
		const location = (!needs || needs.includes('location') || needs.includes('search')) && useLocation();
		const navigate = (!needs || needs.includes('navigate')) && useNavigate();
		const params = (!needs || needs.includes('params')) && useParams();
		const [urlSearchParams] = (!needs || needs.includes('search')) ? useSearchParams() : EMPTY_ARRAY;
		// const [urlSearchParams] = (!needs || needs.includes('search')) ? [parseQuery(location.search)] : [null];
		// console.log('WithRouter::: Render', Component.name, typeof urlSearchParams, urlSearchParams);
		return (
			<Component
				{...props}
				location={location || EMPTY_OBJECT}
				navigate={navigate || console.log}
				params={params || EMPTY_OBJECT}
				// search={urlSearchParams ? Object.fromEntries(urlSearchParams) : {}}
				search={urlSearchParams}
				// search={urlSearchParams}
			/>
		);
	}

	return ComponentWithRouterProp;
}
