/**
 * Root Component
 */
import React, { StrictMode } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { RouterProvider, BrowserRouter } from 'react-router-dom';
import SSRProvider from 'react-bootstrap/SSRProvider';

import IntlWrapper from './modules/Intl/IntlWrapper.js';
// import pageAnimator from './components/Content/PageAnimator.js';

// Import Routes
import AppRoutes, { getRouter } from './routes.js';

// const AnimatedRoutes = pageAnimator(Routes);

// import './assets/css/public.min.css';

export default function App({ location = null, ssr = false, store = {}, context = {}, id = 0, routerComponent = BrowserRouter }) {
    const Router = routerComponent || BrowserRouter;
    // console.log('👉 AppWrapper::: Render');
	// const renderTest = () => {
	// 	console.log('Test ?');
	// 	return <p>Test</p>;
	// };
    const content = (
		<Provider key={id || 0} store={store}>
			<Router key={location} location={location} context={context}>
				<IntlWrapper intl={store.intl}>
					<AppRoutes ssr={ssr} />
					{/* <RouterProvider router={getRouter(ssr)} /> */}
				</IntlWrapper>
			</Router>
		</Provider>
    );
    if(ssr) {
        return (
			<SSRProvider>
				{content}
			</SSRProvider>
		);
    }
    return content;
}

App.propTypes = {
    store: PropTypes.object,
    context: PropTypes.object,
    id: PropTypes.number,
    ssr: PropTypes.bool,
    routerComponent: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.func,
    ]),
	location: PropTypes.string,
};
