export default [
  [0.5, 4.55],
  [1, 5.4],
  [2, 6.4],
  [3, 7],
  [4, 7.9],
  [5, 10],
  [7, 12],
  [10, 14],
  [15, 18.3],
  [20, 20.5],
  [30, 25.9],
];
