import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Row, Col } from 'react-bootstrap';

import { shuffle } from '../../../../util/array.js';

import { getProductAttributeList, getProductPrice, getProductOption, memoizedGetProducts } from '../../ProductActions.js';
import { getUserGroup } from '../../../User/UserGroupActions.js';
import { getDefaultLanguage } from '../../../Intl/IntlHelper.js';

import Slider from '../../../../components/Content/Slider.js';
import PackListItem from './PackListItem.js';
import CmsPanel from '../../../Cms/components/content/CmsPanel.js';

function PackList({ intl, packs = [], userGroup = null, categories = [], isRandom = false, isSlider = false, hideTitle = false, onlyHighlight = false, count = 0 }) {
	// packs = getFilteredProducts(packs, { isActive: true, isPack: true, isVariation: false });
    if(categories && categories.length) {
        packs = (packs || []).filter(pack => [...getProductAttributeList(pack, 'categories', intl.locale), ...getProductAttributeList(pack, 'categories', getDefaultLanguage())].some(productCategory => categories.map(cat => `${cat}`.toLowerCase()).includes(productCategory.toLowerCase())));
    }

    packs = packs.filter(pack => !(getProductOption(pack, 'visibleGroups') || []).length || getProductOption(pack, 'visibleGroups').includes((userGroup || {}).identifier));

    if(onlyHighlight) {
        packs = packs.filter(pack => pack.isHighlight);
    }

    if(count) {
        packs = packs.slice(0, count);
    }

    if(!packs || !packs.length) {
        return null;
    }

    const countPerSlide = 4;

    const groups = [];
    const packsTemp = [...(isRandom ? shuffle(packs) : packs.sort((packA, packB) => getProductPrice(packA, 1, userGroup) - getProductPrice(packB, 1, userGroup)))];
    while(packsTemp.length > 0) {
        groups.push(packsTemp.splice(0, countPerSlide));
    }

    const largeSize = 6;
    const slides = groups.map((contents, index) => {
        return (
            <Row key={index}>
                {contents.map((pack, index) => {
                    const colSize = largeSize; // index === 0 ? largeSize : (largeSize / (2));
                    return (
                        <Col key={index} xs="12" lg={largeSize} xl={colSize} className="mb-3">
                            <PackListItem pack={pack} isLarge={colSize === largeSize} />
                        </Col>
                    );
                })}
            </Row>
        );
    });

    const renderSlider = () => {
        return (
            <Slider
                slides={slides.map(content => ({ content }))}
                disableLightbox
                disableControls={false}
                disableIndicators={false}
                interval={8000}
            />
        );
    };

    const renderList = () => {
        return slides;
    };

    return (
        <Container className="py-3">
            {!hideTitle && (
                <div className="text-center">
                    <h2 className="d-inline-block border-half-warning text-center"><FormattedMessage id="packsTitle" defaultMessage="Our delicatessen boxes" /></h2>
                    <CmsPanel slug="packs-list" isTemplate />
                </div>
            )}
            <div className="pt-3 slider-alt">
                {
                    isSlider
                    ? renderSlider()
                    : renderList()
                }
            </div>
        </Container>
    );
}

function mapStateToProps(store, props) {
    return {
		packs: memoizedGetProducts(store, { isActive: true, isPack: true, isVariation: false }),
        userGroup: getUserGroup(store),
    };
}

PackList.propTypes = {
    intl: PropTypes.object.isRequired,
    packs: PropTypes.arrayOf(PropTypes.object),
    userGroup: PropTypes.object,
    categories: PropTypes.arrayOf(PropTypes.string),
    hideTitle: PropTypes.bool,
    isRandom: PropTypes.bool,
    isSlider: PropTypes.bool,
    onlyHighlight: PropTypes.bool,
    count: PropTypes.number,
};

export default connect(mapStateToProps)(injectIntl(PackList));
