import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { getContentBlockItemsByName, getContentBlockItemValue } from '../../../CmsActions.js';

import CmsBlockItem from '../../content/CmsBlockItem.js';
import Slider from '../../../../../components/Content/Slider.js';

function PanelSlider({ panel }) {
    const interval = getContentBlockItemValue(panel.blocks, 'interval');
    return (
        <div style={{ position: 'fixed', bottom: '0', left: '0', borderTop: '4px solid #ff2222' }} className="d-block d-lg-none w-100 bg-danger text-white text-center border-dark">
            <Slider
                slides={getContentBlockItemsByName(panel.blocks || [], 'content').map((item, index) => ({
                    content: (
                        <div key={index} className="px-2">
                            <CmsBlockItem item={item} type="html" />
                        </div>
                    ),
                }))}
                disableLightbox
                disableControls={false}
                disableIndicators={false}
                interval={interval || 3000}
            />
        </div>
    );
}

PanelSlider.propTypes = {
    panel: PropTypes.object.isRequired,
};

export default PanelSlider;
