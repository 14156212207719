import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import dayjs from 'dayjs';
import { dateDiff, dateDuration, dateIsAfter, dateUtc } from '../../util/date';
import { getRandomString } from '../../util/text';

class Chrono extends Component {
    constructor(props) {
        super(props);

        this.state = {
            duration: null,
            isOver: false,
        };

        this.durationInterval = null;
        this.id = getRandomString();
    }

    componentDidMount() {
        this.setDuration();
    }

    componentDidUpdate(prevProps, prevState) {
        if(!this.state.duration) {
            this.setDuration();
        } else if(this.state.duration && !this.durationInterval) {
            this.durationInterval = setInterval(this.setDuration, 1000);
        }
        if(!prevState.isOver && this.state.isOver) {
            this.endChrono();
        }
    }

    componentWillUnmount() {
        clearInterval(this.durationInterval);
    }

    getThresholdClassName() {
        if(this.props.threshold && dateIsAfter(dateUtc(), this.props.threshold)) {
            return 'text-danger';
        }
        return '';
    }

    setDuration = () => {
        if(this.props.date && !this.state.isOver) {
            this.setState({
                duration: dayjs.duration(dateDiff(this.props.date, dateUtc(), 'millisecond', false)),
                isOver: dateIsAfter(dateUtc(), this.props.date),
            });
        }
    };

    endChrono = () => {
        // console.log('Chrono::: End');
        setTimeout(() => {
            this.setState({
                isOver: true,
            }, () => {
                this.props.onEnd && this.props.onEnd();
                clearInterval(this.durationInterval);
            });
        }, 1000);
    };

	renderFullText = (days, hours, minutes, seconds) => {
		return (
			<Fragment>
				{days !== '00' && <span>{days} <FormattedMessage id="days" defaultMessage="day(s)" />{' '}</span>}
				{hours !== '00' && <span>{hours} <FormattedMessage id="hours" defaultMessage="hour(s)" />{' '}</span>}
				<span>{minutes} <FormattedMessage id="minutes" defaultMessage="minute(s)" /></span>
				{' '}<span>{seconds} <FormattedMessage id="seconds" defaultMessage="second(s)" /></span>
			</Fragment>
		);
	};

    render() {
        if(this.state.duration) {
            let days = Math.floor(this.state.duration.asDays());
            let hours = Math.floor(this.state.duration.asHours() - (days * 24));
            let minutes = Math.floor(this.state.duration.asMinutes() - (days * 24 * 60) - (hours * 60));
            let seconds = Math.floor(this.state.duration.asSeconds() - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60));
			// console.log(days, hours, minutes, seconds);

            if(days < 0 || hours < 0 || minutes < 0 || seconds < 0) {
                if(!this.props.allowNegative) {
                    days = 0;
                    hours = 0;
                    minutes = 0;
                    seconds = 0;
                    if(this.props.hideOnEnd) {
                        return null;
                    }
                }
            }
            // if(!this.props.allowNegative && (days < 0 || hours < 0 || minutes < 0 || seconds < 0)) {
            //     return null;
            // }

            days = `${days}`.length < 2 ? `0${days}` : days;
            hours = `${hours}`.length < 2 ? `0${hours}` : hours;
            minutes = `${minutes}`.length < 2 ? `0${minutes}` : minutes;
            seconds = `${seconds}`.length < 2 ? `0${seconds}` : seconds;

			if(this.props.displayText) {
				return (
					<span id={`chrono-${this.id}`} className={`${this.getThresholdClassName()}`}>
						{this.renderFullText(days, hours, minutes, seconds)}
					</span>
				);
			}
            return (
                <OverlayTrigger
					overlay={<Tooltip>{this.renderFullText(days, hours, minutes, seconds)}</Tooltip>}
                >
                    <span id={`chrono-${this.id}`} className={`${this.getThresholdClassName()}`}>
                        {`${days !== '00' ? `${days}:` : ''}${hours !== '00' ? `${hours}:` : ''}${minutes}:${seconds}`}
                    </span>
                </OverlayTrigger>
            );
        }
        // console.error('Chrono duration', this.props.date, this.state.duration);
        return null;
    }
}

Chrono.defaultProps = {
    onEnd: null,
    allowNegative: false,
    threshold: null,
    hideOnEnd: false,
	displayText: false,
};

Chrono.propTypes = {
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    onEnd: PropTypes.func,
    allowNegative: PropTypes.bool,
    threshold: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    hideOnEnd: PropTypes.bool,
	displayText: PropTypes.bool,
};

export default Chrono;
