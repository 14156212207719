import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import NProgress from 'nprogress';
import Icon from '../Content/Icon.js';

import 'nprogress/nprogress.css';

import config from '../../config.js';
import styles from './Loader.css';

export default class Loader extends PureComponent {
    componentDidMount() {
        NProgress.start();
    }

    componentWillUnmount() {
        NProgress.done();
    }

    render() {
        if(!this.props.hideContent) {
            return (
                <div className={`${styles.wrapper} loader-wrapper`} style={{ paddingTop: '150px', minHeight: '300px', textAlign: 'center' }}>
                    <img src={`${config.url}assets/images/logo-D.png`} alt={config.application.name} style={{ maxWidth: '100px' }} loading="lazy" /><br /><br />
                    <Icon icon="spinner" spin size="lg" className="text-primary" />
                </div>
            );
        }
        return null;
    }
}

Loader.defaultProps = {
    hideContent: false,
};

Loader.propTypes = {
    hideContent: PropTypes.bool,
};
