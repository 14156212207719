import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// import { getLoggedUser } from '../../modules/User/UserActions.js';

// import withRouter from '../Router/WithRouter.js';
import Loader from '../Loader/Loader.js';
// import UserLoginPage from '../../modules/User/pages/auth/UserLoginPage.js';
import ErrorPage from '../../modules/Error/pages/ErrorPage.js';
import { getLoggedUser } from '../../modules/User/UserActions.js';

export default function authorization(allowedRoles) {
    return WrappedComponent => {
        class AuthorizationComponent extends Component {
            // componentWillMount() {
            //     if(!this.props.user) {
            //         this.context.router.push('/login');
            //     }
            // }
            //
            // componentWillUpdate(nextProps) {
            //     if(!nextProps.user) {
            //         this.context.router.push('/login');
            //     }
            // }

            isAuthenticated() {
                return this.props.user && allowedRoles && (!allowedRoles.length || allowedRoles.includes(this.props.user.role));
            }

            renderAlert() {
                if(this.props.user) {
                    return <ErrorPage />;
                }
                return null;
                // return <UserLoginPage location={this.props.location} redirect={(this.props.location || {}).pathname} />;
            }

            render() {
				// console.log('Authorization::: Render');
                // return <ComposedComponent {...this.props} />
                return (
                    <div>
                        {
                            this.props.isMounted
                            ? this.isAuthenticated()
                                ? <WrappedComponent {...this.props} />
                                : this.renderAlert()
                            : <Loader />
                        }
                    </div>
                );
            }
        }

        function mapStateToProps(store) {
            return {
                user: getLoggedUser(store),
                isMounted: store.app.isMounted,
            };
        }

        AuthorizationComponent.defaultProps = {
            user: null,
        };

        AuthorizationComponent.propTypes = {
            dispatch: PropTypes.func.isRequired,
            location: PropTypes.object.isRequired,
            user: PropTypes.object,
            isMounted: PropTypes.bool.isRequired,
        };

        return connect(mapStateToProps)(AuthorizationComponent);
    };
}
