import callApi from '../../util/apiCaller.js';

import { setIsFetching, FETCH_LIMIT } from '../App/AppActions.js';
import { addError } from '../Error/ErrorActions.js';

export const SET_ORDERS = 'SET_ORDERS';
export const SET_ORDER = 'SET_ORDER';
export const REMOVE_ORDER = 'REMOVE_ORDER';

// Requests
export function getOrdersRequest(search = '', filters = null, sorter = { column: null, type: 'ASC' }, pager = { current: 0, size: FETCH_LIMIT }) {
    const recursiveFetch = (limit, start = 0, items = []) => {
        return new Promise((resolve, reject) => {
            callApi(`orders?${search ? `search=${search}&` : ''}${filters ? `${Object.keys(filters).map(filterKey => `filters[${filterKey}]=${filters[filterKey]}`).join('&')}&` : ''}${sorter && sorter.column ? `sortBy=${sorter.column}&sortType=${sorter.type || 'ASC'}` : ''}&start=${start}&limit=${limit}`).then(res => {
                items = items.concat(res.orders);
                if(!pager && res.orders && res.orders.length >= FETCH_LIMIT) {
                    return resolve(recursiveFetch(limit, start + limit, items));
                }
                resolve(items);
            }).catch(err => {
                reject(err);
            });
        });
    };

    return dispatch => {
        dispatch(setIsFetching('orders'));
        return recursiveFetch((pager && pager.size) || FETCH_LIMIT, pager ? (pager.current || 0) * (pager.size || FETCH_LIMIT) : 0).then(orders => {
            dispatch(setOrders(orders || [], true));
            return orders;
        }).catch(err => {
            dispatch(addError(err));
            return null;
        });
    };
}

export function getOrdersByUserRequest(userId = null) {
    return dispatch => {
        return callApi(`orders/user/${userId || ''}`).then(res => {
            res.orders && dispatch(setOrders(res.orders));
            return res.orders;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function getOrderRequest(orderId) {
    return dispatch => {
        return callApi(`order/${orderId}`).then(res => {
            res.order && dispatch(setOrders([res.order, ...(res.suborders || [])]));
            return res;
        }).catch(error => {
            // dispatch(addError(error));
            return null;
        });
    };
}

export function editOrderRequest(order) {
    return dispatch => {
        return callApi('order/edit', 'post', { order }).then(res => {
            res.order && dispatch(setOrder(res.order));
            return res.order;
        }).catch(error => {
            // dispatch(addError(error));
            return null;
        });
    };
}

export function createOrderRequest(order, isMultiorder = false) {
    return dispatch => {
        return callApi('order/create', 'post', { order, isMultiorder }).then(res => {
            res.order && dispatch(getOrderRequest(res.order._id));
            return res.order;
        }).catch(error => {
            // dispatch(addError(error));
            return null;
        });
    };
}

export function addPaymentRequest(orderId, payment) {
    return dispatch => {
        return callApi('order/payment/add', 'post', { order: { _id: orderId }, payment }).then(res => {
            res.order && dispatch(setOrder(res.order));
            return res.order;
        }).catch(error => {
            // dispatch(addError(error));
            return null;
        });
    };
}

export function transmitOrderRequest(orderId, items = null, orderData = null) {
    return dispatch => {
        return callApi(`order/${orderId}/transmit`, 'post', { items, order: orderData }).then(res => {
            res && res.ok && orderId && orderId !== 'custom' && dispatch(getOrderRequest(orderId));
            return res && res.ok;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function resetOrderProductStockRequest(orderId, increase = false) {
    return dispatch => {
        return callApi(`order/${orderId}/resetstock${increase ? '?increaseStock=1' : ''}`).then(res => {
            return res && res.ok;
        }).catch(error => {
            // dispatch(addError(error));
            return null;
        });
    };
}

export function sendOrderContactRequest(orderIds, contact = {}) {
    return dispatch => {
        return callApi('orders/contact', 'post', { orders: orderIds, ...contact }).then(result => {
            return result && result.ok;
        }).catch(err => {
            return null;
        });
    };
}

export function getParentOrderDetailsRequest(userId, orderId) {
    return dispatch => {
        return callApi(`order/${orderId}/parent/${userId}`).then(res => {
            return res;
        }).catch(err => {
            return null;
        });
    };
}

export function confirmMultiorderRequest(orderId) {
    return dispatch => {
        return callApi(`order/${orderId}/confirm`, 'post').then(res => {
            res && res.ok && dispatch(getOrderRequest(orderId));
            return res && res.ok;
        }).catch(error => {
            // dispatch(addError(error));
            return null;
        });
    };
}

export function sendOrderStatusNotification(orderId) {
    return dispatch => {
        return callApi(`order/${orderId}/sendnotification`, 'get').then(res => {
            return res && res.ok;
        }).catch(error => {
            // dispatch(addError(error));
            return null;
        });
    };
}

// GETTERS
export function getOrders(store, statuses = null) {
    if(statuses && !Array.isArray(statuses)) {
        statuses = [statuses];
    }
    return store.orders.data.filter(order => !statuses || statuses.includes(order.status));
}

export function getOrdersByUser(store, userId, statuses = null) {
    return getOrders(store, statuses).filter(order => !userId || ((order.user || {})._id || order.user) === userId);
}

export function getOrder(store, orderId) {
    return store.orders.data.find(order => order._id === orderId);
}

export function getOrderByCode(store, orderCode) {
    return store.orders.data.find(order => `${order.code}`.toLowerCase() === `${orderCode}`.toLowerCase()) || null;
}

// Actions
export function setOrders(orders, clear = false) {
    return {
        type: SET_ORDERS,
        orders,
        clear,
    };
}

export function setOrder(order) {
    return {
        type: SET_ORDER,
        order,
    };
}

export function removeOrder(orderId) {
    return {
        type: REMOVE_ORDER,
        orderId,
    };
}
