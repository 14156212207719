import callApi from '../../util/apiCaller.js';
import { getLoggedUser } from '../User/UserActions.js';
import { addError } from '../Error/ErrorActions.js';

// Export Constants
export const SET_ADDRESSES = 'SET_ADDRESSES';
export const REMOVE_ADDRESS = 'REMOVE_ADDRESS';

// Export Actions
export function getAvailableCountriesRequest() {
    return callApi('address/countries').then(res => {
        return res.countries;
    });
}

export function getAddressesRequest(userId = null) {
    return dispatch => {
        return callApi(`addresses${userId ? `/${userId}` : ''}`).then(res => {
            if(res.addresses) {
                dispatch(setAddresses(res.addresses));
            }
            return res.addresses;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function getAddressRequest(addressId) {
    return dispatch => {
        return callApi(`address/${addressId}`).then(res => {
            return res.address;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function editAddressRequest(address) {
    return dispatch => {
        return callApi('address/edit', 'post', { address }).then(res => {
            if(res.address) {
                dispatch(setAddresses([res.address]));
            }
            return res.address;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function setInvoiceAddressRequest(address) {
    return dispatch => {
        return callApi('address/useforinvoice', 'post', { address }).then(res => {
            if(res.address) {
                dispatch(getAddressesRequest());
            }
            return res.address;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function deleteAddressRequest(addressId) {
    return dispatch => {
        return callApi('address/remove', 'post', { address: { _id: addressId } }).then(res => {
            if(res.ok) {
                dispatch(removeAddress(addressId));
            }
            return res.ok;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function getAddress(store, addressId) {
    return store.addresses.data.find(address => address._id === addressId);
}

export function getAddresses(store, userId) {
    return store.addresses.data.filter(address => {
        const addressUserId = address.user._id || address.user;
        return addressUserId === userId;
    });
}

export function getInvoiceAddressFromStore(store, userId = null) {
    const userAddresses = getAddresses(store, userId || (getLoggedUser(store) || {})._id);
    return getInvoiceAddress(userAddresses);
}

export function getInvoiceAddress(addresses) {
    const invoiceAddress = addresses.find(address => address.isUsedForInvoice);
    if(!invoiceAddress) {
        return addresses && addresses[0];
    }
    return invoiceAddress;
}

export function getCountryNameTranslation(item, locale) {
	if(typeof item === 'string') {
		return item;
	}
    let name = '';
    switch(locale) {
        case 'fr':
            name = item.translations.fra && item.translations.fra.common;
            break;

        case 'de':
            name = item.translations.deu && item.translations.deu.common;
            break;

        case 'es':
            name = item.translations.spa && item.translations.spa.common;
            break;

        default:
            name = item.name.common;
    }
    return name || item.name.common;
}

export function setAddresses(addresses) {
    return {
        type: SET_ADDRESSES,
        addresses,
    };
}
export function removeAddress(addressId) {
    return {
        type: REMOVE_ADDRESS,
        addressId,
    };
}
