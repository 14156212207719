import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import { getContentBlockItemsByName } from '../../../Cms/CmsActions.js';

import withRouter from '../../../../components/Router/WithRouter.js';
import PredictiveLink from '../../../Cms/components/view/PredictiveLink.js';

function PanelFooterLinks({ location, navigate, panel }) {
    const needObfuscate = (location.pathname || '').split('/').filter(part => part).length > 1;

    const handleGoTo = event => navigate(event.target.getAttribute('data-path'));

    return (
        <div className="company-info">
            {getContentBlockItemsByName(panel.blocks, 'links').map((link, index) => (
                <div className="pt-2 " key={link.path}>
                    {link.needObfuscate || needObfuscate ? <Button variant="link" as="span" className="p-0 text-start text-white" data-path={link.path} onClick={handleGoTo}>{link.text}</Button> : <PredictiveLink to={link.path} className="p-0 text-white">{link.text}</PredictiveLink>}
                </div>
            ))}
        </div>
    );
}

PanelFooterLinks.propTypes = {
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    panel: PropTypes.object.isRequired,
};

export default withRouter(PanelFooterLinks, ['navigate', 'location']);
