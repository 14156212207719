import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import CmsBlock from '../../content/CmsBlock.js';

function PanelDefault({ panel }) {
    return (
        <Fragment>
            {(panel.blocks || []).map((subBlock, index) => <CmsBlock key={subBlock.id || index} block={subBlock} />)}
        </Fragment>
    );
}

PanelDefault.propTypes = {
    panel: PropTypes.object.isRequired,
};

export default PanelDefault;
