const PageBlogModel = {
    type: 'page',
    name: 'blog',
    blocks: [
        {
            name: 'image', // name or id; MUST be unique
            max: 1, // max same items allowed
            type: 'image', // || text || link || image || mixed (with children)
        },
        {
            name: 'title', // name or id; MUST be unique
            max: 1, // max same items allowed
            type: 'text', // || text || link || image || mixed (with children)
        },
        {
            name: 'promotion',
            type: 'panel',
        },
    ],
    // options: {
    //     disableCanonical: true,
    // },
};

export default PageBlogModel;
