import React, { useState, useEffect, startTransition, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Modal, ButtonGroup, Button } from 'react-bootstrap';

import config from '../../../../config.js';

import { getParentOrderDetailsRequest } from '../../OrderActions.js';
import withRouter from '../../../../components/Router/WithRouter.js';
import Icon from '../../../../components/Content/Icon.js';
import { setUserGroup, setUserGroupFromParent } from '../../../User/UserGroupActions.js';
import { setCartMultiorder, addMultiorderInvitation, removeInvitation } from '../../../Cart/CartActions.js';
import { getOrderOption } from '../../OrderHelpers.js';

function MultiOrderPopin({ dispatch, search }) {
    // const [isFetching, setIsFetching] = useState(false);
    const [isOpen, toggle] = useState(false);
    const [parentUser, setParentUser] = useState(null);
    const [parentUserGroup, setParentUserGroup] = useState(null);
    const [parentOrder, setParentOrder] = useState(null);

    useEffect(() => {
        startTransition(() => {
            if(search.get('join')) {
                const [user, order] = search.get('join').split('-');
                dispatch(getParentOrderDetailsRequest(user, order)).then(result => {
                    if(result) {
						const newUserGroup = {
							...result.userGroup,
							discount: getOrderOption(result.order, 'userGroupDiscount') !== '' ? Math.min(getOrderOption(result.order, 'userGroupDiscount'), result.userGroup.discount) : result.userGroup.discount,
						};
                        result.user && setParentUser(result.user);
                        result.userGroup && setParentUserGroup(newUserGroup);
                        result.order && setParentOrder(result.order);
                        dispatch(addMultiorderInvitation(result.user, newUserGroup, result.order));
                        toggle(true);
                    }
                });
            }
        });
    }, [search]);

    const handleValid = event => {
        // store parentUser, userGroup and/or parentOrder in redux store
        dispatch(setUserGroup(parentUserGroup));
        dispatch(setUserGroupFromParent());
        dispatch(setCartMultiorder(parentUser, parentOrder));
        dispatch(removeInvitation(`multiorder-${(parentUser || {})._id}-${(parentOrder || {})._id}`));
        toggle(false);
    };

    const handleClose = () => toggle(false);

    return (
        <Modal show={isOpen} onHide={() => toggle(false)} centered contentClassName="bg-info bg-texture">
            <Modal.Body>
                {parentUser && (
                    <div>
                        <div className="text-center">
                            <h3><FormattedMessage id="orderMultiorderInviteTitle" defaultMessage="Join the bulk order on {app}" values={{ app: config.application.name }} /></h3>
                            <p>
                                {
                                    (parentUserGroup || {}).discount
                                    ? <FormattedMessage id="orderMultiorderInviteWithDiscountText" defaultMessage="{user} invites you to join their bulk order to enjoy a {discount}% discount on your order on {app}!" values={{ app: config.application.name, user: parentUser.company || `${parentUser.firstName || ''} ${parentUser.lastName || ''}`, discount: parentUserGroup.discount }} />
                                    : <FormattedMessage id="orderMultiorderInviteText" defaultMessage="{user} invites you to join his bulk order on {app}." values={{ app: config.application.name, user: parentUser.company || `${parentUser.firstName || ''} ${parentUser.lastName || ''}` }} />
                                }
                            </p>
                        </div>

                        <hr />
                        <div className="mt-2 text-sm">
                            <h5><Icon icon="ui-kit" /> <FormattedMessage id="orderMultiorderProcessTitle" defaultMessage="How does a bulk order work?" /></h5>
                            <p className="text-sm">
                                <FormattedMessage id="orderMultiorderProcessInfo" defaultMessage="A grouped order brings together several participants, each carrying out an order. All orders are shipped to the same address and then distributed to you by the group order manager." />
                            </p>
                        </div>
                        <hr />

                        {(parentUserGroup || {}).discount > 0 && (
                            <div className="text-center">
                                <strong className="d-block display-3 text-danger">
                                    -{(parentUserGroup || {}).discount}%
                                </strong>
                                <small><FormattedMessage id="userGroupDiscountLabelSuffix" defaultMessage="immediate discount" /></small>
                            </div>
                        )}
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup>
                    <Button size="sm" variant="link" outline onClick={handleClose}><Icon icon="close-o" /> <FormattedMessage id="close" defaultMessage="Close" /></Button>
                    <Button variant="success" onClick={handleValid}><FormattedMessage id="orderMultiorderInviteConfirm" defaultMessage="I join the group order" /> 👉</Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
}

MultiOrderPopin.propTypes = {
    dispatch: PropTypes.func.isRequired,
    search: PropTypes.array.isRequired,
};

export default connect()(withRouter(MultiOrderPopin));
