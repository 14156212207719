import React, { PureComponent, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from '../../../../components/Router/WithRouter.js';

import { registerRequest, getLoggedUser } from '../../UserActions.js';
import { USER_GROUP_DEFAULT } from '../../UserGroupActions.js';

function InstantRegister({ dispatch, search, user = null }) {
    useEffect(() => {
        if(!user && search.get('instantRegister') && search.get('email')) {
            dispatch(registerRequest({
                email: search.get('email')?.toLowerCase()?.trim() || '',
                firstName: search.get('firstName') || '',
                lastName: search.get('lastName') || '',
                phone: search.get('phone') || '',
                company: search.get('company') || '',
                group: search.get('group') || USER_GROUP_DEFAULT,
                options: {
                    needResetPassword: true,
                },
            }, true));
        }
	}, []);

	return null;
}

function mapStateToProps(store, props) {
    return {
        user: getLoggedUser(store),
    };
}

InstantRegister.propTypes = {
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    search: PropTypes.object.isRequired,
    user: PropTypes.object,
};

export default connect(mapStateToProps)(withRouter(InstantRegister));
