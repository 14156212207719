import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { JSONLD, Product, AggregateRating, Generic, GenericCollection, Review, Author, Rating } from 'react-structured-data';

import config from '../../../../config.js';
import { getFile } from '../../../../util/file.js';
import { dateFormat, dateAdd } from '../../../../util/date.js';
import { truncate } from '../../../../util/text.js';

import { getProductSlug } from '../../ProductActions.js';
import { getReviews, memoizedGetReviews } from '../../../Review/ReviewActions.js';
import { getConfig } from '../../../Config/ConfigActions.js';
import { getTranslation } from '../../../Intl/IntlHelper.js';
import { getPanelBySlugAndLanguage, getContentBlockItemValue, memoizedGetPanelBySlugAndLanguage } from '../../../Cms/CmsActions.js';

function ProductSnippet({ intl, product, siteTitlePanel = null, rate = 0, reviewsCount = 0, reviews = [] }) {
    const siteTitle = siteTitlePanel && getContentBlockItemValue(siteTitlePanel.blocks, 'text');
    const video = (product.pictures || []).find(picture => picture.includes('.gif') || picture.includes('.webm') || picture.includes('.mp4'));
    const image = (product.pictures || []).find(picture => picture !== video);

    // return (
    //     <script type="application/ld+json">
    //         {`{
    //             "@context": "https://schema.org/",
    //             "@type": "Product",
    //             "name": "${getTranslation(product, 'name', intl.locale)}",
    //             "image": "${getFile(image)}",
    //             "description": "${getTranslation(product, 'description', intl.locale)}",
    //             "sku": "${product.companyReference || product.slug || product._id}",
    //             "gtin": "${product.gtin}",
    //             "manufacturer": "Keesbo",
    //             "brand": {
    //                 "@type": "Organization",
    //                 "name": "${siteTitle || config.application.name}",
    //                 "url": "${`${config.url}${intl.locale}/`}"
    //             },
    //             "offers": {
    //                 "@type": "Offer",
    //                 "price": "${product.priceTTC}",
    //                 "priceCurrency": "EUR",
    //                 "availability": "http://schema.org/InStock",
    //                 "priceValidUntil": "${moment().add(1, 'month').format('YYYY-MM-DD')}",
    //                 "url": "${`${config.url}${intl.locale}/${getProductSlug(product, intl.locale)}`}"
    //             },
    //             ${video ? `"Video": {
    //                     "@type": "VideoObject",
    //                     "name": "${getTranslation(product, 'name', intl.locale)}",
    //                     "description": "${getTranslation(product, 'description', intl.locale)}",
    //                     "thumbnailUrl": "${getFile(image)}",
    //                     "uploadDate": "2022-09-01",
    //                     "contentUrl": "${getFile(video)}"
    //                 },` : ''
    //             }
    //             "aggregateRating": {
    //                 "@type": "AggregateRating",
    //                 "ratingValue": "${rate}",
    //                 "reviewCount": "${reviewsCount}",
    //                 "bestRating": 5
    //             },
    //             ${reviews.length > 0 ? `
    //                 "review": [
    //                     ${(reviews || {}).filter(review => review.score).map(review => `
    //                         {
    //                             "@type": "Review",
    //                             "name": "${review.title}",
    //                             "reviewBody": "${review.text}",
    //                             "datePublished": "${dateFormat(review.dates.created, 'MM/DD/YYYY')}",
    //                             "author": {
    //                                 "@type": "Person",
    //                                 "name": "${review.name}"
    //                             },
    //                             "reviewRating": {
    //                                 "@type": "Rating",
    //                                 "ratingValue": ${review.score}
    //                             }
    //                         }
    //                     `)}
    //                 ]` : ''}
    //         }`}
    //     </script>
    // );

    return (
        <Fragment>
            <JSONLD dangerouslyExposeHtml>
                <Product
                    name={getTranslation(product, 'name', intl.locale)}
                    image={getFile(image)}
                    description={truncate(getTranslation(product, 'description', intl.locale), 4000, 'chars')}
                    sku={product.companyReference || product.slug || product._id}
                    gtin={product.gtin}
                    manufacturer="Keesbo"
                >
                    <Generic
                        type="brand"
                        jsonldtype="Brand"
                        schema={{
                            name: siteTitle || config.application.name,
                            url: `${config.url}${intl.locale}/`,
                        }}
                    />

                    <Generic
                        type="offers"
                        jsonldtype="Offer"
                        schema={{
                            price: product.priceTTC,
                            priceCurrency: 'EUR',
                            availability: 'http://schema.org/InStock',
                            priceValidUntil: dateFormat(dateAdd('', 1, 'month'), 'YYYY-MM-DD'),
                            url: `${config.url}${intl.locale}/${getProductSlug(product, intl.locale)}` }}
                    >
                        <Generic
                            type="shippingDetails"
                            jsonldtype="OfferShippingDetails"
                        >
                            <Generic
                                type="shippingRate"
                                jsonldtype="MonetaryAmount"
                                schema={{
                                    value: '0',
                                    currency: 'EUR',
                                }}
                            />
                            <GenericCollection
                                type="shippingDestination"
                            >
                                <Generic
                                    jsonldtype="DefinedRegion"
                                    schema={{
                                        addressCountry: 'FR',
                                    }}
                                />
                                <Generic
                                    jsonldtype="DefinedRegion"
                                    schema={{
                                        addressCountry: 'BE',
                                    }}
                                />
                                <Generic
                                    jsonldtype="DefinedRegion"
                                    schema={{
                                        addressCountry: 'DE',
                                    }}
                                />
                                <Generic
                                    jsonldtype="DefinedRegion"
                                    schema={{
                                        addressCountry: 'NL',
                                    }}
                                />
                            </GenericCollection>
                        </Generic>
                    </Generic>

                    <AggregateRating ratingValue={rate} reviewCount={reviewsCount} bestRating={5} />
                    {
                        reviews.length > 0
                        ? (
                            <GenericCollection type="review">
                                {(reviews || {}).filter(review => review.score).map(review => (
                                    <Review key={review._id} name={review.title} reviewBody={review.text} datePublished={dateFormat(review.dates.created, 'MM/DD/YYYY')}>
                                        <Author name={review.name} />
                                        <Rating ratingValue={review.score} />
                                    </Review>
                                ))}
                            </GenericCollection>
                        ) : (
                            <Generic
                                type="noReview"
                            />
                        )
                    }
                </Product>
            </JSONLD>

            <JSONLD dangerouslyExposeHtml>
                {
                    video
                    ? (
                        <Generic
                            jsonldtype="CreativeWork"
                        >
                            <Generic
                                type="video"
                                jsonldtype="VideoObject"
                                schema={{
                                    name: getTranslation(product, 'name', intl.locale),
                                    description: getTranslation(product, 'description', intl.locale),
                                    thumbnailUrl: getFile(image),
                                    uploadDate: '2022-09-01T08:00:00+01:00',
                                    contentUrl: getFile(video),
                                }}
                            />
                        </Generic>
                    ) : <Generic type="noVideo" />
                }
            </JSONLD>
        </Fragment>
    );
}

function mapStateToProps(store, props) {
    return {
        rate: getConfig(store, 'star-rating'),
        siteTitlePanel: memoizedGetPanelBySlugAndLanguage(store, 'site-title', (store.intl || {}).locale),
        reviewsCount: getConfig(store, 'reviews-count'),
        reviews: memoizedGetReviews(store, ['validated']),
    };
}

ProductSnippet.propTypes = {
    intl: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    rate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    siteTitlePanel: PropTypes.object,
    reviewsCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    reviews: PropTypes.arrayOf(PropTypes.object),
};

export default connect(mapStateToProps)(injectIntl(ProductSnippet));
