import React from 'react';
import PropTypes from 'prop-types';

function PageWrapper({ children }) {
    return (
        <div className="row">
            <div className="col col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                {children}
            </div>
        </div>
    );
}

PageWrapper.propTypes = {
    children: PropTypes.node.isRequired,
};

export default PageWrapper;
