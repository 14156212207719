import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Card, Form, Button, Alert } from 'react-bootstrap';

import Toggle from 'react-toggle';
import 'react-toggle/style.css';

import config from '../../../../config.js';
import { getFile } from '../../../../util/file.js';
import { dateFormat, dateIsBefore, dateUtc } from '../../../../util/date.js';

import { registerQuizParticipationRequest } from '../../QuizActions.js';
import { getContentBlockItemsByName, getContentBlockItemByName, getContentBlockMixedItem, getContentBlockItemValue } from '../../../Cms/CmsActions.js';
import { getLoggedUser, checkEmail } from '../../../User/UserActions.js';
import { displayErrors } from '../../../Error/ErrorActions.js';

import Icon from '../../../../components/Content/Icon.js';
import withRouter from '../../../../components/Router/WithRouter.js';
import Breadcrumbs from '../../../App/components/view/Breadcrumbs.js';
import HtmlComponent from '../../../../components/Text/HtmlComponent.js';
import FileLoader from '../../../../components/Content/FileLoader.js';
import CmsPanel from '../../../Cms/components/content/CmsPanel.js';
import CmsBlockItem from '../../../Cms/components/content/CmsBlockItem.js';
import PreFooter from '../../../App/components/PreFooter.js';
import PageCover from '../../../Cms/components/view/PageCover.js';

function PageQuiz({ search, dispatch, page, user, ...props }) {
    const params = search || {};
    const [isConditionVisible, toggleCondition] = useState(params.displayConditions);
    const [email, setEmail] = useState((user || {}).email || '');
    const [isSaving, toggleSaving] = useState(false);
    const [isParticipationSent, toggleConfirmation] = useState(false);
    const [isSubscribe, setSubscribe] = useState(false);

    const name = getContentBlockItemValue(page.blocks, 'name');

    const isEmailValid = () => {
        return email && checkEmail(email);
    };

    const handleChange = event => {
        setEmail(event.target.value);
    };

    const handleSubmit = event => {
        if(isEmailValid()) {
            toggleSaving(true);
            dispatch(registerQuizParticipationRequest(name, email, isSubscribe, { preferences: { language: props.intl.locale } })).then(result => {
                toggleSaving(false);
                toggleConfirmation(result);
            });
        } else {
            displayErrors('warning', props.intl.formatMessage({
                id: 'userEmailError',
                defaultMessage: 'Your email address is not valid',
            }));
        }
    };

    const dateStart = getContentBlockItemValue(page.blocks, 'dateStart');
    const dateEnd = getContentBlockItemValue(page.blocks, 'dateEnd');

    const contents = getContentBlockItemsByName(page.blocks, 'content');
    const conditions = getContentBlockItemValue(page.blocks, 'conditions');
    const footer = getContentBlockItemValue(page.blocks, 'footer');
    const confirmation = getContentBlockItemValue(page.blocks, 'confirmation');
    const end = getContentBlockItemValue(page.blocks, 'end');

    const renderContent = (content, isFullColumn = false) => {
        const text = getContentBlockMixedItem(content, 'text') && <HtmlComponent text={getContentBlockMixedItem(content, 'text').text} />;
        const image = getContentBlockMixedItem(content, 'image') && getContentBlockMixedItem(content, 'image').path && <CmsBlockItem item={getContentBlockMixedItem(content, 'image')} type="image" className="mt-3 mt-md-0 w-100" />;
        const cta = getContentBlockMixedItem(content, 'call-to-action') && getContentBlockMixedItem(content, 'call-to-action').text && <div className="text-center"><Button variant="warning" size="lg" as={Link} to={getContentBlockMixedItem(content, 'call-to-action').path} className="mt-3">{getContentBlockMixedItem(content, 'call-to-action').text}</Button></div>;
        const position = getContentBlockMixedItem(content, 'position') && getContentBlockMixedItem(content, 'position').text;
        return (
            <div className="mb-5">
                {
                    isFullColumn
                    ? (
                        <Fragment>
                            {image}
                            {text}
                            {cta}
                        </Fragment>
                    ) : (
                        <Row>
                            {
                                (!position || position === 'right')
                                ? (
                                    <Fragment>
                                        <Col xs="12" md={image ? 8 : 12}>
                                            {text}
                                            {cta}
                                        </Col>
                                        {image && <Col>{image}</Col>}
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        {image && <Col>{image}</Col>}
                                        <Col xs="12" md={image ? 8 : 12}>
                                            {text}
                                            {cta}
                                        </Col>
                                    </Fragment>
                                )
                            }
                        </Row>
                    )
                }
            </div>
        );
    };

    const renderStep = () => {
        if(dateIsBefore(dateUtc(), dateEnd)) {
            return (
                isParticipationSent
                ? (

                    <div className="">
                        <Card body className={classnames('px-2', 'p-md-5', 'text-center')}>
                            <h2 className="text-secondary"><FormattedMessage id="quizParticipationSuccessTitle" defaultMessage="Congratulations! Your participation has been saved!" /></h2>

                            <HtmlComponent text={confirmation} />

                            <div className="my-5">
                                <h5><FormattedMessage id="orderConfirmationSocialTitle" defaultMessage="Stay in touch!" /></h5>
                                <p><FormattedMessage id="orderConfirmationSocialText" defaultMessage="Follow us on your favorite social network to stay up to date with the latest news, new products and even some discounts ..." /></p>
                                <a href="https://www.facebook.com/directos.eu" className="me-1" target="_blank" rel="noreferrer">
                                    <img src="https://s3.eu-west-3.amazonaws.com/assets.directos.eu/images/facebook.png" alt="facebook" width="50" />
                                </a>{' '}
                                <a href="https://www.instagram.com/directos.eu/" target="_blank" rel="noreferrer">
                                    <img src="https://s3.eu-west-3.amazonaws.com/assets.directos.eu/images/instagram.png" alt="Instagram" width="50" />
                                </a>
                            </div>

                        </Card>
                        <CmsPanel slug="categories" isTemplate />
                    </div>
                )
                : (
                    <Card body className={classnames('px-2', 'p-md-5')}>
                        {isConditionVisible && (
                            <div className="mb-3 pb-3">
                                <Button variant="link" onClick={() => toggleCondition(false)} className="float-right"><Icon icon="close" /> <FormattedMessage id="close" defaultMessage="Close" /></Button>
                                <HtmlComponent text={conditions} />
                                <hr />
                            </div>
                        )}

                        {contents && contents.length && contents.map((content, index) => <div key={index}>{renderContent(content)}</div>)}

                        {
                            dateIsBefore(dateUtc(), dateStart)
                            ? (
                                <Alert variant="warning" className="text-center">
                                    <Icon icon="danger" /> <FormattedMessage id="quizDateStartAlert" defaultMessage="This game hasn't started yet! Start date on {date}" values={{ date: dateFormat(dateUtc(dateStart)) }} />
                                </Alert>
                            ) : (
                                <Row>
                                    <Col className="mb-2 mb-md-0">
                                        <Form.Group>
                                            <Form.Control type="email" name="email" value={email} bsSize="lg" disabled={isSaving} onChange={handleChange} className="rounded-pill" placeholder={props.intl.formatMessage({ id: 'userEmailFormInfo', defaultMessage: 'Enter your email' })} />
                                        </Form.Group>
                                        <Form.Group>
                                            <Toggle
                                                id="subscribeField"
                                                name="subscribe"
                                                checked={isSubscribe}
                                                onChange={event => setSubscribe(event.target.checked)}
                                            />
                                        <Form.Label for="subscribeField" className="w-75 ps-2 align-text-bottom"><FormattedMessage id="quizNewsletterSubscribe" defaultMessage="I want to subscribe to monthly newsletter, to receive news, discounts, or other informations from {name}, and maybe you will have a surprise 🎁 ...." values={{ name: config.application.name }} /></Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col xs="12" md="4" xl="3" className="text-center">
                                        <Button variant="success" size="lg" block className="rounded-pill" disabled={isSaving} onClick={handleSubmit}><FormattedMessage id="quizParticipationValidButton" defaultMessage="I participate" /> <Icon icon={isSaving ? 'spinner' : 'check-circle'} spin={isSaving} /></Button>
                                        <small>
                                            <FormattedMessage id="quizLegalNoticeText" defaultMessage="By participating, i accept the quiz conditions" />
                                        </small>
                                        {!isConditionVisible && <Button size="sm" variant="link" className="p-0" onClick={() => { toggleCondition(true); window.scrollTo({ top: 0, left: 0, behavior: 'instant' }); }}><FormattedMessage id="quizConditionsCheckButton" defaultMessage="Check the conditions" /></Button>}
                                    </Col>
                                </Row>
                            )
                        }

                        <HtmlComponent text={footer} />
                    </Card>
                )
            );
        }
        return (
            <Card body className=" text-center">
                <Alert variant="success" className="text-center">
                    <Icon icon="danger" /> <FormattedMessage id="quizDateEndAlert" defaultMessage="This game is over!" />
                </Alert>

                <HtmlComponent text={end} />

                <div className="my-5">
                    <h5><FormattedMessage id="orderConfirmationSocialTitle" defaultMessage="Stay in touch!" /></h5>
                    <p><FormattedMessage id="orderConfirmationSocialText" defaultMessage="Follow us on your favorite social network to stay up to date with the latest news, new products and even some discounts ..." /></p>
                    <a href="https://www.facebook.com/directos.eu" className="me-1" target="_blank" rel="noreferrer">
                        <img src="https://s3.eu-west-3.amazonaws.com/assets.directos.eu/images/facebook.png" alt="facebook" width="50" />
                    </a>{' '}
                    <a href="https://www.instagram.com/directos.eu/" target="_blank" rel="noreferrer">
                        <img src="https://s3.eu-west-3.amazonaws.com/assets.directos.eu/images/instagram.png" alt="Instagram" width="50" />
                    </a>
                </div>
            </Card>
        );
    };

    return (
        <Fragment>
            <PageCover page={page} coverKey="image" />

            <Container className={classnames('mt-lg-n5', 'mb-5', 'critical-css-limiter')}>
                {renderStep()}
            </Container>
            <PreFooter />
        </Fragment>
    );
}

function mapStateToProps(store, props) {
    return {
        user: getLoggedUser(store),
    };
}

PageQuiz.defaultProps = {
    user: null,
};

PageQuiz.propTypes = {
    search: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
    user: PropTypes.object,
};

export default connect(mapStateToProps)(withRouter(injectIntl(PageQuiz)));
