import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col } from 'react-bootstrap';

import config from '../../../../config.js';

import { formatPrice } from '../../../../util/price.js';

import { getProductPrice, getProductAttributeList, getProductOption } from '../../../Product/ProductActions.js';
import { getUserGroupOption } from '../../../User/UserGroupActions.js';
import { getTranslation } from '../../../Intl/IntlHelper.js';

import AppLogo from '../../../App/components/view/AppLogo.js';
import ProductWeight from '../../../Product/components/view/ProductWeight.js';
import Price from '../../../Currency/components/view/Price.js';
import { getCartQuantityStep } from '../../../Cart/CartActions.js';
import Icon from '../../../../components/Content/Icon.js';

class OrderForm extends Component { // React-to-print is not working with stateless component
    renderQRCode() {
         return <img src={`https://api.qrserver.com/v1/create-qr-code/?data=${'https://directos.eu/' || config.url}${this.props.locale}&amp;size=200x200`} alt="" title="" style={{ width: '100px' }} />;
    }

    render() {
        const { products, user, userGroup, hidePrice, referral, locale } = this.props;
        const displayPriceHT = getUserGroupOption(userGroup, 'displayPriceHT');

        return (
            <div className="w-100">
                <div className="mx-3 mt-2">
                    <Row className="pt-0">
                        <Col>
                            <AppLogo className="w-75" />
                        </Col>
                        <Col>
                            <Row>
                                <Col>
                                    <FormattedMessage id="orderFormQRCodeText" defaultMessage="Find all the products in detail on the online store" />
                                </Col>
                                <Col className="text-end">
                                    {this.renderQRCode()}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                {!this.props.hideForm && (
                    <div className="mx-3 pt-1">
                        <Row className="pt-1">
                            <Col>
                                <div className="border-bottom">
                                    <FormattedMessage id="userFirstName" defaultMessage="First name" />
                                </div>
                            </Col>
                            <Col>
                                <div className="border-bottom">
                                    <FormattedMessage id="userLastName" defaultMessage="Last name" />
                                </div>
                            </Col>
                            <Col>
                                <div className="border-bottom">
                                    <FormattedMessage id="userGroup" defaultMessage="Group" />
                                </div>
                            </Col>
                        </Row>
                        <Row className="pt-1">
                            <Col>
                                <div className="border-bottom">
                                    <FormattedMessage id="userEmail" defaultMessage="Email" />
                                </div>
                            </Col>
                                <Col>
                                    <div className="border-bottom">
                                        <FormattedMessage id="userPhone" defaultMessage="Phone" />
                                    </div>
                                </Col>
                        </Row>
                    </div>
                )}
                <div className="mx-3 pt-2">
                    <h4 className="mb-1 border-bottom"><FormattedMessage id="orderFormTitle" defaultMessage="Order form" /></h4>
                    <Row>
                        <Col xs="7">
                            {' '}
                        </Col>
                        {!hidePrice && (
                            <Col xs="3">
                                {displayPriceHT ? <FormattedMessage id="productUnitPriceExclTax" defaultMessage="Unit price excl. tax" /> : <FormattedMessage id="productUnitPriceInclTax" defaultMessage="Unit price incl. tax" />}
                            </Col>
                        )}
                        <Col xs="2">
                            <FormattedMessage id="quantity" defaultMessage="Quantity" />
                        </Col>
                    </Row>

                    {(products || [])
                        .filter(product => !product.isPack)
                        .sort((productA, productB) => getTranslation(productA, 'name', locale).localeCompare(getTranslation(productB, 'name', locale)))
                        .filter(product => product.isAvailable)
                        .filter(product => !(getProductOption(product, 'visibleGroups') || []).length || getProductOption(product, 'visibleGroups').includes((userGroup || {}).identifier))
                        .map((product, index) => (
                        <Row key={product._id} className={classnames('pt-1', index % 2 === 0 && 'bg-superlight')}>
                            <Col xs="7" className="py-1">
                                <strong>{getTranslation(product, 'name', locale)}</strong> - <small><ProductWeight product={product} hideLabel /> - {getCartQuantityStep(product, userGroup) > 1 ? <div className="pt-2"><Icon icon="cube" /> <FormattedMessage id="productPacking" defaultMessage="Box of {count} unit(s)" values={{ count: getCartQuantityStep(product, userGroup) }} /></div> : <em>{getProductAttributeList(product, 'categories', locale).join(', ')}</em>}</small>
                            </Col>
                            {!hidePrice && (
                                <Col xs="3">
                                    {product[displayPriceHT ? 'priceHT' : 'priceTTC'] > getProductPrice(product, 1, userGroup, !displayPriceHT, referral) && (
                                        <small><em className="d-inline-block me-1 text-crossed text-black-75"><Price amount={product[displayPriceHT ? 'priceHT' : 'priceTTC']} /></em></small>
                                    )}
                                    <strong className="text-danger"><Price amount={getProductPrice(product, 1, userGroup, !displayPriceHT, referral)} /></strong>
                                </Col>
                            )}
                            <Col xs="2">
                                {' '}
                            </Col>
                        </Row>
                    ))}

                    {!this.props.hidePacks && (
                        <Fragment>
                            {(products || []).filter(product => product.isPack).length > 0 && <h5 className="mt-4"><FormattedMessage id="companyPacksTitle" defaultMessage="Our packs" /></h5>}
                            {(products || [])
                                .filter(product => product.isPack)
                                .sort((packA, packB) => getTranslation(packA, 'name', locale).localeCompare(getTranslation(packB, 'name', locale)))
                                .filter(pack => pack.isAvailable)
                                .filter(product => !(getProductOption(product, 'visibleGroups') || []).length || getProductOption(product, 'visibleGroups').includes((userGroup || {}).identifier))
                                .map((pack, index) => (
                                <Row key={pack._id} className={classnames('pt-1', index % 2 === 0 && 'bg-superlight')}>
                                    <Col className="py-1">
                                        <strong>{getTranslation(pack, 'name', locale)}</strong><br />
                                        {pack.items.length > 0 && (
											<Fragment>
												<FormattedMessage
													id="productCount"
													defaultMessage="{count} product(s)"
													values={{
														count: formatPrice(pack.items.reduce((quantity, item) => quantity + ((item && item.quantity * ((item.items || []).length ? item.items.reduce((packItemTotal, packItem) => packItemTotal + packItem.quantity, 0) : 1)) || 0), 0)),
													}}
												/> : {pack.items.map(item => ({ ...item, ...(products || []).find(product => product._id === item.productId) })).filter(product => product).map(product => `${product.quantity} x ${getTranslation(product, 'name', locale)}`).join(', ')}
											</Fragment>
										)}
                                    </Col>
                                    {!hidePrice && (
                                        <Col xs="2">
                                            {pack[displayPriceHT ? 'priceHT' : 'priceTTC'] > getProductPrice(pack, 1, userGroup, !displayPriceHT, referral) && (
                                                <small><em className="d-inline-block me-1 text-crossed text-black-75"><Price amount={pack[displayPriceHT ? 'priceHT' : 'priceTTC']} /></em></small>
                                            )}
                                            <strong className="text-danger"><Price amount={getProductPrice(pack, 1, userGroup, !displayPriceHT, referral)} /></strong>
                                        </Col>
                                    )}
                                    <Col xs="2">
                                        {' '}
                                    </Col>
                                </Row>
                            ))}
                        </Fragment>
                    )}
                </div>
                <div className="page-breaker" />
            </div>
        );
    }
}

OrderForm.defaultProps = {
    products: [],
    user: null,
    userGroup: null,
    hidePrice: false,
    hideForm: false,
    hidePacks: false,
};

OrderForm.propTypes = {
    locale: PropTypes.object.isRequired,
    products: PropTypes.arrayOf(PropTypes.object),
    user: PropTypes.object,
    userGroup: PropTypes.object,
    hidePrice: PropTypes.bool,
    hideForm: PropTypes.bool,
    hidePacks: PropTypes.bool,
};

export default OrderForm;
