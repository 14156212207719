import React, { Component, Fragment, lazy, Suspense, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Navbar, Nav, Button } from 'react-bootstrap';
// import io from 'socket.io-client';

import { AFFILIATION_QUERY_PARAM, setCurrentAffiliation } from '../../../Affiliation/AffiliationActions.js';
import { getLoggedUser, getUserRolesForConsole, getUserPreference } from '../../../User/UserActions.js';
import { getUserGroupRequest } from '../../../User/UserGroupActions.js';
import { initCurrency } from '../../../Currency/CurrencyActions.js';
import { setReferral, initPersistedData, getReferral, getRedirect, setRedirect } from '../../AppActions.js';
import { initApp, checkVersionRequest } from '../../AppHelpers.js';
import { initLanguage, switchLanguage } from '../../../Intl/IntlActions.js';
import { getLanguagePath, getPreferedLanguage } from '../../../Intl/IntlHelper.js';
import { displayErrors, removeError } from '../../../Error/ErrorActions.js';

// import socketListener from '../../../../socketListener.js';

import { enabledLanguages } from '../../../../../Intl/setup.js';

import withRouter from '../../../../components/Router/WithRouter.js';
import Icon from '../../../../components/Content/Icon.js';
import AppLogo from '../view/AppLogo.js';
import Menu from '../menu/Menu.js';
import CmsPanel from '../../../Cms/components/content/CmsPanel.js';
import LocaleSwitcher from '../../../Intl/components/switch/LocaleSwitcher.js';
import CurrencySwitcher from '../../../Currency/components/switch/CurrencySwitcher.js';
import UserGroupHeaderInfo from '../../../User/components/view/UserGroupHeaderInfo.js';
import CartItemsBubble from '../../../Cart/components/view/CartItemsBubble.js';
// import TaskSummary from '../../../Task/components/list/TaskSummary.js';

import SearchMini from '../search/SearchMini';

// const socket = io(config.ws);

function Header({ dispatch, navigate, location, params, intl, appRedirect = '', appIsMobile = false }) {
	const [isMounted, setIsMounted] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [isScrolling, setIsScrolling] = useState(true);
	const [displaySearch, setDisplaySearch] = useState(false);
    const scrollThreshold = 30;

    const initLanguage = () => {
        if(!params.lang || !enabledLanguages.includes(params.lang)) {
            // navigate(getLanguagePath(location.pathname, getPreferedLanguage())); // Cause force redirection (Google Cache, WebsiteAuditor leak,....)
        } else {
            switchLanguage(params.lang, intl);
        }
        // if(this.props.user) {
        //     this.switchLanguage(this.props.params.lang);
        // }
    };

    const handleRedirect = () => {
		// console.log('AppRedirect::: ', appRedirect);
        navigate(getLanguagePath(appRedirect, intl.locale), { replace: true });
        dispatch(setRedirect(null));
    };

    const handleScroll = event => {
        const newIsScrolling = (document.body.scrollTop || document.documentElement.scrollTop) > scrollThreshold;
        if(newIsScrolling !== isScrolling) {
			setIsScrolling(newIsScrolling);
        }
    };

    const handleClick = event => {
		setIsOpen(false);
    };

    const handleGoToAccount = () => navigate(getLanguagePath('/user/profile', intl.locale));

    const handleGoToCart = () => navigate(getLanguagePath('/cart', intl.locale));

    const toggle = event => {
        event && event.stopPropagation();
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        // this.props.dispatch(initSocket(socket));
        // this.props.user && socketListener(socket, this.props.dispatch, this.props.user, this.props.intl);

        // dispatch(initPersistedData()); // moved in index.js entry
		dispatch(initApp());

        // window && window.addEventListener('scroll', this.handleScroll);
        // this.handleScroll();
        // window && window.addEventListener('click', this.handleClick);

        if(appRedirect) {
            handleRedirect();
        }

        // console.log('Search query', query);

        // initLanguage();
		setIsMounted(true);
	}, []);

    useEffect(() => {
		if(isMounted) {
			window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
			dispatch(checkVersionRequest());
			setIsOpen(false);
		}
	}, [location]);

	// if(!prevProps.user && this.props.user) {
		// this.props.user && socketListener(socket, this.props.dispatch, this.props.user, this.props.intl);
	// }

	useEffect(() => {
		appRedirect && handleRedirect();
	}, [appRedirect]);

    const renderOpenMenu = () => {
        return (
            <div className="w-100 mt-2 ms-3 pe-md-4 d-md-flex justify-content-end">
                <div className="border-right">
                    <CmsPanel slug="main-menu" isTemplate closeMenu={toggle} />
                </div>
                <div className="me-md-2">
                    <ul className="ms-2 list-unstyled text-lg">
                        <li className="mb-md-2">
                            <Button variant="link" className="m-0 p-0 nav-link text-decoration-none" onClick={handleGoToAccount}>
                                <Icon icon="user" />
                                {' '}<FormattedMessage id="userAccountMy" defaultMessage="My account" />
                            </Button>
                        </li>
                        <li>
                            <Button variant="link" className="m-0 p-0 nav-link text-decoration-none" onClick={handleGoToCart}>
                                <Icon icon="shopping-cart" />
                                {' '}<FormattedMessage id="cartMy" defaultMessage="My cart" />{' '}
                                <CartItemsBubble />
                            </Button>
                        </li>
                    </ul>
                </div>
            </div>
        );
    };

	return (
		<div className={classnames('fixed-top', 'header-wrapper', isScrolling && 'header-scrolled', isOpen && 'menu-open')}>
			<Container fluid="xl" className="p-1">
				<div className="d-flex justify-content-between">
					<a href={getLanguagePath('/', intl.locale)} className="site-logo-wrapper d-inline-block float-start p-1">
						{
							appIsMobile
							? <AppLogo width="65" height="40" suffix="D" className="site-logo" />
							: <AppLogo width="186" height="40" className="site-logo" />
						}
						<span className="d-none d-md-block baseline"><CmsPanel slug="app-baseline" isTemplate /></span>
					</a>

					<div className="ms-auto me-2">
						<div className="top-bar">
							<div className="d-flex justify-content-end">
								{!displaySearch && (
									<Fragment>
										<UserGroupHeaderInfo />
										<LocaleSwitcher />
										<CurrencySwitcher />
									</Fragment>
								)}
								<SearchMini toggle={val => setDisplaySearch(val)} />
							</div>
						</div>
						<div className="bottom-bar general-menu">
							<div id="general-menu" className={classnames(isOpen ? ['d-block'] : ['d-none'])}>
								{renderOpenMenu()}
							</div>
							{!isOpen && (
								<Nav bsPrefix="navcustom" className={classnames(['d-flex', 'justify-content-end'])}>
									<Nav.Item>
										<Menu />
									</Nav.Item>

									<Nav.Item className="text-uppercase" onClick={toggle}>
										<Button variant="link" aria-label="My account" className="border-0 m-0 p-0 nav-link position-relative text-uppercase">
											<Icon icon={isOpen ? 'times' : 'bars'} className="text-danger" /> Menu
										</Button>
									</Nav.Item>

									<Nav.Item>
										<Button variant="link" aria-label="My account" className="border-0 m-0 p-0 nav-link position-relative" onClick={handleGoToAccount}>
											<span className="d-none d-md-inline"><FormattedMessage id="userAccountMy" defaultMessage="My account" /></span>
											<span className="d-inline d-md-none"><Icon icon="user" /></span>
										</Button>
									</Nav.Item>
									<Nav.Item>
										<Button variant="link" aria-label="My cart" className="border-0 m-0 p-0 nav-link position-relative" onClick={handleGoToCart}>
											<span className="d-none d-md-inline"><FormattedMessage id="cartMy" defaultMessage="My cart" /></span>
											<span className="d-inline d-md-none"><Icon icon="shopping-cart" /></span>
											<CartItemsBubble />
										</Button>
									</Nav.Item>
								</Nav>
							)}
						</div>
					</div>
				</div>
			</Container>
		</div>
	);
}
// <Link to={getLanguagePath('/orders', this.props.intl.locale)}><Icon icon="shopping-cart" /> <span className="d-none d-md-inline"><FormattedMessage id="ordersMy" defaultMessage="My orders" /></span> {subordersCount ? <Badge className="bg-transparent text-secondary" style={{ position: 'absolute', top: '-5px', right: '-10px' }}>{subordersCount}</Badge> : null}</Link>
// <FormattedMessage id="accountMy" defaultMessage="My account" /> ({`${this.props.user.firstName} ${this.props.user.lastName}`})

// {false && !this.isLoggedIn() ? <Link to={getLanguagePath('/user/register', this.props.intl.locale)} variant="light" size="sm"><Icon icon="user-add" /></Link> : ''}

function mapStateToProps(store, props) {
    return {
        appRedirect: getRedirect(store),
		appIsMobile: store.app.isMobile,
    };
}

// Header.defaultProps = {
//     appRedirect: '',
// 	appIsMobile: false,
// };

Header.propTypes = {
    location: PropTypes.object.isRequired,
    navigate: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    appRedirect: PropTypes.string,
	appIsMobile: PropTypes.bool,
};

export default connect(mapStateToProps)(withRouter(injectIntl(Header), ['location', 'navigate', 'params']));
