import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getConfig } from '../../../Config/ConfigActions.js';

import Icon from '../../../../components/Content/Icon.js';
import HtmlComponent from '../../../../components/Text/HtmlComponent.js';

function Disclaimer({ disclaimer = '' }) {
    if(disclaimer) {
        return (
            <div className="p-3 bg-secondary text-center text-white">
                <Icon icon="info" /> <HtmlComponent text={disclaimer} inline />
            </div>
        );
    }
    return null;
}

// <div className="trustpilot-widget" data-locale="fr-FR" data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="5ba10aceafa853000188761b" data-style-height="24px" data-style-width="100%" data-theme="light"> <a href="https://fr.trustpilot.com/review/directos.eu" target="_blank">Trustpilot</a> </div>

function mapStateToProps(store, props) {
    const { locale } = store.intl;
    return {
        locale,
        disclaimer: getConfig(store, `disclaimer-${locale}`),
    };
}

Disclaimer.propTypes = {
    disclaimer: PropTypes.string,
};

export default connect(mapStateToProps)(Disclaimer);
