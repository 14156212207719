// Import Actions
import { SET_ADDRESSES, REMOVE_ADDRESS } from './AddressActions.js';
import { LOGOUT_USER } from '../User/UserActions.js';

// Initial State
export const initialState = {
    data: [],
};

const AddressReducer = (state = initialState, action) => {
    switch(action.type) {

        case SET_ADDRESSES:
            return {
                ...state,
                data: state.data.filter(address => !action.addresses.find(addressToAdd => addressToAdd._id === address._id)).concat(action.addresses),
            };

        case REMOVE_ADDRESS:
            return {
                ...state,
                data: state.data.filter(address => address._id !== action.addressId),
            };

        case LOGOUT_USER:
            return initialState;

        default:
            return state;
    }
};

export default AddressReducer;
