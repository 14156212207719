import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Modal, Button } from 'react-bootstrap';

import { getPanelBySlugAndLanguage, memoizedGetPanelBySlugAndLanguage } from '../../../Cms/CmsActions.js';

import withRouter from '../../../../components/Router/WithRouter.js';
import Icon from '../../../../components/Content/Icon.js';
import CmsPanel from '../../../Cms/components/content/CmsPanel.js';

function VoucherPopin({ panel = null }) {
    const [displayModal, toggleModal] = useState(true);
    const handleClose = () => toggleModal(false);
    if(displayModal && panel) {
        return (
            <Modal show={displayModal} onHide={() => toggleModal(false)} centered contentClassName="bg-info bg-texture">
                <CmsPanel slug={panel.slug} />
                <Button block size="sm" variant="link" outline onClick={handleClose}><Icon icon="close" /> <FormattedMessage id="close" defaultMessage="Close" /></Button>
            </Modal>
        );
    }
    return null;
}

function mapStateToProps(store, props) {
    return {
        panel: memoizedGetPanelBySlugAndLanguage(store, `voucher-popin-${((props.search.get('vouchers') || '').split(',') || [])[0]}`, store.intl.locale),
    };
}

VoucherPopin.propTypes = {
    search: PropTypes.object.isRequired,
    panel: PropTypes.object,
};

export default withRouter(connect(mapStateToProps)(VoucherPopin));
