import slugify from 'slugify';
import { storagePersistent } from './storage.js';

import config from '../config.js';

const KEY_PREFIX = `${slugify(config.application.name).toLowerCase()}.${process.env.NODE_ENV}.persistor.v2.`;

export function persistData(key, data, usePrefix = true) {
    try {
        if(storagePersistent && storagePersistent.setItem) {
            storagePersistent.setItem(`${usePrefix ? KEY_PREFIX : ''}${key}`, data ? JSON.stringify(data) : '');
        }
    } catch (e) {
        console.error(e);
    }
}

export function clearPersistedData(key, usePrefix = true) {
    if(storagePersistent && storagePersistent.removeItem) {
        try {
            return storagePersistent.removeItem(`${usePrefix ? KEY_PREFIX : ''}${key}`);
        } catch (e) {
            return false;
        }
    }
    return false;
}

export function getPersistedData(key, defaultData = {}, usePrefix = true) {
    if(storagePersistent && storagePersistent.getItem) {
        try {
            const value = storagePersistent.getItem(`${usePrefix ? KEY_PREFIX : ''}${key}`);
            const data = value && JSON.parse(value);
            return data || defaultData;
        } catch (e) {
            return defaultData;
        }
    }
    return defaultData;
}
