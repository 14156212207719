// Import Actions
import { SET_SHIPPINGS, REMOVE_SHIPPING } from './ShippingActions.js';
import { LOGOUT_USER } from '../User/UserActions.js';

// Initial State
export const initialState = {
    data: [],
};

const ShippingReducer = (state = initialState, action = {}) => {
    switch(action.type) {
        case SET_SHIPPINGS: {
            const otherShippings = state.data.filter(shipping => !action.shippings.find(shippingToSet => shippingToSet._id === shipping._id));
            return {
                ...state,
                data: otherShippings.concat(action.shippings),
            };
        }

        case REMOVE_SHIPPING: {
            return {
                ...state,
                data: state.data.filter(shipping => shipping._id !== action.id),
            };
        }

        case LOGOUT_USER:
            return initialState;

        default:
            return state;
    }
};

export default ShippingReducer;
