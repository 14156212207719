let storage = null;
let storagePersistent = null;

try {
    if(typeof window !== 'undefined' && typeof ((window || {}).sessionStorage) !== 'undefined') {
        // use the local storage
        storage = (window || {}).sessionStorage;
    }

    if(typeof window !== 'undefined' && typeof ((window || {}).localStorage) !== 'undefined') {
        // use the local storage
        storagePersistent = (window || {}).localStorage;
    }
} catch (err) {
    console.error(err);
}

export default storage;
export { storagePersistent };
