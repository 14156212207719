import React, { Fragment, Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import Icon from '../../../../../components/Content/Icon.js';

import config from '../../../../../config.js';
import { getFile } from '../../../../../util/file.js';

import { getContentBlockItemsByName, getContentBlockItemByName, getContentBlockMixedItem, getContentBlockItemValue } from '../../../CmsActions.js';

import HtmlComponent from '../../../../../components/Text/HtmlComponent.js';
import CmsBlockItem from '../../content/CmsBlockItem.js';
import PredictiveLink from '../../view/PredictiveLink.js';
import PageCover from '../../view/PageCover.js';
import PreFooter from '../../../../App/components/PreFooter.js';

function PageDefault({ page }) {
    const contents = getContentBlockItemsByName(page.blocks, 'content');
    const promotion = getContentBlockItemByName(page.blocks, 'promotion');

    const renderContent = (content, isFullColumn = false, index = 0) => {
        const text = getContentBlockMixedItem(content, 'text') && <HtmlComponent text={getContentBlockMixedItem(content, 'text').text} />;
        const image = getContentBlockMixedItem(content, 'image') && getContentBlockMixedItem(content, 'image').path && <CmsBlockItem item={getContentBlockMixedItem(content, 'image')} type="image" className="mt-3 mt-md-0 w-100" />;
        const cta = getContentBlockMixedItem(content, 'call-to-action') && getContentBlockMixedItem(content, 'call-to-action').text && <div className="text-center"><Button variant={getContentBlockMixedItem(content, 'call-to-action').color || 'warning'} size="lg" as={PredictiveLink} to={getContentBlockMixedItem(content, 'call-to-action').path} className="mt-3">{getContentBlockMixedItem(content, 'call-to-action').text}</Button></div>;
        const position = getContentBlockMixedItem(content, 'position') && getContentBlockMixedItem(content, 'position').text;
        return (
            <div key={index} className={classnames('mb-5', !isFullColumn && 'critical-css-limiter')}>
                {
                    isFullColumn
                    ? (
                        <Fragment>
                            {image}
                            {text}
                            {cta}
                        </Fragment>
                    ) : (
                        <Fragment>
                            <Row>
                                {
                                    (!position || position === 'right')
                                    ? (
                                        <Fragment>
                                            <Col xs="12" md={image ? 8 : 12}>
                                                {text}
                                            </Col>
                                            {image && <Col>{image}</Col>}
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            {image && <Col>{image}</Col>}
                                            <Col xs="12" md={image ? 8 : 12}>
                                                {text}
                                            </Col>
                                        </Fragment>
                                    )
                                }
                            </Row>
                            {cta}
                        </Fragment>
                    )
                }
            </div>
        );
    };

    return (
        <Fragment>
            <PageCover page={page} coverKey="image" />

            <Container className={classnames('mt-lg-n5')}>
                <Card className={classnames('px-2', 'p-md-5')}>
                    <Card.Body>
                        {contents && contents.length && contents.map((content, index) => {
                            if(index === 0 && promotion) {
                                return (
                                    <Row key={index} className="my-3">
                                        <Col xs={{ size: 12, order: 2 }} md={{ size: 7, order: 1 }}>
                                            {renderContent(content, true, index)}
                                        </Col>
                                        <Col xs={{ size: 12, order: 1 }} md={{ size: 5, order: 2 }} className="mb-3">
                                            <div className="d-none d-md-block bg-tertiary" style={{ paddingTop: '120px' }}>
                                                {getContentBlockMixedItem(promotion, 'image') && (
                                                    <div className="d-none d-md-block position-absolute w-100 text-center" style={{ top: '-300px', left: 0 }}>
                                                        <CmsBlockItem item={getContentBlockMixedItem(promotion, 'image')} type="image" style={{ maxHeight: '450px' }} />
                                                    </div>
                                                )}
                                                <div className="p-3">
                                                    {getContentBlockMixedItem(promotion, 'title') && <span className="h6 font-weight-bold">{getContentBlockMixedItem(promotion, 'title').text}</span>}
                                                    {getContentBlockMixedItem(promotion, 'call-to-action') && getContentBlockMixedItem(promotion, 'call-to-action').path && (
                                                        <Button variant="secondary" as={PredictiveLink} to={getContentBlockMixedItem(promotion, 'call-to-action').path} className="d-block mt-3">{getContentBlockMixedItem(promotion, 'call-to-action').text}</Button>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="d-block d-md-none p-3 bg-tertiary">
                                                <div className="text-center"><CmsBlockItem item={getContentBlockMixedItem(promotion, 'image')} type="image" className="mb-3 w-75" /></div>
                                                {getContentBlockMixedItem(promotion, 'title') && <span className="h6 font-weight-bold">{getContentBlockMixedItem(promotion, 'title').text}</span>}
                                                {getContentBlockMixedItem(promotion, 'call-to-action') && getContentBlockMixedItem(promotion, 'call-to-action').path && getContentBlockMixedItem(promotion, 'call-to-action').text && (
                                                    <Button variant="secondary" as={PredictiveLink} to={getContentBlockMixedItem(promotion, 'call-to-action').path} className="d-block mt-3">{getContentBlockMixedItem(promotion, 'call-to-action').text}</Button>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                );
                            }
                            return renderContent(content, false, index);
                        })}
                    </Card.Body>
                </Card>
            </Container>
            <PreFooter />
        </Fragment>
    );
}

PageDefault.propTypes = {
    page: PropTypes.object.isRequired,
};

export default PageDefault;
