const CategoryViewPageModel = {
    type: 'page',
    name: 'category',
    blocks: [
        {
            name: 'breadcrumbs',
            type: 'link',
        },
        {
            name: 'category', // name or id; MUST be unique
            type: 'text', // || text || link || image || mixed (with children)
        },
        {
            name: 'productsByPage', // name or id; MUST be unique
            type: 'text', // || text || link || image || mixed (with children)
			max: 1,
        },
        {
            name: 'productsOrder',
            type: 'product',
        },
        {
            name: 'title', // name or id; MUST be unique
            max: 1, // max same items allowed
            type: 'text', // || text || link || image || mixed (with children)
        },
        {
            name: 'picto', // name or id; MUST be unique
            max: 1, // max same items allowed
            type: 'image', // || text || link || image || mixed (with children)
        },
        {
            name: 'cover', // name or id; MUST be unique
            max: 2, // max same items allowed
            type: 'image', // || text || link || image || mixed (with children)
        },
        {
            name: 'description',
            type: 'mixed',
            blocks: [
                {
                    name: 'text',
                    max: 1,
                    type: 'html',
                },
                {
                    name: 'image',
                    max: 1,
                    type: 'image',
                },
            ],
        },
        {
            name: 'descriptionAlt',
            type: 'mixed',
            blocks: [
                {
                    name: 'text',
                    max: 1,
                    type: 'html',
                },
                {
                    name: 'image',
                    max: 1,
                    type: 'image',
                },
            ],
        },
        {
            name: 'promotion',
            type: 'panel',
        },
        {
            name: 'categories',
            max: 1,
            type: 'panel',
        },
    ],
};

export default CategoryViewPageModel;
