import React, { Component, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { JSONLD, Product, AggregateRating, Generic, GenericCollection, Review, Author, Rating } from 'react-structured-data';

import config from '../../../../config.js';
import { dateFormat } from '../../../../util/date.js';

import { getReviews, memoizedGetReviews } from '../../../Review/ReviewActions.js';
import { getConfig } from '../../../Config/ConfigActions.js';
import { getPanelBySlugAndLanguage, getContentBlockItemValue, memoizedGetPanelBySlugAndLanguage } from '../../../Cms/CmsActions.js';

function GenericSnippet({ intl, title = '', description = '', image = '', siteTitlePanel = null, rate = 0, reviewsCount = 0, reviews = [] }) {
    const siteTitle = siteTitlePanel && getContentBlockItemValue(siteTitlePanel.blocks, 'text');

    const defaultTitle = `${config.application.name}: ${siteTitle}`;
    // return (
    //     <script type="application/ld+json">
    //         {`{
    //             "@context": "https://schema.org/",
    //             "@type": "Product",
    //             "name": "${title || defaultTitle}",
    //             "image": "${image || `${config.url}assets/images/logo.png`}",
    //             "description": "${description}",
    //             "brand": {
    //                 "@type": "Organization",
    //                 "name": "${defaultTitle}",
    //                 "url": "${`${config.url}${intl.locale}/`}"
    //             },
    //             "aggregateRating": {
    //                 "@type": "AggregateRating",
    //                 "ratingValue": "${rate}",
    //                 "reviewCount": "${reviewsCount}",
    //                 "bestRating": 5
    //             },
    //             "review": [
    //                 ${(reviews || []).filter(review => review.score).map(review => `
    //                     {
    //                         "@type": "Review",
    //                         "name": "${review.title || ''}",
    //                         "reviewBody": "${review.text}",
    //                         "datePublished": "${dateFormat(review.dates.created, 'MM/DD/YYYY')}",
    //                         "author": {
    //                             "@type": "Person",
    //                             "name": "${review.name}"
    //                         },
    //                         "reviewRating": {
    //                             "@type": "Rating",
    //                             "ratingValue": ${review.score}
    //                         }
    //                     }
    //                 `)}
    //             ]
    //         }`}
    //     </script>
    // );

    return (
        <JSONLD dangerouslyExposeHtml>
            <Product
                name={title || defaultTitle}
                image={image || `${config.url}assets/images/logo.png`}
                description={description}
            >
                <Generic
                    type="brand"
                    jsonldtype="Organization"
                    schema={{
                        name: defaultTitle,
                        url: `${config.url}${intl.locale}/`,
                    }}
                />
                <AggregateRating ratingValue={rate} reviewCount={reviewsCount} bestRating={5} />
                {(reviews || []).length > 0 && (
                    <GenericCollection type="review">
                        {(reviews || []).filter(review => review.score).map(review => (
                            <Review key={review._id} name={review.title} reviewBody={review.text} datePublished={dateFormat(review.dates.created, 'MM/DD/YYYY')}>
                                <Author name={review.name} />
                                <Rating ratingValue={review.score} />
                            </Review>
                        ))}
                    </GenericCollection>
                )}
            </Product>
        </JSONLD>
    );
}

function mapStateToProps(store, props) {
    return {
        rate: getConfig(store, 'star-rating'),
        siteTitlePanel: memoizedGetPanelBySlugAndLanguage(store, 'site-title', (store.intl || {}).locale),
        reviewsCount: getConfig(store, 'reviews-count'),
        reviews: memoizedGetReviews(store, ['validated']),
    };
}

GenericSnippet.propTypes = {
    intl: PropTypes.object.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    rate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    siteTitlePanel: PropTypes.object,
    reviewsCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    reviews: PropTypes.arrayOf(PropTypes.object),
};

export default connect(mapStateToProps)(injectIntl(GenericSnippet));
