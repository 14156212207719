import React, { useEffect, Fragment, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Row, Col, Card, ButtonGroup, Button, Badge, Alert } from 'react-bootstrap';

import config from '../../../../../config.js';
import { getFile } from '../../../../../util/file.js';
import { dateFormat } from '../../../../../util/date.js';

import { getCmsContentsRequest, getPosts, getContentBlockItemsByName, getContentBlockItemByName, getContentBlockMixedItems, getContentBlockMixedItem, getContentBlockItemValue, CMS_BLOG_POSTS_COUNT, memoizedGetPosts } from '../../../CmsActions.js';
import { getUserGroup, USER_GROUP_DEFAULT } from '../../../../User/UserGroupActions.js';
import { getLanguagePath } from '../../../../Intl/IntlHelper.js';

import withRouter from '../../../../../components/Router/WithRouter.js';
import HtmlComponent from '../../../../../components/Text/HtmlComponent.js';
import CmsBlockItem from '../../content/CmsBlockItem.js';
import CmsPanel from '../../content/CmsPanel.js';
import PreFooter from '../../../../App/components/PreFooter.js';
import PredictiveLink from '../../view/PredictiveLink.js';
import Loader from '../../../../../components/Loader/Loader.js';
import PageCover from '../../view/PageCover.js';

function PageBlog({ dispatch, intl, search, page, posts = [], userGroup = null }) {
	const didMount = useRef(false);
	const [isLoading, setIsLoading] = useState(false);

	const pageIndex = search.get('page');
    const currentPage = parseInt(pageIndex || 1, 10);
    let hasMorePosts = !!posts.length;

    useEffect(() => {
		console.log('PageBlog::: useEffect', didMount?.current, search);
        if(didMount?.current) {
			setIsLoading(true);
			window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
			dispatch(getCmsContentsRequest('post', intl.locale, currentPage)).then(() => {
				setIsLoading(false);
			});
		} else {
			didMount.current = true;
		}
    }, [search]);

    posts = posts.sort((postA, postB) => new Date(getContentBlockItemValue(postB.blocks, 'date', 'date')) - new Date(getContentBlockItemValue(postA.blocks, 'date', 'date')));
    posts = posts.slice((currentPage - 1) * CMS_BLOG_POSTS_COUNT, ((currentPage - 1) * CMS_BLOG_POSTS_COUNT) + CMS_BLOG_POSTS_COUNT + 1);
    hasMorePosts = posts.length >= (CMS_BLOG_POSTS_COUNT + 1);
    posts = posts.slice(0, CMS_BLOG_POSTS_COUNT);

    const cover = getContentBlockItemValue(page.blocks, 'image', 'path');
    const title = getContentBlockItemValue(page.blocks, 'title') || page.title;
    const promotion = getContentBlockItemValue(page.blocks, 'promotion');

    const renderCover = () => {
        const style = {
            height: '350px',
        };
        if(cover) {
            style.height = '550px';
            style.backgroundImage = `url(${getFile(cover)})`;
        }
        return (
            <div className={classnames(cover && 'header-cover', 'page-wrapper', 'bg-info')} style={style}>
                <Container className="ms-2 mx-md-auto mt-5 pt-5">
                    <h1 className={classnames('mt-2', 'ms-2', 'ms-md-0', 'w-100', 'w-md-50', 'border-half-danger', cover ? 'text-white' : 'text-dark')}>
                        {title}
                    </h1>
                </Container>
            </div>
        );
    };

    const renderPromotion = (onlyOnSM = false) => {
        if(promotion && ((userGroup || {}).identifier === USER_GROUP_DEFAULT || process.env.NODE_ENV === 'development')) {
            return (
                <Card className={classnames('w-100', 'mb-3', 'mt-0', 'mt-md-3', 'px-3', 'py-4', 'bg-warning', 'rounded', onlyOnSM && ['d-block', 'd-lg-none'])}>
                    <CmsPanel slug={promotion} />
                </Card>
            );
        }
        return null;
    };

    const renderPost = (post, isFullColumn = false) => {
        const title = getContentBlockItemValue(post.blocks, 'title') || post.title;
        const content = getContentBlockItemByName(post.blocks, 'content');
        const date = getContentBlockItemValue(post.blocks, 'date', 'date');
        const tags = getContentBlockItemsByName(post.blocks, 'tags');
        let image = getContentBlockItemByName(post.blocks, 'image');
        if(content) {
            if(!image || !image.path) {
                image = getContentBlockMixedItems(content, 'image').find(image => image.path);
            }
            const text = getContentBlockMixedItem(content, 'text') && <HtmlComponent text={getContentBlockMixedItem(content, 'text').text} inline truncate={100} />;
            return (
                <Card key={title} body className={classnames('p-3', 'mb-3', 'border-bottom')}>
                    <Row className="g-0">
                        <Col xs="12" md="3" className={classnames('me-3', 'rounded', 'text-center', 'text-secondary')}>
                            {image ? <CmsBlockItem item={image} type="image" className="m-0 mb-3 mb-md-0 mt-md-0 p-0 rounded w-100 mh-100" style={{ boxShadow: '10px 10px 0px var(--bs-info)' }} /> : <span className="display-1 d-none d-md-inline" style={{ lineHeight: '0.9em' }}>{dateFormat(date, 'DD')}<br /><strong>{dateFormat(date, 'MM')}</strong></span>}
                        </Col>
                        <Col>
                            <h2><PredictiveLink to={`/${post.language}/${post.slug}`}>{title}</PredictiveLink></h2>
                            <p>{text}</p>
                            <div className="d-flex justify-content-between">
                                <div>
                                    {tags.map(tag => <span key={tag.text}><Badge bg="danger" pill className="bg-opacity-75">{tag.text}</Badge>{' '}</span>)}
                                </div>
                                <PredictiveLink to={`/${post.language}/${post.slug}`}><FormattedMessage id="readMore" defaultMessage="Read more" />{' >'}</PredictiveLink>
                            </div>
                        </Col>
                    </Row>
                </Card>
            );
        }
    };

	const renderPosts = () => {
		return posts.length > 0
		? posts.map((post, index) => (index === 1
			? (
				<Fragment key={index}>
					{renderPromotion(true)}
					{renderPost(post)}
					<span className="critical-css-limiter">{' '}</span>
				</Fragment>
			) : renderPost(post)))
		: (
			<Alert variant="warning" className="text-center">
				<FormattedMessage id="cmsBlogNoPosts" defaultMessage="There is no posts" />
			</Alert>
		);
	};

    const defaultPageTitle = (page.seo || {}).title || page.title;
    return (
        <Fragment>
            <Helmet
                title={currentPage > 1 ? `${defaultPageTitle} - ${currentPage}` : defaultPageTitle}
                link={[
                    currentPage > 1 && {
                        href: `${config.url}${getLanguagePath(`/${(page.slug || '').replace('*', '')}`, page.language).substring(1)}?page=${currentPage - 1}`,
                        rel: 'prev',
                    },
                    hasMorePosts && {
                        href: `${config.url}${getLanguagePath(`/${(page.slug || '').replace('*', '')}`, page.language).substring(1)}?page=${currentPage + 1}`,
                        rel: 'next',
                    },
                ].filter(rel => rel)}
            />

			<PageCover page={page} defaultStyles={{ height: '350px' }} />

            <Container className={classnames('mt-lg-n5', 'clearfix')}>
                <Row>
                    <Col>
						{
							isLoading
							? <Loader />
							: renderPosts()
						}
                    </Col>
                    {promotion && <Col md="3" className="d-none d-lg-block">{renderPromotion()}</Col>}
                </Row>

                <ButtonGroup className="d-inline-block my-auto w-100 text-center">
                    {currentPage > 1 ? <Button variant="outline-secondary" as={Link} to={`?page=${currentPage - 1}`} rel="prev">{'< '}<FormattedMessage id="previous" defaultMessage="Previous" /></Button> : <span>{' '}</span>}
                    {page.data?.count > 0 && new Array(Math.ceil(page.data.count / CMS_BLOG_POSTS_COUNT)).fill(1).map((v, index) => <Button key={index} variant={index + 1 === currentPage ? 'secondary' : 'secondary-outline'} as={Link} to={`?page=${index + 1}`} className={classnames(currentPage === index + 1 && 'rounded')}>{index + 1}</Button>)}
                    {hasMorePosts ? <Button variant="outline-secondary" as={Link} to={`?page=${currentPage + 1}`} rel="next"><FormattedMessage id="next" defaultMessage="Next" />{' >'}</Button> : <span>{' '}</span>}
                </ButtonGroup>

            </Container>

            <PreFooter />
        </Fragment>
    );
}

PageBlog.propTypes = {
    dispatch: PropTypes.func.isRequired,
    search: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
    posts: PropTypes.arrayOf(PropTypes.object),
    userGroup: PropTypes.object,
};

function mapStateToProps(store, props) {
    return {
        posts: memoizedGetPosts(store, store.intl.locale),
        userGroup: getUserGroup(store),
    };
}

export default connect(mapStateToProps)(withRouter(injectIntl(PageBlog), ['search']));
