export default {
    type: 'panel',
    name: 'categories',
    blocks: [
        {
            name: 'panels',
            type: 'panel',
        },
        {
            name: 'content',
            type: 'html',
            max: 1,
        },
    ],
};
