import dayjs from 'dayjs';

import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import 'dayjs/locale/fr';
import 'dayjs/locale/de';
import 'dayjs/locale/nl';

dayjs.extend(utc);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(localizedFormat);

// DAYJS use
export function toDate(date) {
    return dayjs(date || undefined).toDate();
}
// // Check: https://day.js.org/docs/en/display/format
export function dateFormat(date = '', formatString = '') {
    // force utc mode (only GMT)
    return dayjs(date || undefined).format(formatString);
}

export function dateUtc(date) {
    return dateFormat(dayjs(date || undefined).utc());
}

export function dateTimestamp(date) {
    return dayjs(date || undefined).unix();
}

export function dateAdd(date, value, step) {
    return dayjs(date || undefined).add(value, step);
}

export function dateSub(date, value, step) {
    return dayjs(date || undefined).subtract(value, step);
}

// the first date is after the second date
export function dateIsAfter(date1, date2) {
    return dayjs(date1 || undefined).isSameOrAfter(date2 || dayjs());
}

// Is the first date before the second one?
export function dateIsBefore(date1, date2) {
    return dayjs(date1 || undefined).isSameOrBefore(date2 || dayjs());
}

export function dateIsBetween(date1, date2, date3, step = 'day', includes = '[]') {
    return dayjs(date1 || undefined).isBetween(date2, date3, step, includes);
}

export function dateDiff(date1, date2, step, truncate = true) {
    return dayjs(date1 || undefined).diff(date2, step, !truncate);
}

export function dateDuration(seconds, unit) {
    return dayjs.duration(seconds).as(unit);
}

export function dateWeekDay(date) {
    return dayjs(date || undefined).day();
}

export function dateFromNow(date) {
    return dayjs(date || undefined).fromNow();
}

export function dateStartOf(date, step) {
    return dayjs(date || undefined).startOf(step);
}

export function dateEndOf(date, step) {
    return dayjs(date || undefined).endOf(step);
}

export function dateClone(date) {
    return dayjs(date || undefined).clone();
}

export function setDateLanguage(lang) {
    dayjs.locale(lang);
}


// Date-fns use
// import { parseISO, format, add, sub, isAfter, isBefore, getDay, formatDistanceToNow } from 'date-fns';
// import { fr, de } from 'date-fns/locale';
// const LOCALES = {
//     fr,
//     de,
// };
//
// // Check: https://date-fns.org/v2.29.3/docs/format
// export function dateFormat(date = '', formatString = 'PPpp') {
//     // force utc mode (only GMT)
//     // return moment(date).utc().format(format);
//     return format(dateUtc(date), formatString, { locale: LOCALES[currentLocale] });
// }
//
// export function dateUtc(date) {
//     process.env.NODE_ENV === 'development' && console.log('dateUtc', date, new Date(date), parseISO(date), new Date(parseISO(date)).toUTCString());
//     return new Date(parseISO(date)).toUTCString();
// }
//
// export function dateAdd(date, value, step) {
//     return add(new Date(date), {
//         [step]: value,
//     });
// }
//
// export function dateSub(date, value, step) {
//     return sub(new Date(date), {
//         [step]: value,
//     });
// }
//
// // the first date is after the second date
// export function dateIsAfter(date1, date2) {
//     return isAfter(date1, date2);
// }
//
// // Is the first date before the second one?
// export function dateIsBefore(date1, date2) {
//     return isBefore(date1, date2);
// }
//
// export function dateWeekDay(date) {
//     return getDay(date);
// }
//
// export function dateFromNow(date) {
//     return formatDistanceToNow(new Date(date));
// }
//
// export function setDateLanguage(lang) {
//     // currentLocale = lang;
// }
