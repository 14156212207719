import { dateIsBefore } from '../../util/date.js';

export const ORDER_CHARGES = {
    payment: 2.36,
    shipping: 16,
};

// Fixed charges by month
export const MONTHLY_CHARGES = [
    {
        date: '2023-05-01',
        ads: 3390, // Google Ads + Facebook Ads
        communication: 20 + 10, // Newsletter + SMS
        rental: 360,
        employee: 400,
        insurance: 35,
        seo: 600,
        server: 60,
        other: 180, // Marketing mgmt
    },
    {
        date: '2023-03-01',
        ads: 3390, // Google Ads + Facebook Ads
        communication: 20 + 10, // Newsletter + SMS
        rental: 360,
        employee: 0,
        insurance: 35,
        seo: 600,
        server: 60,
        other: 180, // Marketing mgmt
    },
    {
        date: '2022-02-01',
        ads: 3000, // Google Ads + Facebook Ads
        communication: 20 + 10, // Newsletter + SMS
        rental: 360,
        employee: 0,
        insurance: 35,
        seo: 600,
        server: 60,
        other: 150, // Marketing mgmt
    },
    {
        date: '2022-12-01',
        ads: 3000, // Google Ads + Facebook Ads
        communication: 20 + 10, // Newsletter + SMS
        rental: 360,
        employee: 0,
        insurance: 35,
        seo: 500,
        server: 60,
        other: 120, // Marketing mgmt
    },
    {
        date: '2022-09-01',
        ads: 2440, // Google Ads + Facebook Ads
        communication: 20 + 10, // Newsletter + SMS
        rental: 360,
        employee: 0,
        insurance: 35,
        seo: 500,
        server: 60,
        other: 120, // Marketing mgmt
    },
    {
        date: '2022-08-01',
        ads: 2440, // Google Ads + Facebook Ads
        communication: 20 + 10, // Newsletter + SMS
        rental: 360,
        employee: 450,
        insurance: 35,
        seo: 200,
        server: 60,
        other: 120, // Marketing mgmt
    },
    {
        date: '2022-07-18',
        ads: 2340, // Google Ads + Facebook Ads
        communication: 20 + 10, // Newsletter + SMS
        rental: 360,
        employee: 450,
        insurance: 35,
        seo: 200,
        server: 60,
        other: 120, // Marketing mgmt
    },
    {
        date: '2022-05-31',
        ads: 2340, // Google Ads + Facebook Ads
        communication: 20 + 10, // Newsletter + SMS
        rental: 360,
        employee: 0,
        insurance: 35,
        seo: 200,
        server: 60,
        other: 120, // Marketing mgmt
    },
    {
        date: '2022-01-01',
        ads: 4000, // Google Ads + Facebook Ads
        communication: 50 + 10, // Newsletter + SMS
        rental: 360,
        employee: 0,
        insurance: 35,
        seo: 200,
        server: 60,
        other: 120, // Marketing mgmt
    },
    {
        date: '2021-01-01',
        ads: 3000, // Google Ads + Facebook Ads
        communication: 20, // Newsletter
        rental: 0,
        employee: 0,
        insurance: 0,
        seo: 200,
        server: 60,
        other: 120, // Marketing mgmt
    },
    {
        date: '2020-01-01',
        ads: 2000, // Google Ads + Facebook Ads
        rental: 0,
        employee: 0,
        insurance: 0,
        seo: 200,
        server: 60,
        other: 120, // Marketing mgmt
    },
];

export function getMonthlyCharges(date = null) {
    if(date) {
        return MONTHLY_CHARGES.find(charge => dateIsBefore(charge.date, date));
    }
    return MONTHLY_CHARGES[0];
}
