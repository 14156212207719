import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import VisibilitySensor from 'react-visibility-sensor';

import { getPageBySlugAndLanguage, getCmsInfoFromPath, getCmsPageRequest } from '../../CmsActions';

function PredictiveLink({ dispatch, to, children, content = null, appIsMobile = false, ...props }) {
	const [isFetched, setIsFetched] = useState(false);
	const destination = getCmsInfoFromPath(to);

	const handlePreload = () => {
		if(!content) {
			!isFetched && dispatch(getCmsPageRequest(destination.slug, destination.language, false, destination.search));
			setIsFetched(true);
		}
	};

	const handleVisibilityChange = isVisible => {
		isVisible && handlePreload();
	};

	if(appIsMobile) {
		return (
			<VisibilitySensor onChange={handleVisibilityChange}>
				<Link to={to} {...props}>{children}</Link>
			</VisibilitySensor>
		);
	}

	return (
		<Link to={to} onMouseOver={handlePreload} {...props}>{children}</Link>
	);
}

PredictiveLink.propTypes = {
	dispatch: PropTypes.func.isRequired,
	to: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	content: PropTypes.object,
	appIsMobile: PropTypes.bool,
};

const mapStateToProps = (store, props) => {
	const info = getCmsInfoFromPath(props.to);
	return {
		content: getPageBySlugAndLanguage(store, info.slug, info.language),
		appIsMobile: store.app.isMobile,
	};
};

export default connect(mapStateToProps)(PredictiveLink);
