import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Modal, Button } from 'react-bootstrap';

import { getLoggedUser, isUserSubscribedToNewsletter } from '../../UserActions.js';

import withRouter from '../../../../components/Router/WithRouter.js';
import Icon from '../../../../components/Content/Icon.js';
import UserNewsletterSubscribe from './UserNewsletterSubscribe.js';
import { isPopinActive, setPopinActive } from '../../../App/AppActions.js';

class NewsletterPopin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alreadyDisplayed: false,
            displayModal: false,
            navigationCount: 0,
        };
    }

    componentDidMount() {
        // this.initTimer(process.env.NODE_ENV === 'development' ? 5 : 40);
    }

    componentDidUpdate(prevProps, prevState) {
        if(!this.state.alreadyDisplayed && this.state.navigationCount && this.state.navigationCount % 5 === 0) { // do not work with react-router v6
            this.displayModal();
        }
        if(prevProps.location.pathname !== this.props.location.pathname) {
            this.setState({
                navigationCount: this.state.navigationCount + 1,
            });
        }
    }

    initTimer = (seconds = 0) => {
        setTimeout(() => {
            this.displayModal();
        }, seconds * 1000); // 20s
    };

    displayModal = () => {
        if(!this.state.alreadyDisplayed) {
            if(this.props.popinActive) {
                // this.initTimer(20);
            } else {
                this.setState({
                    alreadyDisplayed: true,
                    displayModal: true,
                }, () => {
                    // console.log('set popin active to', this.state.displayModal);
                    this.props.dispatch(setPopinActive(this.state.displayModal));
                });
            }
        }
    };

    toggle = () => {
        this.setState({
            displayModal: false,
        }, () => {
            this.props.dispatch(setPopinActive(false));
        });
    };

    render() {
        if(
			this.state.displayModal
			&& (!this.props.user || !isUserSubscribedToNewsletter(this.props.user, 'general') || process.env.NODE_ENV === 'development')
			&& !this.props.location.pathname.includes('cart/')
			&& this.props.user?.role === 'customer'
		) {
            return (
                <Modal show={this.state.displayModal} onHide={this.toggle} centered contentClassName="bg-info bg-texture">
                    <UserNewsletterSubscribe />
                    <Button size="sm" variant="link" className="w-100" onClick={this.toggle}><Icon icon="close" /> <FormattedMessage id="close" defaultMessage="Close" /></Button>
                </Modal>
            );
        }
        if(process.env.NODE_ENV === 'development') {
            return <Button onClick={() => this.setState({ alreadyDisplayed: false }, this.displayModal)}>Display newsletter popin</Button>;
        }
        return null;
    }
}
// <ModalHeader toggle={this.toggle}>Newsletter</ModalHeader>
// <ModalBody>
//     Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
// </ModalBody>

function mapStateToProps(store, props) {
    return {
        user: getLoggedUser(store),
        popinActive: isPopinActive(store),
    };
}

NewsletterPopin.defaultProps = {
    user: null,
    popinActive: false,
};

NewsletterPopin.propTypes = {
    location: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.object,
    popinActive: PropTypes.bool,
};

export default connect(mapStateToProps)(withRouter(NewsletterPopin));
