import React, { Component, useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { FormattedMessage } from 'react-intl';

import { stripTags, truncate as truncateUtil } from '../../util/text.js';

function HtmlComponent({ text = '', inline = false, useCollapse = false, truncate = 0, collapsedSize = 0 }) {
    const useParser = true; // Faster with parser

    const [isCollapsed, setCollapse] = useState(false);

    useEffect(() => {
        useCollapse && setCollapse(true);
    }, []);

    const handleOpen = () => {
        setCollapse(false);
    };

    const transform = (node, index) => {
    };

    const html = truncate ? stripTags(truncateUtil(text, truncate)) : text;

    const renderHtml = () => {
        if(html) {
            const transformedText = useParser ? parse(html) : html;

            return (
                <Fragment>
                    {transformedText}
                    {truncate ? '...' : ''}
                </Fragment>
            );
        }
    };

    const needCollapse = !inline && stripTags(text).length > 400;

    const Tag = inline ? 'span' : 'div';
    const tagProps = {
		className: 'html-text-wrapper',
    };

    if(!useParser) {
        tagProps.dangerouslySetInnerHTML = {
            __html: html,
        };
    }

	const renderContent = () => (useParser ? <Tag {...tagProps}>{renderHtml()}</Tag> : <Tag {...tagProps} />);

	if(needCollapse) {
		return (
			<div {...tagProps}>
				<div className="overflow-hidden" style={{ height: isCollapsed ? `${collapsedSize || 10}rem` : 'auto' }}>
					{renderContent()}
				</div>
				{isCollapsed && needCollapse && <Tag className="mt-1 text-end text-lg text-decoration-underline see-more" onClick={handleOpen}> <span><FormattedMessage id="readMore" defaultMessage="Read more" />{' >'}</span></Tag>}
			</div>
		);
	}
	return renderContent();
}

HtmlComponent.propTypes = {
    text: PropTypes.string,
    inline: PropTypes.bool,
    useCollapse: PropTypes.bool,
    truncate: PropTypes.number,
    collapsedSize: PropTypes.number,
};

export default HtmlComponent;
