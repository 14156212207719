export default {
    type: 'panel',
    name: 'text',
    blocks: [
        {
            name: 'text', // name or id; MUST be unique
            label: 'Text', // Display name, if not set, name is used
            max: 1, // max same items allowed
            type: 'text', // || text || link || image || mixed (with children)
            // items: [{text: '<p>azerty 123456</p>'}], // values saved inserted in content model
        },
    ],
};
