import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import config from '../../../../config';

import { getFile } from '../../../../util/file';
import { getDefaultLanguage, getEnabledLanguages, getLanguagePath, getTranslation } from '../../../Intl/IntlHelper';

import { getCmsPageSeo, getCmsPageSeoDescription, getCmsPageSeoTitle, getContentBlockItemValue, getPanelBySlugAndLanguage, memoizedGetPanelBySlugAndLanguage } from '../../../Cms/CmsActions';
import { getProductSlug } from '../../ProductActions';

function ProductSeo({ product, page, intl, siteTitlePanel = null }) {
    const getTitle = () => {
        return getCmsPageSeoTitle(page, siteTitlePanel && getContentBlockItemValue(siteTitlePanel.blocks, 'text'), getTranslation(product, 'subtitle', intl.locale) || getTranslation(product, 'name', intl.locale));
    };

    const getProductSeo = () => {
        const title = getTitle();
        const description = getCmsPageSeoDescription(page, getTranslation(product, 'description', intl.locale));

        const image = product.pictures.find(picture => picture.includes('jpg'));
        const video = product.pictures.find(picture => picture.includes('mp4') || picture.includes('gif'));

        const seoData = {
            title,
            meta: [
                {
                    name: 'og:title',
                    content: title,
                },
                {
                    name: 'description',
                    content: description,
                },
                {
                    name: 'og:description',
                    content: description,
                },
                {
                    name: 'keywords',
                    content: ((page || {}).seo || {}).keywords || '',
                },
                {
                    name: 'app:cms',
                    content: 'page/product',
                },
                {
                    name: 'robots',
                    content: getCmsPageSeo(page, 'hideOnSitemap') ? 'noindex, nofollow' : 'index, follow',
                },
            ],
            link: (
                // ((page.slug && page.slug.replace('*', '') !== (this.props.params.slug || '')) || Object.fromEntries(this.props.search).length)
                // ? [{
                //     href: `${config.url}${getLanguagePath(`/${(page.slug || getProductSlug(product, intl.locale)).replace('*', '')}`, intl.locale).substring(1)}`,
                //     rel: 'canonical',
                // }]
                // : [{
                //     href: '',
                //     rel: 'canonical',
                // }]
                []
            ).concat(image ? [
                {
                    href: getFile(image),
                    rel: 'preload',
                    type: `image/${image.split('.').pop()}`,
                    as: 'image',
					fetchpriority: 'high',
                },
            ] : []).concat(video ? [{
                href: getFile(video),
                rel: 'preload',
                type: `video/${video.split('.').pop()}`,
                as: 'video',
				fetchpriority: 'low',
            }] : []),
        };

        if(page.title && page.slug) {
            const defaultTranslation = getDefaultLanguage() === intl.locale ? page : (page.translations || []).find(translation => translation.language === getDefaultLanguage());
            seoData.link = (seoData.link || [])
            .concat(getEnabledLanguages().map(language => {
                    const existingTranslation = (page.translations || []).concat([page]).find(translation => translation.language === language);
                    return existingTranslation
                    ? {
                        href: `${config.url}${getLanguagePath(`/${existingTranslation.slug.replace('*', '')}`, existingTranslation.language).substring(1)}`,
                        rel: 'alternate',
                        hreflang: existingTranslation.language,
                    }
                    : {
                        href: `${config.url}${getLanguagePath(`/${getProductSlug(product, language)}`, language).substring(1)}`,
                        rel: 'alternate',
                        hreflang: language,
                    };
            }));
        } else {
            seoData.link = [].concat(
                getEnabledLanguages().map(language => ({
                    href: `${config.url}${getLanguagePath(`/${getProductSlug(product, language)}`, language).substring(1)}`,
                    rel: 'alternate',
                    hreflang: language,
                })),
            );
        }
        if(product.pictures && product.pictures[0]) {
            seoData.meta.push(
                {
                    name: 'og:image',
                    content: getFile(product.pictures[0]),
                },
            );
        }
        // console.log(seoData);
        return seoData;
    };

	return (
		<Helmet {...getProductSeo()}>
			<body data-header-contrast="false" />
		</Helmet>
	);
}

function mapStateToProps(store, props) {
    return {
        siteTitlePanel: memoizedGetPanelBySlugAndLanguage(store, 'site-title', (store.intl || {}).locale),
    };
}

ProductSeo.propTypes = {
	intl: PropTypes.object.isRequired,
	product: PropTypes.object.isRequired,
	siteTitlePanel: PropTypes.object,
	page: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(injectIntl(ProductSeo));
