import React, { Fragment, Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Row, Col } from 'react-bootstrap';

import config from '../../../config.js';

import { getConfig } from '../../Config/ConfigActions.js';

import LazyLoader from '../../../components/Content/LazyLoader.js';
import UserGroupPanel from '../../User/components/view/UserGroupPanel.js';
import CmsPanel from '../../Cms/components/content/CmsPanel.js';

import UserNewsletterSubscribe from '../../User/components/newsletter/UserNewsletterSubscribe.js';
import { getUserGroup, getUserGroupOption } from '../../User/UserGroupActions.js';

function PreFooter({ userGroup, isSubscriptionDisabled = false }) {
    return (
        <Fragment>
			<div className="py-md-4 bg-white">
				<Container>
					<Row className="g-0 py-3">
						<Col xs="12" lg="6">
							<UserGroupPanel />
						</Col>
						<Col xs="12" lg="6">
							<UserNewsletterSubscribe />
						</Col>
					</Row>
				</Container>
			</div>
			{(!userGroup || getUserGroupOption(userGroup, 'allowSubscription')) && !isSubscriptionDisabled && (
				<div className="py-md-4 bg-info bg-texture">
					<Container>
						<Row>
							<Col xs="12" md="8" xl="6">
								<div className="p-2 p-md-0 text-center text-md-start">
									<CmsPanel slug={`subscription-disclaimer-${userGroup?.identifier || userGroup}`} isTemplate />
								</div>
							</Col>
							<Col md="4" xl="6">
								<img src={`${config.url}assets/images/panel-subscription-box.jpg`} alt="Box subscription" className="w-100 rounded" loading="lazy" />
							</Col>
						</Row>
					</Container>
				</div>
			)}
        </Fragment>
    );
    // return null;
}

function mapStateToProps(store, props) {
	return {
		userGroup: getUserGroup(store),
		isSubscriptionDisabled: !!getConfig(store, 'is-subscription-disabled'),
	};
}

PreFooter.propTypes = {
	userGroup: PropTypes.object.isRequired,
	isSubscriptionDisabled: PropTypes.bool,
};

export default connect(mapStateToProps)(PreFooter);
