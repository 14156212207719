import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import VisibilitySensor from 'react-visibility-sensor';
import { getFileUrl, getFile } from '../../util/file.js';

import Icon from './Icon.js';

export default class FileLoader extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            url: props.useAppUrl ? getFile(props.filename) : '',
            isLoading: props.useAppUrl || false,
            isUrlFeched: props.useAppUrl || false,
            isLoaded: props.useAppUrl || false,
            loadedUrls: {},
        };

        this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
        this.handleLoadFile = this.handleLoadFile.bind(this);
    }

    componentDidMount() {
        if(this.props.loadonmount || !this.props.delayDisplay) {
            this.handleLoadFile();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.filename !== this.props.filename) {
            this.handleLoadFile();
        }
    }

    handleVisibilityChange(isVisible) {
        if(isVisible && !this.state.isLoaded && !this.state.isLoading) {
            this.handleLoadFile();
        }
    }

    handleLoadFile() {
        if(this.props.filename) {
            if(this.state.loadedUrls[this.props.filename]) {
                this.setState({
                    url: this.state.loadedUrls[this.props.filename],
                    isUrlFeched: true,
                    isLoaded: true,
                });
            } else if(this.props.useAppUrl && !this.props.owner) {
                this.setState({
                    url: getFile(this.props.filename),
                    isUrlFeched: true,
                    isLoaded: true,
                });
            } else {
                this.setState({
                    isLoading: true,
                });
                getFileUrl(this.props.filename, this.props.owner).then(url => {
                    this.setState({
                        url,
                        isLoading: false,
                        isLoaded: true,
                        isUrlFeched: true,
                        loadedUrls: {
                            ...this.state.loadedUrls,
                            [this.props.filename]: url,
                        },
                    });
                }).catch(() => {
                    console.error('File not found !');
                    this.setState({
                        isLoading: false,
                    });
                });
            }
        }
    }

    renderFileTag() {
        const { owner, loadonmount, hideplaceholders, filenamePlaceholder, placeholder, tag, useAppUrl, delayDisplay, ...nativeProps } = this.props;
        let fileTag = 'span';

        let props = {
            ...nativeProps,
            onLoad: () => {
                // console.log('image loaded');
                this.setState({ isLoaded: true });
            },
        };
        let content = null;
        if(this.props.filename) {
            const ext = this.props.filename.split('?').shift().split('.').pop();
            switch(ext) {
                case 'jpg':
                case 'jpeg':
                case 'gif':
                case 'png':
                case 'tiff':
                case 'bmp':
                case 'svg':
                case 'webp':
                    fileTag = 'img';
                    props.muted = false;
                    props.src = this.state.url;
                    break;
                    // return <img src={this.state.url} {...this.props} />; // eslint-disable-line

                case 'webm':
                case 'mpeg':
                case 'mp4':
                    fileTag = 'video';
                    props.src = this.state.url;
                    break;

                default:
                    fileTag = 'a';
                    content = (
                        <Fragment>
                            <Icon icon="software-download" /> {this.props.filename.split('/').pop()}
                        </Fragment>
                    );
                    props = {
                        href: this.state.url,
                        download: this.props.filename.split('/').pop(),
                        target: '_blank',
                    };
                    break;
                    // return <a href={this.state.url} download={this.props.filename.split('/').pop()} target="_blank"><Icon icon="software-download" /> {this.props.filename.split('/').pop()}</a>; // eslint-disable-line
            }
        }
        const Tag = tag || fileTag;
        return content ? <Tag {...props}>{content}</Tag> : <Tag {...props} />;
    }

    renderPlaceholder() {
        return (this.props.placeholder || this.props.filenamePlaceholder) && !this.state.isLoaded && (this.props.placeholder || <FileLoader filename={this.props.filenamePlaceholder} />);
    }

    render() {
        if(this.props.filename) {
            const displayFile = () => {
                if(this.state.isUrlFeched) {
                    return (
                        <Fragment>
                            {this.renderFileTag()}
                            {this.renderPlaceholder()}
                        </Fragment>
                    );
                }
                return (
                    <span>
                        {
                            this.props.hideplaceholders || this.props.filenamePlaceholder
                            ? <span>&nbsp;</span>
                            : (
                                this.state.isLoading
                                ? <Icon icon="spinner" spin className="m-3" />
                                : <Icon icon="file-image" className="m-3" />
                            )
                        }
                    </span>
                );
            };

            if(this.props.delayDisplay) {
                return (
                    <VisibilitySensor onChange={this.handleVisibilityChange}>
                        {displayFile()}
                    </VisibilitySensor>
                );
            }
            return displayFile();
        }
        return null;
    }
}

FileLoader.defaultProps = {
    owner: null,
    loadonmount: false,
    hideplaceholders: false,
    filenamePlaceholder: null,
    tag: null,
    useAppUrl: false,
    placeholder: null,
    delayDisplay: false,
};

FileLoader.propTypes = {
    filename: PropTypes.string.isRequired,
    owner: PropTypes.string,
    loadonmount: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    hideplaceholders: PropTypes.bool,
    filenamePlaceholder: PropTypes.string,
    tag: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    useAppUrl: PropTypes.bool,
    placeholder: PropTypes.element,
    delayDisplay: PropTypes.bool,
};
