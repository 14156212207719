import callApi from '../../util/apiCaller.js';

export function registerQuizParticipationRequest(quizName, email, isSubscriber = false, userData = {}) {
    return dispatch => {
        return callApi(`quiz/${quizName}/participate`, 'post', { email, isSubscriber, userData }).then(res => {
            return res && res.ok;
        }).catch(err => {
            console.error(err);
            return null;
        });
    };
}

export function selectWinnersRequest(name, count = 1) {
    return callApi(`quiz/${name}/select/${count}`).then(result => {
        return result;
    }).catch(err => {
        console.error(err);
        return null;
    });
}
