import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import fetch from 'isomorphic-fetch';

import ReactGA4 from 'react-ga4';
// import BugsnagPerformance from '@bugsnag/browser-performance';
import { onINP } from 'web-vitals/attribution';

import { connect } from 'react-redux';
import config from '../../../../config.js';

function AppAnalytics({ appIsMobile = false }) {
	// Core Web Vitals report
	const sendWebVitalsReport = metric => {
		// console.log('CoreWebVitals::: Report', metric);
		const url = '/api/app/webvitals';
		// Replace with whatever serialization method you prefer.
		// Note: JSON.stringify will likely include more data than you need.
		const { entries, attribution, ...metricData } = metric;
		const { processedEventEntries, interactionTargetElement, ...attributionData } = attribution;
		const data = {
			...metricData,
			attribution: attributionData,
			url: window.location.href,
		};
		// console.log(data);

		// Use `navigator.sendBeacon()` if available, falling back to `fetch()`.
		if(navigator.sendBeacon) {
			navigator.sendBeacon(url, JSON.stringify(data));
		} else {
			fetch(
				url,
				{
					body: { metric: JSON.stringify(data) },
					method: 'POST',
					keepalive: true,
					headers: new Headers({ 'Content-Type': 'application/json' }),
				},
			);
		}

		// Send to GA
		if(config.google.analytics.trackingCodeGA4) {
			const eventParams = {
				metric_value: metric.value,
				metric_id: metric.id,
			};

			switch(metricData.name) {
				case 'INP':
					eventParams.debug_target = metric.attribution.eventTarget;
					eventParams.debug_type = metric.attribution.eventType;
					eventParams.debug_time = metric.attribution.eventTime;
					eventParams.debug_load_state = metric.attribution.loadState;
					break;

				// Additional metric logic...
				default:
			}

			// Assumes the global `gtag()` function exists, see:
			// https://developers.google.com/analytics/devguides/collection/ga4
			ReactGA4.gtag('event', metric.name, eventParams);
		}
	};

	useEffect(() => {
		if(process.env.NODE_ENV === 'production' && appIsMobile && config.application.enableINP) {
			// console.log('Listenning for INP...');
			onINP(sendWebVitalsReport, { reportAllChanges: true });
		}

		// if(typeof window !== 'undefined' /* && process.env.NODE_ENV === 'production'*/) {
		//     window.addEventListener('load', () => {
		//         console.log('Window::: Loaded');
				// store.dispatch(setAppLoaded())

				// GA: New
				if(config.google.analytics.trackingCodeGA4) {
					ReactGA4.gtag('consent', 'default', {
						ad_storage: 'denied',
						ad_user_data: 'denied',
						ad_personalization: 'denied',
						analytics_storage: 'granted',
					});
					config.google.analytics.trackingCodeGA4.split(',').forEach(trackingId => {
						ReactGA4.initialize(trackingId, {});
					});
					ReactGA4.ga('set', 'appName', config.application.name);
					ReactGA4.ga('set', 'appVersion', config.version);
					ReactGA4.gtag('consent', 'update', {
						analytics_storage: 'granted',
					});
				}

				// config.bugsnag.key && BugsnagPerformance.start({
				// 	apiKey: config.bugsnag.key,
				// 	appVersion: config.version || '0.0.1',
				// 	releaseStage: process.env.NODE_ENV,
				// 	settleIgnoreUrls: [/.*\/console\//]
				// })
		//     });
		// }
	}, []);
	return null;
}

function mapStateToProps(store, props) {
	return {
		appIsMobile: store.app.isMobile,
	};
}

AppAnalytics.propTypes = {
	appIsMobile: PropTypes.bool,
};

export default connect(mapStateToProps)(AppAnalytics);
