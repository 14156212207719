import { toast } from 'react-toastify';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

export const ADD_ERROR = 'ADD_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';

// Set in App.js
// const position = 'top-right';
// const effect = 'flip';
// const timeout = 3000;

export function displayErrors(type, message) {
    // console.log(type, message);
    // return {
    //     type: TRIGGER_ERROR,
    //     error: {
    //         type: type,
    //         message: message
    //     }
    // }
    message = message.message || message;
    if(message && typeof message === 'string') {
        switch(type) {
            case 'error':
                toast.error(message);
                break;

            case 'warning':
                toast.warning(message);
                break;

            case 'info':
                toast.info(message);
                break;

            case 'success':
                toast.success(message);
                break;

            default:
                toast(`${type} : ${message}`);
        }
    } else if(message !== 'Unauthorized') {
            console.error(message);
        } else {
            // console.log(message);
        }
}

export async function confirmAction(title, message = '', onConfirm = () => {}, onCancel = () => {}) {
    confirmAlert({
        title: title || 'Are you sure ?', // Title dialog
        message: message || 'Please confirm your action', // Message dialog
        buttons: [
            {
              label: 'Yes',
              onClick: onConfirm,
            },
            {
              label: 'No',
              onClick: onCancel,
            },
          ],
    });

    // confirmAlert({
    //     title: title || 'Are you sure ?', // Title dialog
    //     message: message || 'Please confirm your action :', // Message dialog
    //     buttons: [
    //         {
    //             label: 'Cancel',
    //             onClick: cancel,
    //         },
    //         {
    //             label: 'Confirm',
    //             onClick: confirm,
    //         },
    //     ],
    // });
}


export function addError(error, type = 'error') {
    return {
        type: ADD_ERROR,
        error: {
            message: error,
            type,
        },
    };
}

export function removeError(error) {
    return {
        type: REMOVE_ERROR,
        error,
    };
}
