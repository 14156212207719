import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Dropdown, Nav } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getCurrencySymbol, getEnabledCurrencies, initCurrency, switchCurrency } from '../../CurrencyActions';
import { getLoggedUser, setUserPreferenceRequest } from '../../../User/UserActions';

function CurrencySwitcher({ dispatch, currencyData, user = null }) {
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		dispatch(initCurrency(user));
	}, [user]);

	const toggle = () => setIsOpen(!isOpen);

	const handleSwitchCurrency = event => {
        const currency = event.target.getAttribute('data-currency');
        dispatch(switchCurrency(currency));
        user && dispatch(setUserPreferenceRequest('currency', currency));
    };

	const currenciesNodes = getEnabledCurrencies().filter(currency => currency !== currencyData.currency).filter(currency => (currencyData.rates || [])[currency] || currency === 'EUR').map(
		currency => <Dropdown.Item key={currency} as="span" size="sm"><Nav.Link as="span" data-currency={currency} onClick={handleSwitchCurrency} className={classNames('text-dark', currency === currencyData.currency ? 'selected' : '')}>{getCurrencySymbol(currency)}</Nav.Link></Dropdown.Item>,
	);
	return (
		<Dropdown show={isOpen} onToggle={toggle} className="ms-3 align-content-center">
			<Dropdown.Toggle variant="link" size="sm" as="span" className="p-0" suppressHydrationWarning>
				{getCurrencySymbol(currencyData.currency)}
			</Dropdown.Toggle>
			<Dropdown.Menu className="position-absolute text-center" style={{ left: '-200%' }} suppressHydrationWarning>{currenciesNodes}</Dropdown.Menu>
		</Dropdown>
	);
}

function mapStateToProps(store, props) {
	return {
		currencyData: store.currency,
		user: getLoggedUser(store),
	};
}

CurrencySwitcher.propTypes = {
	dispatch: PropTypes.func.isRequired,
	currencyData: PropTypes.object.isRequired,
	user: PropTypes.object,
};

export default connect(mapStateToProps)(CurrencySwitcher);
