import { defaultLanguage, enabledLanguages } from '../../../Intl/setup.js';

export function getDefaultLanguage() {
    return defaultLanguage;
}

export function getEnabledLanguages() {
    return enabledLanguages;
}

// @TODO:
// Browser: Check cookie to retrieve prefered language in case of missing in url
// Node: Check request
export function getPreferedLanguage() {
    let lang = ((global.navigator || {}).language || '').split('-')[0] || defaultLanguage;
    if(!enabledLanguages.includes(lang)) {
        lang = defaultLanguage;
    }
    // console.log('Preferred Language choosen', lang);
    return lang;
}

export function getCountryFromLanguage(lang) {
    switch(lang) {
        case 'en':
            return 'GB';

        default:
            return lang && lang.toUpperCase();
    }
}

export function getTranslation(model, field, lang, force = false) {
    const translation = ((model || {}).translations || []).find(translation => translation.lang === lang);
    if((translation && translation[field]) || force) {
        return (translation || {})[field] || '';
    }
    return (model || {})[field] || '';
}

export function getLanguagePath(path, lang = defaultLanguage) {
    let replaced = false;
    path = `${(path || '')[0] !== '/' ? '/' : ''}${path || ''}`;
    enabledLanguages.forEach(language => {
        if(path && !replaced) {
            if(path.split('/').length <= 2) {
                path = path.replace(`/${language}`, '/');
                replaced = true;
            } else if(path.indexOf(`/${language}/`) !== -1) {
                path = path.replace(`/${language}/`, '/');
                replaced = true;
            // } else if(path.indexOf(`/${language}`) !== -1) {
            //     path = path.replace(`/${language}`, '/');
            }
        }
    });
    return `/${lang}${path || ''}`.toLowerCase();
}
