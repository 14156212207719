import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import withRouter from '../../../../components/Router/WithRouter';
import { getUserGroup, getUserGroupRequest } from '../../UserGroupActions';

function UserGroupInit({ dispatch, search, userGroup }) {
	useEffect(() => {
		if(search?.get('group') && userGroup?.identifier !== search.get('group')) {
			dispatch(getUserGroupRequest());
		}
	}, [search]);

	return null;
}

function mapStateToProps(store, props) {
	return {
		userGroup: getUserGroup(store),
	};
}

UserGroupInit.propTypes = {
  dispatch: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
  userGroup: PropTypes.object,
};

export default connect(mapStateToProps)(withRouter(UserGroupInit, ['search']));
