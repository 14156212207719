import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import withRouter from '../../../../components/Router/WithRouter';
import { sequence } from '../../../../util/promises';
import { applyVoucherRequest } from '../../VoucherActions';

function CartAutoAddVoucher({ dispatch, search }) {
	useEffect(() => {
		// Handle automatic voucher apply: ?vouchers=code1,code2
        if(search.get('vouchers')) {
            sequence(search.get('vouchers').split(','), voucher => dispatch(applyVoucherRequest(voucher, true)));
        }
	}, [search]);
	return null;
}

CartAutoAddVoucher.propTypes = {
	dispatch: PropTypes.func.isRequired,
	search: PropTypes.object.isRequired,
};

export default connect()(withRouter(CartAutoAddVoucher));
