import React, { lazy } from 'react';

import { getPanelModelViewer, CMS_PANEL_MODELS } from './CmsPanels.js';

import PageDefaultModel from './components/model/pages/PageDefaultModel.js';
import PageVouchersModel from './components/model/pages/PageVouchersModel.js';
import HomePageModel from '../App/pages/view/HomePageModel.js';
import ProductViewPageModel from '../Product/pages/view/ProductViewPageModel.js';
import CategoryViewPageModel from '../Product/pages/view/CategoryViewPageModel.js';
import PageMenuModel from './components/model/pages/PageMenuModel.js';
import PageQuizModel from '../Quiz/pages/view/PageQuizModel.js';
import PageBlogModel from './components/model/pages/PageBlogModel.js';
import PostDefaultModel from './components/model/posts/PostDefaultModel.js';
// const PageTest = lazy(() => import('./components/model/pages/PageTest'));

// Pages
import HomePage from '../App/pages/view/HomePage';
import ProductViewPage from '../Product/pages/view/ProductViewPage';
import CategoryViewPage from '../Product/pages/view/CategoryViewPage';
import PageDefault from './components/model/pages/PageDefault';
import PageMenu from './components/model/pages/PageMenu';
import PageQuiz from '../Quiz/pages/view/PageQuiz';
import PageBlog from './components/model/pages/PageBlog';
import PostDefault from './components/model/posts/PostDefault';
import PageVouchers from './components/model/pages/PageVouchers';

export const CMS_PAGE_MODEL_DEFAULT = PageDefaultModel;
export const CMS_PAGE_MODELS = [
    PageDefaultModel,
    HomePageModel,
    ProductViewPageModel,
    CategoryViewPageModel,
    PageMenuModel,
    PageQuizModel,
    PageBlogModel,
    PageVouchersModel,
];

export const CMS_POST_MODEL_DEFAULT = PostDefaultModel;
export const CMS_POST_MODELS = [PostDefaultModel];

export function getAvailableCmsTypes() {
    return ['page', 'panel', 'post'];
}

export function getModels(type = null) {
    return [...CMS_PAGE_MODELS, ...CMS_PANEL_MODELS, ...CMS_POST_MODELS].filter(model => model && (type === null || type === model.type));
}

export function getModel(type, name) {
    return getModels(type).find(model => model.name === name);
}

export function getCmsViewer(cms) {
    let viewer = null;
    switch(cms.type) {
        case 'panel':
            viewer = getPanelModelViewer;
            break;

        case 'post':
            viewer = getPostModelViewer;
            break;

        case 'page':
        default:
            viewer = getPageModelViewer;
            break;
    }

    return viewer && viewer(cms.model);
}

export function getPageModelViewer(model) {
    switch(model) {
        // Add custom model page
        // case 'xyz':
        //     return <PageXyz page={this.props.page} />;
        case 'home':
            return HomePage;

        case 'product':
            return ProductViewPage;

        case 'category':
            return CategoryViewPage;

        case 'pageMenu':
            return PageMenu;

        // case 'test':
        //     return PageTest;

        case 'quiz':
            return PageQuiz;

        case 'blog':
            return PageBlog;

        case 'vouchers':
            return PageVouchers;

        case 'default':
        default:
            return PageDefault;
    }
}

export function getPostModelViewer(model) {
    switch(model) {
        case 'post':
        case 'default':
        default:
            return PostDefault;
    }
}
