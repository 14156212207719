export default {
    type: 'panel',
    name: 'shipping',
    blocks: [
        {
            name: 'delivery',
            max: 1,
            type: 'html',
        },
        {
            name: 'shipping',
            max: 1,
            type: 'html',
        },
        {
            name: 'order',
            max: 1,
            type: 'html',
        },
    ],
};
