import fetch from 'isomorphic-fetch';

import callApi from './apiCaller.js';
import { getMaxImageWidthFromScreen } from './image.js';

import config from '../config.js';
// import { uploadFile as s3UploadFile, getFile as s3GetFile, listFiles, deleteFile as s3DeleteFile } from './aws/s3.js';

export function getFile(filename, options = { autoResize: false, width: null, height: null, format: null }) {
    const { autoResize, height, width, format } = options;
    // let { width } = options;
    // if(autoResize) {
    //     width = getMaxImageWidthFromScreen();
    // }
    // if(format) {
    //     const ext = filename.split('.').pop();
    //     filename = filename.replace(`.${ext}`, `.${format}`);
    // }
    if(filename) {
        return `${config.url}api/file/content/${filename}${width || height ? '?' : ''}${width ? `width=${width}&` : ''}${height ? `height=${height}&` : ''}`;
    }
    console.error(new Error('FileMissingFilename'), filename);
    return '';
}

export function getFileUrl(filename, userId = null, method = 'get') {
    return callApi('file/geturl', 'post', { filename, userId, method }).then(result => {
        return result.url;
    });
}

export function getFileContent(filename, userId = null) {
    return getFileUrl(filename, userId).then(url => {
        if(url) {
            return fetch(url, {
                method: 'GET',
            })
            .then(response => {
                return response;
            });
        }
        return Promise.reject(new Error('NoFileError'));
    }).catch(error => {
        console.error('getFileContent', error);
    });
}

export function uploadFile(filename, data, contentType = '', userId = null) {
    return new Promise((resolve, reject) => {
        return getFileUrl(filename, userId, 'put').then(url => {
            if(url) {
                fetch(url, {
                    headers: new Headers({ 'Content-Type': contentType }),
                    method: 'PUT',
                    body: data,
                })
                .then(result => {
                    resolve(true);
                })
                .catch(err => {
                    console.error('UploadFile:::Error on sending to S3', err);
                    reject(err);
                });
            } else {
                reject(new Error('NoUploadUrl'));
            }
        }).catch(error => {
            console.error('UploadFile:::Error getting presigned url', error);
            reject(error);
        });
    });
}

export function getFiles(path) {
    return new Promise((resolve, reject) => {
        // listFiles(path).then(result => {
        //     if(result) {
        //         resolve(result.Contents.map(content => {
        //             console.log(content);
        //             return content.Key;
        //         }));
        //     } else {
        //         reject(new Error(null));
        //     }
        // }).catch(error => {
        //     console.error(error);
        //     reject(error);
        // });
    });
}

export function deleteFile(path) {
    return callApi('file/remove', 'delete', { filename: path });
}

export function deleteFolder(path) {
    return callApi('file/folder/remove', 'delete', { path });
}

export function getFileFromData(data, contentType = '') {
    const sliceSize = 512;

    const byteCharacters = atob(data);
    const byteArrays = [];

    for(let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for(let i = 0; i < slice.length; i += 1) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}
