import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { getCartItemFromProduct, setCartItem } from '../../CartActions';
import { getUserGroup } from '../../../User/UserGroupActions';
import { memoizedGetProducts } from '../../../Product/ProductActions';
import { getReferral } from '../../../App/AppActions';
import withRouter from '../../../../components/Router/WithRouter';

function CartAutoAddToCart({ dispatch, search, intl, products = [], userGroup = null, referral = '' }) {
	useEffect(() => {
        // Handle automatic add to cart: ?addtocart=product-slug:2,productID:1
        if(search.get('addtocart')) {
            search.get('addtocart').split(',').forEach(itemData => {
                const [id, qty] = itemData.split(':');
                if(id) {
                    const product = products.find(product => product._id === id || (product.slug || '').toLowerCase() === id.toLowerCase());
                    if(product) {
                        dispatch(setCartItem(getCartItemFromProduct(product, qty || 1, intl.locale, userGroup, { referral })));
                    } else {
                        console.error(itemData, id, qty);
                    }
                }
            });
        }
	}, [search]);
	return null;
}

function mapStateToProps(store, props) {
	return {
        userGroup: getUserGroup(store),
        products: memoizedGetProducts(store, { isActive: true, isVariation: false }),
        referral: getReferral(store),
	};
}

CartAutoAddToCart.propTypes = {
	dispatch: PropTypes.func.isRequired,
	search: PropTypes.object.isRequired,
	intl: PropTypes.object.isRequired,
	products: PropTypes.arrayOf(PropTypes.object),
	userGroup: PropTypes.object,
	referral: PropTypes.string,
};

export default connect(mapStateToProps)(withRouter(injectIntl(CartAutoAddToCart), ['search']));
