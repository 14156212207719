import React, { Component, Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-bootstrap';

import FileLoader from './FileLoader.js';

function Slider({ slides = [], label = '', interval = 5000, disableControls, disableIndicators }) {
	const [activeSlider, setActiveSlider] = useState(0);
	const [isAnimating, setIsAnimating] = useState(false);

	slides = (slides || []).filter(slide => slide);

    const onExiting = () => {
        setIsAnimating(true);
    };

    const onExited = () => {
		setIsAnimating(false);
    };

    const next = () => {
        if(isAnimating) {
            return;
        }
        const nextIndex = activeSlider === slides.length - 1 ? 0 : activeSlider + 1;
		setActiveSlider(nextIndex);
    };

    const previous = () => {
		if(isAnimating) {
			return;
        }
        const nextIndex = activeSlider === 0 ? slides.length - 1 : activeSlider - 1;
		setActiveSlider(nextIndex);
    };

    const goToIndex = newIndex => {
		if(isAnimating) {
			return;
        }
		setActiveSlider(newIndex);
    };

    const renderSlides = () => {
		return slides.map((slide, index) => {
			const slideProps = {
				alt: `${label || ''} - ${index + 1}`,
				style: { maxWidth: '100%', maxHeight: '400px' },
			};
			let { content } = slide;
			if(!content) {
				if(slide.path) {
					content = <FileLoader filename={slide.path} {...slideProps} />;
				} else if(slide.url) {
					content = <img src={slide.url || slide} {...slideProps} />;
				}
			}
			if(content) {
				return (
					<Carousel.Item key={index}>
						{content}
						{slide.caption ? <Carousel.Caption>{slide.caption}</Carousel.Caption> : ''}
					</Carousel.Item>
				);
			}
			return null;
		}).filter(slide => slide);
    };

	if(slides && slides.length) {
		const slidesContent = renderSlides();
		if(slidesContent) { // Check if content, if not e.type undefined error
			return (
				<div>
					{/* {slidesContent} */}
					<Carousel
						keyboard
						interval={interval || 5000}
						pause="hover"
						indicators={!disableIndicators}
						controls={!disableControls}
						onSlide={onExiting}
						onSlid={onExited}
					>
						{slidesContent}
					</Carousel>
				</div>
			);
}
	}
	return null;
}
// {this.getSlides().length > 1 && !this.props.disableIndicators ? <CarouselIndicators items={this.getSlides().map((slide, index) => { return { src: index }; })} activeIndex={activeSlider} onClickHandler={this.goToIndex} /> : <CarouselIndicators items={[]} />}
// {this.getSlides().length > 1 && !this.props.disableControls ? <CarouselControl key="prev" direction="prev" directionText="Previous" onClickHandler={this.previous} /> : ''}
// {this.getSlides().length > 1 && !this.props.disableControls ? <CarouselControl key="next" direction="next" directionText="Next" onClickHandler={this.next} /> : ''}

// Slider.defaultProps = {
//     slides: [],
//     label: null,
//     interval: 0,
//     disableControls: false,
//     disableIndicators: false,
// };

Slider.propTypes = {
    slides: PropTypes.array, // slide url or object
    label: PropTypes.string,
    interval: PropTypes.number,
    disableControls: PropTypes.bool,
    disableIndicators: PropTypes.bool,
};

export default Slider;
