import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

import config from '../../../../config.js';
import { getFile } from '../../../../util/file.js';

import { getContentBlockItemValue, getPosts, memoizedGetPosts } from '../../../Cms/CmsActions.js';

import withRouter from '../../../../components/Router/WithRouter.js';
import HtmlComponent from '../../../../components/Text/HtmlComponent.js';
import CmsPanel from '../../../Cms/components/content/CmsPanel.js';
import GenericSnippet from '../../components/view/GenericSnippet.js';
import PreFooter from '../../components/PreFooter.js';
import PackList from '../../../Product/components/pack/PackList.js';
import ReviewSummary from '../../../Review/components/view/ReviewSummary.js';
import PostsSlider from '../../../Cms/components/view/PostsSlider.js';
import AppAdvantage from '../../components/view/AppAdvantage.js';
import ProductHiglightList from '../../../Product/components/list/ProductHiglightList.js';
import PageCover from '../../../Cms/components/view/PageCover.js';
import HomePosts from '../../../Cms/components/view/HomePosts.js';

function HomePage({ page }) {
    const { blocks } = page || {};
    const content = getContentBlockItemValue(blocks, 'content');
    const subcontent = getContentBlockItemValue(blocks, 'subcontent');

    // const handleGoToProducts = () => navigate('/products');
    return (
        <div>
            <GenericSnippet />

			<PageCover page={page} defaultCover={`${config.url}assets/images/home/home-bubbles.png`} />

            <Container>
                <Card className="mt-0 mt-xl-n10 rounded-lg">
                    <Card.Body>
                        <Row className="g-0 text-center">
                            <Col xs="12" md="6" lg="8">
                                <div className="mb-1 mt-0 mt-md-3">
                                    <span className="h2 d-inline-block border-half-secondary text-center"><FormattedMessage id="appIntroText2" defaultMessage="Simple, good and fast" /></span>
                                </div>
                            </Col>
                            <Col>
                                <ReviewSummary hideReviews hideLogo hideTitle />
                            </Col>
                        </Row>

                        <AppAdvantage page={page} />
                    </Card.Body>
                </Card>
            </Container>

            <div className="critical-css-limiter">
                <CmsPanel slug="categories" isTemplate />
            </div>

            <Container className="py-md-4">
                <div className="my-3 text-center">
                    <HtmlComponent text={content} />
                </div>
            </Container>

            <ReviewSummary hideLogo hideSummary />

            <hr />

            <Container className="py-md-4">
                <div className="my-3 text-center">
                    <span className="h3 d-inline-block border-half-secondary text-center"><FormattedMessage id="productHighlightTitle" defaultMessage="Our favorites" /></span>
                </div>
                <ProductHiglightList />
            </Container>
                {/* <Button variant="outline-secondary" size="lg" onClick={handleGoToProducts} className="mt-n2 mb-4 w-100">👉 <FormattedMessage id="productsViewAll" defaultMessage="See all our products" /> 👉</Button> */}

            {subcontent && (
                <Container className="py-md-4">
                    <div className="my-3 text-center">
                        <HtmlComponent text={subcontent} />
                    </div>
                </Container>
            )}

            <div className="py-md-4 bg-info bg-texture">
                <div className="mb-5">
                    <PackList count={4} onlyHighlight />
                </div>
            </div>

			<HomePosts />

            <PreFooter />
        </div>
    );
}

HomePage.propTypes = {
    // location: PropTypes.object,
    page: PropTypes.object.isRequired,
};

export default HomePage;
