const ProductViewPageModel = {
    type: 'page',
    name: 'product',
    blocks: [
        {
            name: 'breadcrumbs',
            type: 'link',
        },
        {
            name: 'product', // name or id; MUST be unique
            max: 1, // max same items allowed
            type: 'product', // || text || link || image || mixed (with children)
        },
        {
            name: 'title', // name or id; MUST be unique
            max: 1, // max same items allowed
            type: 'text', // || text || link || image || mixed (with children)
        },
        {
            name: 'description',
            max: 1,
            type: 'html',
        },
        {
            name: 'composition',
            max: 1,
            type: 'html',
        },
        {
            name: 'shipping-info-sup',
            max: 1,
            type: 'html',
        },
        {
            name: 'voucher-info',
            max: 1,
            type: 'panel',
        },
        {
            name: 'recommandation',
            max: 1,
            type: 'panel',
        },
        {
            name: 'associated-products',
            type: 'product',
        },
    ],
};

export default ProductViewPageModel;
