import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { AFFILIATION_QUERY_PARAM, setCurrentAffiliation } from '../../AffiliationActions';

import withRouter from '../../../../components/Router/WithRouter';

function AffiliationInit({ dispatch, search }) {
	useEffect(() => {
        if(search.get(AFFILIATION_QUERY_PARAM)) {
            dispatch(setCurrentAffiliation(search.get(AFFILIATION_QUERY_PARAM)));
        }
	}, []);

	return null;
}

AffiliationInit.propTypes = {
	dispatch: PropTypes.func.isRequired,
	search: PropTypes.object.isRequired,
};

export default connect()(withRouter(AffiliationInit, ['search']));
