import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ButtonGroup, Button } from 'react-bootstrap';

import { loginRequest, logoutUser, setAdminRequest, getLoggedUser } from '../../UserActions.js';
import { getUserGroupsRequest, getUserGroups, getUserGroup, setUserGroup } from '../../UserGroupActions.js';

class UserAdminLoginSwitcher extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
        };
    }

    componentDidMount() {
        (this.props.user || {}).role === 'admin' && this.props.dispatch(getUserGroupsRequest());
    }

    triggerLogin = userRole => {
        let email = '';
        switch(userRole) {
            case 'admin':
                email = 'clement@offaxis.io';
                break;

            default:
                email = 'test@offaxis.io';
                break;
        }
        this.setState({
            email,
            password: '123456',
        }, () => {
            this.props.dispatch(logoutUser(true));
            setTimeout(() => {
                this.props.dispatch(loginRequest(this.state.email.toLowerCase(), this.state.password));
            }, 500);
        });
    };

    render() {
        if(process.env.NODE_ENV !== 'production') {
            return (
                <div>
                    <ButtonGroup size="sm" className="w-100 mt-5">
                        <Button variant="secondary" onClick={event => this.triggerLogin('customer')}>Customer</Button>
                        <Button variant="danger" onClick={event => this.triggerLogin('admin')}>Admin</Button>
                        <Button onClick={event => this.props.dispatch(setAdminRequest())}>Create admin</Button>
                    </ButtonGroup>
                    <ButtonGroup size="sm" className="w-100">
                        {this.props.userGroups.map(group => <Button key={group.identifier} onClick={event => this.props.dispatch(setUserGroup(group))}>{group.identifier}</Button>)}
                    </ButtonGroup>
                </div>
            );
        }
        return null;
    }
}

function mapStateToProps(store, props) {
    return {
        userGroups: getUserGroups(store),
        userGroup: getUserGroup(store),
        user: getLoggedUser(store),
    };
}

UserAdminLoginSwitcher.defaultProps = {
    user: null,
};

UserAdminLoginSwitcher.propTypes = {
    dispatch: PropTypes.func.isRequired,
    userGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
    userGroup: PropTypes.object.isRequired,
    user: PropTypes.object,
};

export default connect(mapStateToProps)(UserAdminLoginSwitcher);
