import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import config from '../../../../config';

import { getContentBlockItemValue } from '../../../Cms/CmsActions';
import { getLoggedUser } from '../../../User/UserActions';
import { getUserGroup, USER_GROUP_DEFAULT } from '../../../User/UserGroupActions';

import HtmlComponent from '../../../../components/Text/HtmlComponent';

export function AppAdvantage({ page, userGroup }) {
	const { blocks } = page || {};
    const delivery = getContentBlockItemValue(blocks, 'delivery');
    const shipping = getContentBlockItemValue(blocks, 'shipping');
    const order = getContentBlockItemValue(blocks, 'order');

	if(!userGroup || userGroup.identifier === USER_GROUP_DEFAULT || process.env.NODE_ENV === 'development') {
		return (
			<div className="mt-2 px-lg-4">
				<Row className="g-0">
					<Col xs="12" lg="4">
						<Row className="g-0 mb-3 pe-4">
							<Col xs="3">
								<span className="mb-2 icon-box icon-box-warning">
									<img src={`${config.url}assets/images/home/delivery-man-icon.png`} alt="Delivery" width="75" height="75" className="mw-100 mh-100 d-inline-block" loading="lazy" />
								</span>
							</Col>
							<Col className="d-inline-block ms-3 text-end text-md-start" style={{ fontSize: '0.8rem' }}>
								<HtmlComponent text={shipping} />
							</Col>
						</Row>
					</Col>
					<Col xs="12" lg="4">
						<Row className="g-0 mb-3 pe-4">
							<Col xs="3">
								<span className="mb-2 icon-box icon-box-successlight">
									<img src={`${config.url}assets/images/home/delivery-truck.png`} alt="Shipping" width="75" height="75" className="mw-100 mh-100 d-inline-block" loading="lazy" />
								</span>
							</Col>
							<Col className="d-inline-block ms-3 text-end text-md-start" style={{ fontSize: '0.8rem' }}>
								<HtmlComponent text={delivery} />
							</Col>
						</Row>
					</Col>
					<Col xs="12" lg="4">
						<Row className="g-0 mb-3 pe-4">
							<Col xs="3">
								<span className="mb-2 icon-box icon-box-danger">
									<img src={`${config.url}assets/images/home/box-love-icon.png`} alt="Review" width="75" height="75" className="mw-100 mh-100 d-inline-block" loading="lazy" />
								</span>
							</Col>
							<Col className="d-inline-block ms-3 text-end text-md-start" style={{ fontSize: '0.8rem' }}>
								<HtmlComponent text={order} />
							</Col>
						</Row>
					</Col>
				</Row>
			</div>
		);
	}
}

AppAdvantage.propTypes = {
	page: PropTypes.object.isRequired,
	userGroup: PropTypes.object.isRequired,
};

const mapStateToProps = (store, props) => ({
	userGroup: getUserGroup(store),
});

export default connect(mapStateToProps)(AppAdvantage);
