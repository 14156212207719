export default {
    type: 'panel',
    name: 'mainMenu',
    blocks: [{
        name: 'links',
        max: 4,
        type: 'mixed',
        blocks: [
            {
                name: 'mainLevel',
                type: 'link',
                max: 1,
            },
            {
                name: 'subLevel',
                type: 'link',
            },
        ],
    }],
};
