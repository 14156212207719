import React, { Fragment, startTransition, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';

import { capitalizeFirstLetter } from '../../../../util/text';

import { USER_GROUP_DEFAULT, getIsUserGroupFromParent, getUserGroup, resetUserGroupRequest } from '../../UserGroupActions';
import CmsPanel from '../../../Cms/components/content/CmsPanel';
import FormattedMessageConditionnal from '../../../Intl/components/Translate/FormattedMessageConditionnal';
import { getLoggedUser } from '../../UserActions';
import Icon from '../../../../components/Content/Icon';

function UserGroupHeaderInfo({ dispatch, user, userGroup, isUserGroupFromParent = false }) {
	// console.log('UserGroupHeaderInfo::: render', userGroup);
    const handleResetUserGroup = () => dispatch(resetUserGroupRequest());

	return userGroup && (
		userGroup.identifier === USER_GROUP_DEFAULT
		? (
			<span className="d-none d-lg-inline-block me-3">
				<CmsPanel slug="header-top" isTemplate />
			</span>
		) : (
			<span className="d-inline-block">
				<FormattedMessageConditionnal
					id={`userGroupAccount${capitalizeFirstLetter((userGroup.identifier || '').replace('-', ''))}`}
					defaultMessage={`<strong>${capitalizeFirstLetter(userGroup.identifier)}</strong> account`}
					values={{
						strong: msg => <strong className="text-danger">{msg}</strong>,
					}}
				/>
				{(!user && !isUserGroupFromParent) && <Button variant="link" size="sm" className="p-0 text-danger" onClick={handleResetUserGroup}><Icon icon="close-o" /></Button>}
			</span>
		)
	);
}

function mapStateToProps(store, props) {
	return {
		user: getLoggedUser(store),
		userGroup: getUserGroup(store),
		isUserGroupFromParent: getIsUserGroupFromParent(store),
	};
}

UserGroupHeaderInfo.propTypes = {
	dispatch: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	userGroup: PropTypes.object.isRequired,
	isUserGroupFromParent: PropTypes.bool,
	isOpen: PropTypes.bool,
};

export default connect(mapStateToProps)(UserGroupHeaderInfo);
