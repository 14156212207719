import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Container, Row, Col, Card } from 'react-bootstrap';

import { getContentBlockItemsByName, getContentBlockItemValue, getContentBlockMixedItems } from '../../../Cms/CmsActions.js';
import { getUserGroup, USER_GROUP_DEFAULT } from '../../../User/UserGroupActions.js';

import LazyLoader from '../../../../components/Content/LazyLoader.js';
import AppLogo from '../../../App/components/view/AppLogo.js';
import HtmlComponent from '../../../../components/Text/HtmlComponent.js';
import CmsPanel from '../../../Cms/components/content/CmsPanel.js';

// configReveal({ ssrFadeout: true });

function PanelCategories({ navigate, panel, userGroup = null }) {
    const { blocks } = panel || {};
    const panels = getContentBlockItemsByName(blocks, 'panels');
    const content = getContentBlockItemValue(blocks, 'content');

    if(panels && panels.length > 0) {
        return (
            <div className="bg-white">
                <Container>
                    <div className="mb-3 text-center">
                        <span className="h2 d-inline-block border-half-danger text-center"><FormattedMessage id="appIntroText3" defaultMessage="Our selection" /></span>
                    </div>
                    <Row>
                        {panels.map((panel, index) => (
							<Col key={index} xs="12" md="6">
								<CmsPanel slug={panel.text} isTemplate />
							</Col>
						))}
                        {panels.length % 2 !== 0 && (
                            <Col className={classnames(!content && ['d-none', 'd-md-block'])}>
                                {
                                    content && ((userGroup || {}).identifier === USER_GROUP_DEFAULT)
                                    ? (
                                        <Card body className="shadow text-center">
                                            <HtmlComponent text={content} />
                                        </Card>
                                    ) : (
										<AppLogo suffix="D-fade" className="mw-100 card-image" loading="lazy" />
                                    )
                                }
                            </Col>
                        )}
                    </Row>
                </Container>
            </div>
        );
    }
    return null;
}

function mapStateToProps(store, props) {
    return {
        userGroup: getUserGroup(store),
    };
}

PanelCategories.propTypes = {
    navigate: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    panel: PropTypes.object.isRequired,
    userGroup: PropTypes.object,
};

export default connect(mapStateToProps)(PanelCategories);
