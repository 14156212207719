import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Row, Col, Card } from 'react-bootstrap';

import { shuffle } from '../../../../util/array.js';
import { getFile } from '../../../../util/file.js';
import { stripTags, truncate } from '../../../../util/text.js';

import { getContentBlockItemValue, getContentBlockItemsByName, getContentBlockMixedItem, getContentBlockItemByName } from '../../CmsActions.js';
import { getLanguagePath } from '../../../Intl/IntlHelper.js';

import Slider from '../../../../components/Content/Slider.js';
import HtmlComponent from '../../../../components/Text/HtmlComponent.js';
import Icon from '../../../../components/Content/Icon.js';

function PostsSlider({ posts = [], isRandom = false, appIsMobile = false, mode = 'full', excerptSize = 80 }) {
    const countPerSlide = appIsMobile ? 1 : 2;
    const groups = [];
    const items = posts || [];
    const itemsTemp = [...(isRandom ? shuffle(items) : items)];
    while(itemsTemp.length > 0) {
        groups.push(itemsTemp.splice(0, countPerSlide));
    }

    const colSize = 12 / countPerSlide;

    const slides = groups.map((items, index) => {
        return (
            <Row key={index} className={classNames(countPerSlide === 1 && 'g-0')}>
                {items.map((post, index) => {
					const contents = getContentBlockItemsByName(post.blocks, 'content');
                    const image = (getContentBlockItemByName(post.blocks, 'image') || {}).path ? getContentBlockItemByName(post.blocks, 'image') : getContentBlockMixedItem((contents.find(content => (getContentBlockMixedItem(content, 'image') || {}).path)), 'image');

					const renderTitle = () => <h3 className="h4"><a href={getLanguagePath(post.slug, post.language)} className="d-block mt-2">{post.title}</a></h3>;
					const renderImage = () => image && (
						<div className="w-100 overflow-hidden rounded" style={{ boxShadow: '10px 10px 0px var(--bs-info)' }}>
							<img src={getFile(image.path)} alt={image.text || post.title} loading="lazy" className="w-100" />
						</div>
					);
					const renderExcerpt = () => <HtmlComponent text={`${getContentBlockItemValue(post.blocks, 'excerpt') || truncate(stripTags((getContentBlockMixedItem(contents[0], 'text') || {}).text), excerptSize)}...`} />;

					const renderPostContent = () => {
						if(mode === 'mini') {
							return (
								<Row>
									{image && (
										<Col xs="12" md="3">
											{renderImage()}
										</Col>
									)}
									<Col>
										{renderTitle()}
									</Col>
								</Row>
							);
						}
						return (
							<Fragment>
								{renderTitle()}
								<Row>
									{image && (
										<Col xs="12" md="4">
											{renderImage()}
										</Col>
									)}
									<Col className="mt-3 mt-md-0">
										{renderExcerpt()}
										<a href={getLanguagePath(post.slug, post.language)} className="d-block mt-2 text-end"><FormattedMessage id="readMore" defaultMessage="Read more" /> <Icon icon="chevron-right" /></a>
									</Col>
								</Row>
							</Fragment>
						);
					};
					return (
                        <Col key={post._id} xs="12" md={colSize}>
                            <Card className={classNames('shadow border', countPerSlide > 1 && 'm-3')}>
                                <Card.Body>
                                    {renderPostContent()}
                                </Card.Body>
                            </Card>
                        </Col>
                    );
                })}
            </Row>
        );
    });

    if(!posts.length) {
        return null;
    }
    return (
        <Slider
            slides={slides.map(content => ({ content }))}
            disableLightbox
            disableControls={slides.length === 1}
            disableIndicators={slides.length === 1}
            interval={process.env.NODE_ENV === 'development' ? 99999999999 : 6000}
        />
    );
}

function mapStateToProps(store, props) {
    return {
        locale: store.intl.locale,
		appIsMobile: store.app.isMobile,
    };
}

PostsSlider.propTypes = {
    locale: PropTypes.string.isRequired,
    posts: PropTypes.arrayOf(PropTypes.object),
    isRandom: PropTypes.bool,
    appIsMobile: PropTypes.bool,
    mode: PropTypes.string,
	excerptSize: PropTypes.number,
};

export default connect(mapStateToProps)(PostsSlider);
