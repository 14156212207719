import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import FileLoader from '../../../../components/Content/FileLoader.js';
import HtmlComponent from '../../../../components/Text/HtmlComponent.js';
import withRouter from '../../../../components/Router/WithRouter.js';
import PredictiveLink from '../view/PredictiveLink.js';

function CmsBlockItem({ navigate, item = '', type = null, ...props }) {
    const handleGoTo = event => { console.log(event.target); navigate(event.target.getAttribute('data-path')); };

    if(item) {
        switch(type) {
            case 'link': {
                if(item.path) {
                    if(item.needObfuscate) {
                        return <Button variant="link" as="span" {...props} data-path={item.path} onClick={handleGoTo}>{item.text}</Button>;
                    }
                    return item.path.indexOf('http') === 0
                    ? <a href={item.path} target="_blank" {...props}>{item.text}</a>
                    : <PredictiveLink to={item.path} {...props}>{item.text}</PredictiveLink>;
                }
                break;
            }

            case 'image':
                return <FileLoader key={item.sort} filename={item.path} useAppUrl autoPlay loop muted hideplaceholders alt={item.text} role="presentation" {...props} />;

            case 'html':
                return <HtmlComponent key={item.sort} text={item.text} {...props} />;
            case 'text':
            default:
                return <span key={item.sort} {...props}>{item.text}</span>;
        }
    }
    return null;
}

CmsBlockItem.propTypes = {
    navigate: PropTypes.func.isRequired,
    item: PropTypes.object,
    type: PropTypes.string,
};

export default withRouter(CmsBlockItem);
