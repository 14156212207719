import { Parser } from 'expr-eval';

import { getPriceCent, getPriceFromCent } from '../../util/price.js';

import { getTotalWeight, getSubtotal } from '../Cart/CartActions.js';

import colissimoFr from '../../../cms/shipping/colissimo-fr.js';
import mondialrelayFr from '../../../cms/shipping/mondialrelay-fr.js';
import mondialrelayBe from '../../../cms/shipping/mondialrelay-be.js';

export function getShippingFee(address, method, items = [], user = null) {
    // console.log('calculateShippingFee', address, method);
    if(method && method.rates && address && address.countryCode) {
        let shippingFee = 0;

        const { calculation = {}, rates = [] } = method;

        let stepValue = 0;

        // Handle free shipping
        if(calculation.freeShippingStep && calculation.freeShippingTreshold) {
            // console.log('Shipping::: Free ?');
            stepValue = calculation.freeShippingStep === 'weight' ? getTotalWeight(items) : getSubtotal(items);
            // console.log(`${stepValue} >= ${calculation.freeShippingTreshold} = ${stepValue >= calculation.freeShippingTreshold}`);
            if(stepValue >= calculation.freeShippingTreshold) {
                return 0;
            }
        }

        // get step value (weight or subtotal)
        const stepName = calculation.step;

        // console.log('Parcel items', items);
        let parcelShippingFee = 0;
        stepValue = stepName === 'weight' ? getTotalWeight(items) : getSubtotal(items);
        // console.log('Shipping method step', stepName, stepValue, items);

        // calculate shipping fee depending on rates and method config
        if(calculation.mode === 'fixed') {
            parcelShippingFee += calculateShippingFeeFromStep(method, stepValue);
        } else if(calculation.mode === 'loop') {
            let stepValueTemp = stepValue;
            const shippingSteps = rates.map(rate => rate[stepName]);
            const maxShippingStep = shippingSteps[shippingSteps.length - 1];
            let calculatedStepValue = 0;

            while(stepValueTemp > 0) {
                calculatedStepValue = stepValueTemp > maxShippingStep ? maxShippingStep : stepValueTemp;
                parcelShippingFee += calculateShippingFeeFromStep(method, calculatedStepValue);
                stepValueTemp -= calculatedStepValue;
            }
        }

        const parcelExtraFee = calculateShippingExtraFees(method, {
            shippingFee: parcelShippingFee,
            weight: getTotalWeight(items),
            subtotal: getSubtotal(items),
        });

        shippingFee = getPriceFromCent(getPriceCent(shippingFee) + getPriceCent(parcelShippingFee) + getPriceCent(parcelExtraFee));
        // console.log('Parcel Shipping Fee', `Base: ${parcelShippingFee} //  Extra fee: ${parcelExtraFee} // Total parcel: ${getPriceCent(parcelShippingFee) + getPriceCent(parcelExtraFee)} // TOTAL: ${shippingFee}`);

        if(!method.isPriceTTC) {
            // console.log('Setting TTC price');
            shippingFee = getPriceFromCent(getPriceCent(shippingFee) * (1 + parseFloat(method.taxRate)));
        }

        // console.log('Shipping Fee Total', method.identifier, shippingFee);
        return shippingFee;
    }
    // process.env.NODE_ENV === 'development' && console.error('MissingShippingMethodData', method, address);
    return null;
}

export function calculateShippingFeeFromStep(method, stepValue) {
    // console.log('calculateShippingFeeFromStep', method.step, method.rates, stepValue, method.limit, method.tolerance);
    const { step, limit, tolerance } = method.calculation;
    if(method && method.rates && method.rates.length && stepValue) {
        const { rates } = method;
        stepValue = parseFloat(stepValue);
        const rateFound = rates.find((rate, index) => {
            const prevStepRate = rates[index - 1] ? parseFloat(rates[index - 1][step]) : null;
            const currentStepRate = parseFloat(rate[step]);
            const nextStepRate = rates[index + 1] ? parseFloat(rates[index + 1][step]) : null;
            if(limit === 'min') {
                if(tolerance === 'exclusive') {
                    return stepValue > currentStepRate && (nextStepRate === null || stepValue <= nextStepRate);
                }
                // inclusive
                return stepValue >= currentStepRate && (nextStepRate === null || stepValue < nextStepRate);
            }
            // max
            if(tolerance === 'exclusive') {
                return stepValue < currentStepRate && (prevStepRate === null || stepValue >= prevStepRate);
            }
            // inclusive
            return stepValue <= currentStepRate && (prevStepRate === null || stepValue > prevStepRate);
        });
        // console.log('calculateShippingFeeFromStep result', rateFound, rateFound ? parseFloat(rateFound.value) : 0);
        return rateFound ? parseFloat(rateFound.value) : 0;
    }
    return 0;
}

/*
    Variables allowed: shippingFee, weight, subtotal
    ex: "ceil(subtotal/150)*0.95"
*/
export function calculateShippingExtraFees(method, variables) {
    if(method.extraFees && method.extraFees.length) {
        return method.extraFees.reduce((total, extraFee) => {
            // console.log('calculateShippingExtraFees', extraFee.calculation, variables, Parser.evaluate(extraFee.calculation, variables));
            return total + Parser.evaluate(extraFee.calculation, variables);
        }, 0);
    }
    return 0;
}

export function getRealShippingFee(order) {
    const orderWeight = getTotalWeight(order.items) + 0.2; // + package weight
    let rules = [];
    const shipping = order.shipping || {};
    const { method, address } = shipping;
    if(method && address) {
        if(method.provider === 'colissimo') {
            if(address.countryCode === 'FR') {
                rules = colissimoFr;
            }
        } else if(method.provider === 'mondialrelay') {
            if(address.countryCode === 'FR') {
                rules = mondialrelayFr;
            } else if(address.countryCode === 'BE') {
                rules = mondialrelayBe;
            }
        } else if(method.provider === 'stef') {
            if(address.countryCode === 'FR') {
                rules = colissimoFr;
                // rules = stefFr;
            }
        }
    }
    const fee = (rules.find((association, index) => {
        if(rules[index + 1] && rules[index + 1][0] > orderWeight) {
            return association[1];
        }
        return null;
    }) || [])[1] || 0;

    // Add VAT
    return fee * 1.2;
}
