// Import Actions
import { SET_VOUCHERS } from './VoucherActions.js';
import { LOGOUT_USER } from '../User/UserActions.js';

// Initial State
const initialState = {
    data: [],
};

const VoucherReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_VOUCHERS:
            return {
                ...state,
                data: action.vouchers,
            };

        case LOGOUT_USER:
            return initialState;

        default:
            return state;
    }
};

export default VoucherReducer;
