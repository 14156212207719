import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { ButtonGroup, Button } from 'react-bootstrap';

import ReactGA4 from 'react-ga4';

import config from '../../../../config.js';

import { getCartItemFromProduct, getCartItemByProduct, setCartItem, formatAnalyticsItems, getConditionValue, isConditionPercentValue } from '../../../Cart/CartActions.js';
import { getProductPrice } from '../../ProductActions.js';
import { getUserGroup, getUserGroupCondition } from '../../../User/UserGroupActions.js';
import { addUserInterestsRequest, getCurrentUserData, getUserOption } from '../../../User/UserActions.js';
import { getCurrentCurrency, defaultCurrency } from '../../../Currency/CurrencyActions.js';
import { getLanguagePath } from '../../../Intl/IntlHelper.js';

import Icon from '../../../../components/Content/Icon.js';
import { getReferral } from '../../../App/AppActions.js';

function ProductAddToCart({ dispatch, intl, currency = defaultCurrency, userData = {}, product, item = null, userGroup = null, referral = null, onAdded = null, color = '', ...props }) {
    const [isMounted, setIsMounted] = useState(false);

	useEffect(() => {
		setIsMounted(true);
	}, []);

    const productPrice = getProductPrice(product, 1, userGroup, !getUserOption(userData, 'exemptVAT'), referral);

    let quantityStep = Math.max(product.stepQuantity || 1, 1);
	const condition = getUserGroupCondition(userGroup, 'packFill');
	if(condition) {
		const conditionValue = getConditionValue(condition);
		if(isConditionPercentValue(condition)) {
			quantityStep = Math.floor((product.packing * conditionValue) / 100);
		} else {
			quantityStep = conditionValue;
		}
	}

	const newItem = getCartItemFromProduct(
		product,
		(item ? item.quantity : 0) + Math.max(product.minimumQuantity || 1, quantityStep),
		intl.locale,
		userGroup,
		{ referral },
	);

    const handleTrackAddToCart = () => {
		// console.log('handleTrackAddToCart');
        if(typeof window !== 'undefined' && config.google.analytics.trackingCodeGA4) {
            // Ga: New
            ReactGA4.event('add_to_cart', {
                currency,
                value: productPrice,
                items: formatAnalyticsItems([{ ...product, priceTTC: productPrice, quantity: 1 }]),
            });
        }

        if(typeof window !== 'undefined' && window.fbq) {
            window.fbq('track', 'AddToCart', {
                content_name: product.name,
                content_category: product.brand,
                content_ids: [product._id],
                content_type: 'product',
                value: productPrice,
                currency: 'EUR',
                quantity: quantityStep,
            });
        }

		// TODO: Set user interests without blocking render
        // dispatch(addUserInterestsRequest(userData, product.categories));
    };

    const handleAddToCart = () => {
		if(product) {
			dispatch(setCartItem(newItem));
			handleTrackAddToCart();
		}
	};

    if(product) {
		const isAdded = isMounted && item?.quantity > 0;
        return (
            <ButtonGroup vertical className="w-100 add-to-cart-wrapper">
                <Button variant={isAdded ? 'outline-success' : color} onClick={handleAddToCart} {...props}>
                    {
                        isAdded
                        ? <span><Icon icon="check-o" size="lg" /> <FormattedMessage id="cartAddedTo" defaultMessage="Added to cart" /></span>
                        : <span><Icon icon="shopping-cart" size="lg" /> <FormattedMessage id="cartAddTo" defaultMessage="Add to cart" /></span>
                    }
                </Button>
                {isAdded && (
					<Button variant="outline-light" as={Link} to={getLanguagePath('/cart', intl.locale)} className="w-100 bg-transparent border-0 text-success">
						<span className="text-black-75"><Icon icon="shopping-cart" /> <FormattedMessage id="cartItemQuantity" defaultMessage="{count} item(s) in my cart" values={{ count: item.quantity }} /></span>
					</Button>
				)}
            </ButtonGroup>
        );
    }
    return null;
}
// <Button color="secondary" size="sm" outline block><Icon icon="eye" /></Button>

function mapStateToProps(store, props) {
    return {
        currency: getCurrentCurrency(store),
        userData: getCurrentUserData(store),
        userGroup: getUserGroup(store),
        item: getCartItemByProduct(store, props.product),
        referral: getReferral(store),
    };
}

ProductAddToCart.propTypes = {
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    currency: PropTypes.string,
    userGroup: PropTypes.object,
    userData: PropTypes.object,
    product: PropTypes.object.isRequired,
    item: PropTypes.object,
    onAdded: PropTypes.func,
    color: PropTypes.string,
    referral: PropTypes.string,
    referral: PropTypes.bool,
};

export default connect(mapStateToProps)(injectIntl(ProductAddToCart));
