export default {
    type: 'panel',
    name: 'footerLinks',
    blocks: [
        {
            name: 'links',
            type: 'link',
        },
    ],
};
