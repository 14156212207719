const HomePageModel = {
    type: 'page',
    name: 'home',
    slug: '*',
    blocks: [
        {
            name: 'cover',
            type: 'image',
            max: 1,
        },
        {
            name: 'title',
            type: 'text',
            max: 1,
        },
        {
            name: 'shipping',
            type: 'html',
            max: 1,
        },
        {
            name: 'delivery',
            type: 'html',
            max: 1,
        },
        {
            name: 'order',
            type: 'html',
            max: 1,
        },
        {
            name: 'content',
            type: 'html',
            max: 1,
        },
        {
            name: 'subcontent',
            type: 'html',
            max: 1,
        },
    ],
};

export default HomePageModel;
