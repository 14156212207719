import callApi from '../../util/apiCaller.js';
import { capitalizeFirstLetter } from '../../util/text.js';

export const SET_TASKS = 'SET_TASKS';

export function getTasksRequest(targetType = null, targetId = null) {
    return dispatch => callApi(`tasks${targetType ? `/${targetType}` : ''}${targetId ? `/${targetId}` : ''}`).then(res => {
        res && res.tasks && dispatch(setTasks(res.tasks, targetType && targetId));
        return res && res.tasks;
    }).catch(error => {
        return null;
    });
}

export function editTaskRequest(task) {
    return dispatch => callApi('task/edit', 'post', { task }).then(res => {
        res && res.task && dispatch(getTasksRequest(res.task.targetType, getTaskTargetId(res.task)));
        return res && res.task;
    }).catch(error => {
        return null;
    });
}

export function removeTaskRequest(task) {
    return dispatch => callApi('task/remove', 'delete', { task }).then(res => {
        res && res.ok && dispatch(getTasksRequest(task.targetType, getTaskTargetId(task)));
        return res && res.ok;
    }).catch(error => {
        return null;
    });
}

export function getTasks(store, targetType = null, targetId = null) {
    const tasks = store.tasks.data;
    if(targetType && targetId) {
        return tasks.filter(task => task.targetType === capitalizeFirstLetter(targetType) && getTaskTargetId(task) === targetId);
    }
    return tasks;
}

export function getTaskTargetId(task) {
    return task.target && task.target._id ? task.target._id : task.target;
}

export function getTaskColor(task) {
    let color = 'teal';
    switch(task.priority) {
        case 5:
            color = 'pink';
            break;

        case 4:
            color = 'red';
            break;

        case 3:
            color = 'purple';
            break;

        case 2:
            color = 'blue';
            break;

        case 1:
            color = 'green';
            break;

        default:
    }
    return color;
}

export function setTasks(tasks, target = null) {
    return {
        type: SET_TASKS,
        tasks,
        target,
    };
}
