import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { getProductPrice, getProductSpecialPrices } from '../../ProductActions';
import { getUserGroup, getUserGroupOption } from '../../../User/UserGroupActions';
import { getCartItemByProduct } from '../../../Cart/CartActions';
import { getReferral } from '../../../App/AppActions';

import Price from '../../../Currency/components/view/Price';
import Icon from '../../../../components/Content/Icon';

function ProductSpecialPriceQuantityInfo({ product, item, userGroup = null, referral = null }) {
	const displayPriceHT = getUserGroupOption(userGroup, 'displayPriceHT');
	const productNormalPrice = getProductPrice(product, 1, userGroup, !displayPriceHT, referral);
	const specialPricseByQuantity = getProductSpecialPrices(product, displayPriceHT ? 'priceHT' : 'priceTTC', null, userGroup, referral).filter(specialPrice => specialPrice.quantity).reverse();
	return specialPricseByQuantity.length > 0 && (
		<div className="text-success">
			{specialPricseByQuantity
				.filter(specialPrice => specialPrice.quantity > (item?.quantity || 1))
				.map(specialPrice => ({ ...specialPrice, unitSpecialPrice: getProductPrice(product, specialPrice.quantity, userGroup, !displayPriceHT, referral) / specialPrice.quantity }))
				.filter(specialPrice => specialPrice.value < productNormalPrice)
				.map(specialPrice => (
				<OverlayTrigger
					overlay={
						<Tooltip id={`${product.slug}-specialprice-${specialPrice.quantity}`}>
							<FormattedMessage id="productUnitPriceInclTax" defaultMessage="Unit price incl.tax" />: <Price amount={specialPrice.unitSpecialPrice} />
						</Tooltip>
					}
				>
					<span key={specialPrice.quantity} className="d-block">
						<Icon icon="chevron-right" />
						<FormattedMessage
							id="productSpecialPriceQuantityDiscount"
							defaultMessage="Buy {quantity} and save {percent}%!"
							values={{
								quantity: specialPrice.quantity,
								percent: parseInt(100 - ((specialPrice.unitSpecialPrice / productNormalPrice) * 100), 10),
							}}
						/>
					</span>
				</OverlayTrigger>
			))}
		</div>
	);
}

ProductSpecialPriceQuantityInfo.propTypes = {
	product: PropTypes.object.isRequired,
	item: PropTypes.object,
	userGroup: PropTypes.object,
	referral: PropTypes.string,
};

const mapStateToProps = (store, props) => {
	return {
		item: getCartItemByProduct(store, props.product),
		userGroup: getUserGroup(store),
		referral: getReferral(store),
	};
};

export default connect(mapStateToProps)(ProductSpecialPriceQuantityInfo);
