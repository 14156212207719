// Import Actions
import { toDate } from '../../util/date.js';
import { getPersistedData, persistData } from '../../util/persistor.js';
import { INIT_SOCKET, SEND_SOCKET, RECEIVE_SOCKET, SET_REDIRECT, SET_STATUS_CODE, REDIRECT, SET_MOUNTED, UPDATE_TABLE, SET_SSR_MODE, SET_REFERRAL, SET_FORCE_CANONICAL, SET_POPIN_ACTIVE, SET_APP_LOADED, SET_IS_MOBILE, INIT_PERSISTED_DATA } from './AppActions.js';
// import { LOGIN_USER, LOGOUT_USER } from '../User/UserActions.js';

// Initial State
export const initialState = {
    socket: null,
    isSSR: false,
    isMobile: false,
    redirect: '',
    statusCode: 200,
    isMounted: false,
    tables: [
        // {
        //     id: 'users-list',
        //     activePage: 3,
        //     pageSize: null,
        //     searchFilters: [
        //         {
        //             property: 'name',
        //             value: 'test'
        //         }
        //     ],
        //     searchText: 'ma recherche'
        // }
    ],
    referral: null,
	referralDate: null,
    forceCanonical: false,
    isPopinActive: false,
};

const AppReducer = (state = initialState, action = {}) => {
    switch(action.type) {
        case SET_REDIRECT:
            return {
                ...state,
                redirect: action.redirect,
                statusCode: action.statusCode || state.statusCode,
            };

        case SET_STATUS_CODE:
            return {
                ...state,
                statusCode: action.statusCode,
            };

        case REDIRECT:
            // state.redirect && browserHistory.push(state.redirect);
            return state;

        case INIT_SOCKET:
            return {
                ...state,
                socket: action.socket,
            };

        case SEND_SOCKET:
            console.log('SEND_SOCKET', action.data);
            state.socket && state.socket.emit(action.data.type, action.data.data);
            return state;

        case RECEIVE_SOCKET:
            console.log('RECEIVE_SOCKET', action.data);
            return state;

        case SET_MOUNTED:
            return {
                ...state,
                isMounted: true,
            };

        case SET_APP_LOADED:
            return {
                ...state,
                isLoaded: action.isLoaded,
            };

        case UPDATE_TABLE:
            return {
                ...state,
                tables: [
                    ...state.tables.filter(table => table.id !== action.tableId),
                    {
                        id: action.tableId,
                        ...action.tableData,
                    },
                ],
            };

        case SET_SSR_MODE:
            return {
                ...state,
                isSSR: action.isSSR,
            };

        case SET_IS_MOBILE:
            return {
                ...state,
                isMobile: action.isMobile,
            };

        case SET_REFERRAL: {
            const newState = {
				...state,
				referral: action.referral,
				referralDate: toDate(),
            };
			// Persist only referral data
			persistData('app', getDataToPersist(newState));
			return newState;
		}

        case SET_FORCE_CANONICAL:
            return {
                ...state,
                forceCanonical: action.isCanonical,
            };

        case SET_POPIN_ACTIVE:
            return {
                ...state,
                isPopinActive: action.isPopinActive,
            };

        // case LOGIN_USER:
        // case LOGOUT_USER:
        //     return {
        //         ...state,
        //         isMounted: true,
        //     };

        case INIT_PERSISTED_DATA: {
			const storedState = getPersistedData('app', null);
			if(storedState) {
				return {
					...state,
					...storedState,
				};
			}
			persistData('app', getDataToPersist(state));
            return state;
		}

        default:
            return state;
    }
};

function getDataToPersist(state) {
	const { referral, referralDate, ...otherData } = state;
	return {
		referral,
		referralDate,
	};
}

// Export Reducer
export default AppReducer;
