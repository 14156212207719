// Import Actions
import { SET_CONFIGS, REMOVE_CONFIG } from './ConfigActions.js';
import { LOGOUT_USER } from '../User/UserActions.js';

// Initial State
export const initialState = {
    data: [],
};

const ConfigReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_CONFIGS: {
            const otherConfigs = state.data.filter(config => !action.configs.find(configToSet => configToSet.key === config.key));
            return {
                ...state,
                data: otherConfigs.concat(action.configs),
            };
        }

        case REMOVE_CONFIG: {
            return {
                ...state,
                data: state.data.filter(config => config.key !== action.key),
            };
        }

        case LOGOUT_USER:
            return initialState;

        default:
            return state;
    }
};

export default ConfigReducer;
