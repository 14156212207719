// import createHmac from 'create-hmac';
// import { getRandomString } from './text.js';

// export function getHashKeys(string, salt = null) {
//     if(!salt) {
//         salt = getRandomString();
//     }
//     const key = createHmac('sha256', salt)
//         .update(`${process.env.FAKE_ENV || process.env.NODE_ENV}${salt}${string}`)
//         .digest('hex');
//     return {
//         salt,
//         key,
//     };

//     // return {
//     //     salt,
//     //     key: getRandomString(),
//     // };
// }

export function getHashKeys(string, salt = null) {
	return {
		salt: salt || '1',
		key: 'a',
	};
}
