// Import Actions
import { persistData, getPersistedData } from '../../util/persistor.js';

import { SET_ITEM, REMOVE_ITEM, REMOVE_ITEMS, SET_INVOICE_ADDRESS, SET_SHIPPING_ADDRESS, SET_SHIPPING_METHOD, SET_CART_OPTION, SET_CART_MULTIORDER, CLEAR_CART_MULTIORDER, ADD_INVITATION, REMOVE_INVITATION, ADD_CART_NOTE, SET_CART_SUBSCRIPTION, REMOVE_CART_SUBSCRIPTION, CLEAR_CART } from './CartActions.js';
import { APPLY_VOUCHER, REMOVE_VOUCHER, REMOVE_VOUCHERS } from '../Voucher/VoucherActions.js';
import { INIT_PERSISTED_DATA } from '../App/AppActions.js';

// Initial State
export const initialState = {
    items: [],
    shipping: {
        // address: {
        //     countryCode: '', // cca2 code like 'FR'
        //     postalCode: '', // string: '66000'
        // },
        // carrier: ''
    },
    invoice: {
        // address: {},
    },
    vouchers: [],
    isVoucherPrioritary: false,
    multiorder: {},
    invitations: [],
    options: {},
    subscription: null,
	// {
	// 	periodUnit: 'day' | 'month',
	// 	periodValue: 1-n
	//  repetition: 1-n
	// }
    note: '',
};

const CartReducer = (state = initialState, action = {}) => {
    switch(action.type) {
        case SET_ITEM: {
            const existingItem = state.items.find(item => item.productId === action.item.productId);
            const newState = {
                ...state,
                items: existingItem ? state.items.map(item => {
                    if(item.productId === action.item.productId) {
                        return {
                            ...item,
                            ...action.item,
                        };
                    }
                    return item;
                }) : state.items.concat([action.item]),
            };
            persistData('cart', newState);
            return newState;
        }

        case REMOVE_ITEM: {
            const newState = {
                ...state,
                items: state.items.filter(item => item.productId !== action.item.productId),
            };
            persistData('cart', newState);
            return newState;
        }

        case REMOVE_ITEMS: {
            const newState = {
                ...state,
                items: [],
            };
            persistData('cart', newState);
            return newState;
        }

        case CLEAR_CART: {
            const newState = {
                ...state,
                items: [],
                vouchers: [],
                isVoucherPrioritary: false,
                shipping: (state.multiorder || {}).order ? {} : state.shipping,
                multiorder: {},
                options: {},
                subscription: null,
            };
            persistData('cart', newState);
            return newState;
        }

        case SET_INVOICE_ADDRESS: {
            const newState = {
                ...state,
                invoice: {
                    ...state.invoice,
                    address: action.address,
                },
            };
            persistData('cart', newState);
            return newState;
        }

        case SET_SHIPPING_ADDRESS: {
            const newState = {
                ...state,
                shipping: {
                    ...state.shipping,
                    address: action.address,
                },
            };
            persistData('cart', newState);
            return newState;
        }

        case SET_SHIPPING_METHOD: {
            const newState = {
                ...state,
                shipping: {
                    ...state.shipping,
                    method: action.method,
                },
            };
            persistData('cart', newState);
            return newState;
        }

        case APPLY_VOUCHER: {
            let vouchers = [...state.vouchers];
            if(!action.voucher.isCumulative) {
                vouchers = [];
            }
            const newState = {
                ...state,
                vouchers: [...vouchers.filter(voucher => voucher._id !== action.voucher._id), action.voucher],
                isVoucherPrioritary: action.isPrioritary,
            };
            persistData('cart', newState);
            return newState;
        }

        case REMOVE_VOUCHER: {
            const newState = {
                ...state,
                vouchers: state.vouchers.filter(voucher => voucher.code !== action.code),
                isVoucherPrioritary: false,
            };
            persistData('cart', newState);
            return newState;
        }

        case REMOVE_VOUCHERS: {
            const newState = {
                ...state,
                vouchers: [],
                isVoucherPrioritary: false,
            };
            persistData('cart', newState);
            return newState;
        }

        case SET_CART_OPTION: {
            const newState = {
                ...state,
                options: {
                    ...(state.options || {}),
                    [action.key]: action.value,
                },

            };
            persistData('cart', newState);
            return newState;
        }

        case SET_CART_MULTIORDER: {
            const newState = {
                ...state,
                multiorder: {
                    user: action.parentUser,
                    order: action.parentOrder,
                },

            };
            persistData('cart', newState);
            return newState;
        }

        case CLEAR_CART_MULTIORDER: {
            const newState = {
                ...state,
                multiorder: {},
                options: {
                    ...(state.options || {}),
                    isMultiorder: false,
                },

            };
            persistData('cart', newState);
            return newState;
        }

        case ADD_INVITATION: {
            const newState = {
                ...state,
                invitations: [
                    ...((state.invitations || []).filter(invitation => invitation.type !== action.invitation.type && invitation.id !== action.invitation.id)),
                    action.invitation,
                ],

            };
            persistData('cart', newState);
            return newState;
        }

        case REMOVE_INVITATION: {
            const newState = {
                ...state,
                invitations: (state.invitations || []).filter(invitation => invitation.id !== action.invitationId),

            };
            persistData('cart', newState);
            return newState;
        }

        case ADD_CART_NOTE: {
            const newState = {
                ...state,
                note: action.note,

            };
            persistData('cart', newState);
            return newState;
        }

        case SET_CART_SUBSCRIPTION: {
            const newState = {
                ...state,
                subscription: action.subscription,
            };
            persistData('cart', newState);
            return newState;
        }

        case REMOVE_CART_SUBSCRIPTION: {
            const newState = {
                ...state,
                subscription: null,
            };
            persistData('cart', newState);
            return newState;
        }

        case INIT_PERSISTED_DATA: {
			const storedState = getPersistedData('cart', null);
			if(storedState) {
				return {
					...state,
					...storedState,
				};
			}
			persistData('cart', state);
            return state;
		}

        default:
            return state;
    }
};

export default CartReducer;
