export default [
  [0.25, 5.27],
  [0.5, 5.94],
  [0.75, 6.64],
  [1, 7.21],
  [2, 8],
  [3, 8.78],
  [4, 9.57],
  [5, 10.35],
  [6, 11.15],
  [7, 11.93],
  [8, 12.7],
  [9, 13.5],
  [10, 14.29],
  [11, 15.08],
  [12, 15.87],
  [13, 16.65],
  [14, 17.45],
  [15, 18.23],
  [16, 19.02],
  [17, 19.8],
  [18, 20.58],
  [19, 21.38],
  [20, 22.15],
  [21, 22.95],
  [22, 23.73],
  [23, 24.52],
  [24, 25.31],
  [25, 26.09],
  [26, 26.89],
  [27, 27.67],
  [28, 28.46],
  [29, 29.26],
  [30, 30.02],
];
