// Import Actions
import { SET_REVIEWS } from './ReviewActions.js';
import { SET_IS_FETCHING } from '../App/AppActions.js';
import { LOGOUT_USER } from '../User/UserActions.js';

// Initial State
export const initialState = {
    data: [],
    isFetching: false,
};

const ReviewReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_REVIEWS: {
            const otherReviews = state.data.filter(review => !action.reviews.find(reviewToSet => reviewToSet._id === review._id));
            return {
                ...state,
                data: otherReviews.concat(action.reviews),
                isFetching: false,
            };
        }

        case SET_IS_FETCHING:
            if(action.dataType === 'reviews') {
                return {
                    ...state,
                    isFetching: action.isFetching,
                };
            }
            return state;

        // case LOGOUT_USER:
        //     return initialState;

        default:
            return state;
    }
};

export default ReviewReducer;
