import React, { Component, Fragment, memo, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';

// import '../../util/icons';

// Import Style
import 'react-toastify/dist/ReactToastify.css';

// Import Components
import Header from './components/layout/Header.js';
// import Menu from './components/Menu/Menu.js';
import Footer from './components/layout/Footer.js';
import Disclaimer from './components/view/Disclaimer.js';

import ToastProvider from '../Error/components/ToasterProvider.js';
import SeoHead from './components/seo/SeoHead.js';
import CartAutoAddToCart from '../Cart/components/form/CartAutoAddToCart.js';
import CartAutoAddVoucher from '../Voucher/components/form/CartAutoAddVoucher.js';
import ErrorDisclaimer from '../Error/components/ErrorDisclaimer.js';
import ErrorBoundary from '../../components/Error/ErrorBoundary.js';
import BugPage from '../Error/pages/BugPage.js';
import AppReferral from './components/view/AppReferral.js';
import AffiliationInit from '../Affiliation/components/view/AffiliationInit.js';
import UserGroupInit from '../User/components/view/UserGroupInit.js';
import AppAnalytics from './components/stats/AppAnalytics.js';
import LocaleInitiator from '../Intl/components/init/LocaleInitiator.js';

// let DevTools;
// if(process.env.NODE_ENV === 'development') {
//   // eslint-disable-next-line global-require
//   DevTools = require('./components/DevTools').default;
// }
// {false && canMount && !window.devToolsExtension && process.env.NODE_ENV === 'development' && <DevTools />}

function App(props) {
	// const canMount = isMounted || !process.env.CLIENT;
	const isDev = false;
	// console.log('App::: Render');

	return (
		<ErrorBoundary errorComponent={BugPage}>
			<LocaleInitiator>
				<ToastProvider>
					<SeoHead />
					<Header />

					<Container fluid className="content-wrapper">
						{!isDev && <Disclaimer />}
						<div className="content">
							<Outlet />
						</div>
					</Container>
					<Footer />

					<AppReferral />
					<UserGroupInit />
					<CartAutoAddToCart />
					<CartAutoAddVoucher />
					<AffiliationInit />
					<ErrorDisclaimer />
					<AppAnalytics />
				</ToastProvider>
			</LocaleInitiator>
		</ErrorBoundary>
	);
}

App.propTypes = {
    // children: PropTypes.object.isRequired,
};

export default App;
