import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { getContentBlockItemsByName, getContentBlockMixedItems, getContentBlockMixedItem } from '../../../Cms/CmsActions.js';

import Icon from '../../../../components/Content/Icon.js';
import CmsBlockItem from '../../../Cms/components/content/CmsBlockItem.js';
import PredictiveLink from '../../../Cms/components/view/PredictiveLink.js';

function PanelMainMenu({ panel, isMobile = false, closeMenu = null }) {
    const [isOpen, toggle] = useState(false);
    const links = getContentBlockItemsByName(panel.blocks, 'links');
// getContentBlockMixedItem(link, 'mainLevel').path
    if(links && links.length) {
        return (
            <div className={classnames('main-menu', 'position-relative', isMobile && ['mt-4', 'mobile'], isOpen && 'open')}>
                {links.filter(link => getContentBlockMixedItem(link, 'mainLevel') && getContentBlockMixedItem(link, 'mainLevel').path).map((link, index) => (
                    getContentBlockMixedItems(link, 'subLevel') && getContentBlockMixedItems(link, 'subLevel').length
                    ? (
                        <Fragment key={index}>
                            <strong className="d-flex justify-content-between ms-2 text-uppercase text-lg text-danger" onClick={closeMenu}>
                                {getContentBlockMixedItem(link, 'mainLevel').text}
                                {closeMenu && <Icon icon="close" size="4x" className="active" />}
                            </strong>
                            <ul className="ms-3 list-unstyled">
                                {getContentBlockMixedItems(link, 'subLevel').map(sub => (
                                    <li key={sub.path}><a href={sub.path} onClick={closeMenu}>{sub.text}</a></li>
                                ))}
                            </ul>
                        </Fragment>
                    )
                    : <CmsBlockItem key={index} item={getContentBlockMixedItem(link, 'mainLevel')} type="link" />
                ))}
            </div>
        );
    }
    return null;
}
// <Nav>
//     {links.filter(link => getContentBlockMixedItem(link, 'mainLevel') && getContentBlockMixedItem(link, 'mainLevel').path).map((link, index) => (
//         <li key={index}>
//             {
//                 getContentBlockMixedItems(link, 'subLevel') && getContentBlockMixedItems(link, 'subLevel').length
//                 ? (
//                     <UncontrolledDropdown>
//                         <DropdownToggle caret tag={Link} to="#" onClick={event => event.preventDefault()} className={classnames(isOpen && ['border-bottom-warning', 'open'])}>
//                             {getContentBlockMixedItem(link, 'mainLevel').text}
//                         </DropdownToggle>
//                         <DropdownMenu className="general-submenu">
//                             {getContentBlockMixedItems(link, 'subLevel').map(sub => <DropdownItem key={sub.path} tag={Link} to={sub.path} className="text-dark">{sub.text}</DropdownItem>)}
//                         </DropdownMenu>
//                     </UncontrolledDropdown>
//                 )
//                 : <NavItem><CmsBlockItem item={getContentBlockMixedItem(link, 'mainLevel')} type="link" /></NavItem>
//             }
//         </li>
//     ))}
// </Nav>

//      <!-- This checkbox will give us the toggle behavior, it will be hidden, but functional -->
//     <input id="toggle" type="checkbox">
//
//     <!-- IMPORTANT: Any element that we want to modify when the checkbox state changes go here, being "sibling" of the checkbox element -->
//
//     <!-- This label is tied to the checkbox, and it will contain the toggle "buttons" -->
//     <label class="toggle-container" for="toggle">
//         <!-- If menu is open, it will be the "X" icon, otherwise just a clickable area behind the hamburger menu icon -->
//         <span class="button button-toggle"></span>
//     </label>
//
//     <!-- The nav menu -->
//     <nav class="nav">
//         <a class="nav-item" href="">Dashboard</a>
//         <a class="nav-item" href="">History</a>
//         <a class="nav-item" href="">Statistics</a>
//         <a class="nav-item" href="">Settings</a>
//     </nav>
//


PanelMainMenu.propTypes = {
    panel: PropTypes.object.isRequired,
    isMobile: PropTypes.bool,
    closeMenu: PropTypes.func,
};

export default PanelMainMenu;
