import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { getContentBlockItemByName, getContentBlockItemValue } from '../../../CmsActions.js';

import CmsBlockItem from '../../content/CmsBlockItem.js';
import HtmlComponent from '../../../../../components/Text/HtmlComponent.js';

function PanelVoucherPopin({ panel }) {
    const image = getContentBlockItemByName(panel.blocks, 'image');
    const title = getContentBlockItemValue(panel.blocks, 'title');
    const content = getContentBlockItemValue(panel.blocks, 'content');
    return (
        <div className="p-2 text-center">
            {title && (
                <span className="mt-3 mb-4 h2 d-block border-half-danger text-primary text-center">
                    {title}
                </span>
            )}
            <HtmlComponent text={content} />
            {image && <CmsBlockItem item={image} type="image" className="w-100 rounded" />}
        </div>
    );
}

PanelVoucherPopin.propTypes = {
    panel: PropTypes.object.isRequired,
};

export default PanelVoucherPopin;
