import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, ButtonGroup } from 'react-bootstrap';

import { getLoggedUser } from '../../../User/UserActions';
import { getLanguagePath } from '../../../Intl/IntlHelper';

function CmsEditLinks({ intl, page, product = null, user = null }) {
	const [mounted, setMounted] = useState(false);
	useEffect(() => {
		setMounted(true);
	}, []);

	return mounted && page && user?.role === 'admin' && (
		<ButtonGroup vertical style={{ position: 'fixed', bottom: '50px', right: '10px' }}>
			<Button variant="outline-secondary" as={Link} to={getLanguagePath(`/console/content/${page._id}`, intl.locale)} className="bg-white"><FormattedMessage id="contentEditPage" defaultMessage="Edit page" /></Button>
			{product && <Button variant="outline-secondary" as={Link} to={getLanguagePath(`/console/product/${product._id}`, intl.locale)} className="bg-white"><FormattedMessage id="productEdit" defaultMessage="Edit product" /></Button>}
		</ButtonGroup>
	);
}

function mapStateToProps(store, props) {
	return {
		user: getLoggedUser(store),
	};
}

CmsEditLinks.propTypes = {
	intl: PropTypes.object.isRequired,
	page: PropTypes.object.isRequired,
	product: PropTypes.object,
	user: PropTypes.object,
};

export default connect(mapStateToProps)(injectIntl(CmsEditLinks));
