import React, { lazy, memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// List on https://css.gg/app
// To add an icon: add his name on the list below and add css classes on ./client/assets/css/css.gg-icons.min.css
export const ICONS = [
    'add',
    'airplane',
    'attachment',
    'arrow-left',
    'arrow-long-down',
    'arrow-long-up',
    'arrow-right',
    'awards',

    'bell',
	'bolt',

    'calendar-dates',
    'calculator',
    'cap',
    'check',
    'check-o',
    'chevron-down',
    'chevron-left',
    'chevron-right',
    'chevron-up',
    'clipboard',
    'close',
    'close-o',
    'comment',
    'components',
    'credit-card',

    'danger',

    'enter',
    'eye',

    'gift',

    'heart',
    'home',

    'image',
    'info',

    'euro',

    'file-document',

    'list',
    'lock',
    'lock-unlock',
    'log-in',
    'log-off',

    'mail',
    'math-minus',
    'menu-left',
    'merge-vertical',
    'more-vertical',

    'notes',

    'pen',
    'pin',
    'phone',
    'pin-alt',
	'play-button-o',
	'play-pause-o',
    'printer',

	'qr',

    'remove',
    'reply',

    'search',
    'share',
    'shape-circle',
    'shopping-bag',
    'shopping-cart',
    'smile',
    'smile-sad',
    'software-download',
    'software-upload',
    'spinner',
    'stack',
    'star',
    'sync',

    'tag',
    'trash',

    'ui-kit',
    'user',
    'user-add',
    'user-list',
    'user-remove',
];

const SVG = {
};

function adaptToLib(name) {
    switch(name) {
        case 'arrow-uparrow-up':
            return 'arrow-long-up';

        case 'arrow-down':
            return 'arrow-long-down';

        case 'at':
            return 'mail';

        case 'bars':
            return 'menu-left';

        case 'calendar':
            return 'calendar-dates';

        case 'caret-down':
            return 'chevron-down';

        case 'caret-up':
            return 'chevron-up';

        case 'check-circle':
            return 'check-o';

        case 'copy':
            return 'stack';

        case 'circle':
            return 'shape-circle';

        case 'dolly':
            return 'cap';

        case 'download':
            return 'software-download';

        case 'ellipsis-v':
            return 'more-vertical';

        case 'envelope':
        case 'envelope-open':
            return 'mail';

        case 'euro-sign':
            return 'euro';

        case 'exclamation-circle':
        case 'exclamation-triangle':
            return 'danger';

        case 'file-image':
            return 'image';

        case 'file-invoice':
            return 'file-document';

        case 'frown':
            return 'smile-sad';

        case 'hand-pointer':
            return 'check-o';

        case 'info-circle':
            return 'info';

        case 'long-arrow-alt-down':
            return 'arrow-long-down';

        case 'long-arrow-alt-up':
            return 'arrow-long-up';

        case 'map':
        case 'map-marker-alt':
            return 'pin';

        case 'minus':
            return 'remove';

        case 'paper-plane':
            return 'airplane';

        case 'piggy-bank':
            return 'tag';

        case 'plus':
            return 'add';

        case 'power-off':
            return 'log-off';

        case 'print':
            return 'printer';

        case 'save':
            return 'check';

        case 'sign-in-alt':
            return 'enter';

        case 'sort-amount-down':
            return 'arrow-long-down';

        case 'sort-amount-up':
            return 'arrow-long-up';

        case 'star':
            return 'heart';

        case 'sticky-note':
            return 'notes';

        case 'sync-alt':
            return 'sync';

        case 'tachometer-alt':
            return 'components';

        case 'trash-alt':
            return 'trash';

        case 'thumbtack':
            return 'pin-alt';

        case 'times':
            return 'close';

        case 'times-circle':
            return 'close-o';

        case 'unlock':
            return 'lock-unlock';

        case 'upload':
            return 'software-upload';

        case 'user-circle':
            return 'user';

        case 'user-plus':
            return 'user-add';

        case 'user-tag':
            return 'attachment';

        case 'user-times':
            return 'user-remove';

        case 'users':
            return 'user-list';

        default:
            return name;
    }
}

function Icon({ icon = '', size = null, spin = null, className = null, ...props }) {
    // icon = adaptToLib(icon); // Too HEAVY !!!!
    // if(ICONS.includes(icon)) { // Too HEAVY !!!!
        return icon && (
            <i className={classnames(className, 'icon', `gg-${icon.toLowerCase()}`, `icon-${size || '1x'}`, spin && 'icon-spin')} {...props}>{' '}</i>
        );
    // }
    // return <span {...props}>{process.env.NODE_ENV === 'development' ? `ICON ${icon}` : ''}</span>;
}

Icon.propTypes = {
	icon: PropTypes.string,
	size: PropTypes.string,
	spin: PropTypes.bool,
	className: PropTypes.string,
};

export default memo(Icon);
