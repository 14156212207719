import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { Dropdown, Nav } from 'react-bootstrap';

import { connect } from 'react-redux';
import { getCountryFromLanguage, getEnabledLanguages, getLanguagePath } from '../../IntlHelper';
import Flag from '../../../../components/Flag/Flag';
import { getPageBySlugAndLanguage } from '../../../Cms/CmsActions';
import withRouter from '../../../../components/Router/WithRouter';
import { getLoggedUser, getUserPreference } from '../../../User/UserActions';
import { initLanguage } from '../../IntlActions';

function LocaleSwitcher({ dispatch, location, intl, page = null, user = null }) {
	const [isOpen, setIsOpen] = useState(false);

	const handleSwitchLanguage = language => {
        if(language) {
            dispatch(initLanguage(language, user)).then(language => {
                // Search for meta alternate link set by Helmet to redirect to translated content
                // this.props.navigate(this.getLanguageUrl(language), { replace: true });
            });
        } else {
            console.log('LocaleSwitcher::: Missing Locale', language);
        }
    };

	useEffect(() => {
		user && getUserPreference(user, 'language') !== intl.locale && handleSwitchLanguage(intl.locale);
	}, [user]);

	const toggle = () => setIsOpen(!isOpen);

	const getLanguageUrl = language => {
        let url = '';
        // const redirectionLink = Array.from(document.querySelectorAll('link[rel="alternate"]')).find(link => link.hreflang === language));
        const translation = ((page || {}).translations || []).find(translation => translation.language === language);
        // console.log('Header::: Translation found', language, translation);
        if(translation) {
            // console.log('Link alternate found', redirectionLink.href, redirectionLink.href.replace(config.url, '/'));
            url = `/${translation.language}/${translation.slug.replace('*', '')}${location.search || ''}`;
        } else if(location.pathname !== getLanguagePath(location.pathname, language)) {
            // console.log('Replace route to ', this.props.location.pathname, getLanguagePath(this.props.location.pathname, language), this.props.location.search, getLanguagePath(this.props.location.pathname, language) + this.props.location.search);
            url = getLanguagePath(location.pathname, language) + (location.search || '');
        }
		// console.log('LocaleSwitcher::: getLanguageUrl', language, url);
        return url;
    };

	const languageNodes = getEnabledLanguages().filter(locale => locale !== intl.locale).map(
		lang => <Dropdown.Item key={lang} as="span" size="sm"><Nav.Link as="a" href={getLanguageUrl(lang)} className={lang === intl.locale ? 'selected' : ''} rel="alternate" hrefLang={lang}><Flag country={getCountryFromLanguage(lang)} /></Nav.Link></Dropdown.Item>,
	);

	return (
		<Dropdown show={isOpen} onToggle={toggle} className="ms-3 align-content-center">
			<Dropdown.Toggle variant="link" size="sm" as="span" className="p-0">
				<Flag country={getCountryFromLanguage(intl.locale)} />
			</Dropdown.Toggle>
			<Dropdown.Menu renderOnMount className={classNames('position-absolute', 'text-center', isOpen ? 'd-block' : 'd-none')} style={{ left: '-200%', top: isOpen ? 'auto' : '-500px' }}>{languageNodes}</Dropdown.Menu>
		</Dropdown>
	);
}

function mapStateToProps(store, props) {
	return {
        page: getPageBySlugAndLanguage(store, props.params.slug || props.params['*'], store.intl.locale),
		user: getLoggedUser(store),
	};
}

LocaleSwitcher.propTypes = {
	dispatch: PropTypes.func.isRequired,
	location: PropTypes.object.isRequired,
	intl: PropTypes.object.isRequired,
	page: PropTypes.object,
	user: PropTypes.object,
};

export default withRouter(connect(mapStateToProps)(injectIntl(LocaleSwitcher)));
