/**
 * Root Reducer
 */
import { combineReducers } from 'redux';

// Import Reducers
import app from './modules/App/AppReducer.js';
import config from './modules/Config/ConfigReducer.js';
import intl from './modules/Intl/IntlReducer.js';
import currency from './modules/Currency/CurrencyReducer.js';
import cms from './modules/Cms/CmsReducer.js';
import error from './modules/Error/ErrorReducer.js';

import users from './modules/User/UserReducer.js';
import groups from './modules/User/UserGroupReducer.js';
import addresses from './modules/Address/AddressReducer.js';
import products from './modules/Product/ProductReducer.js';
import orders from './modules/Order/OrderReducer.js';
import cart from './modules/Cart/CartReducer.js';
import reviews from './modules/Review/ReviewReducer.js';
import shippings from './modules/Shipping/ShippingReducer.js';
import vouchers from './modules/Voucher/VoucherReducer.js';
import tasks from './modules/Task/TaskReducer.js';
import affiliations from './modules/Affiliation/AffiliationReducer.js';

// const persistConfigCart = {
//   key: 'cart',
//   storage
// }
// const persistConfigOrders = {
//   key: 'orders',
//   storage,
//   whitelist: ['invitations']
// }

// Combine all reducers into one root reducer
export default combineReducers({
    // process.env.CLIENT provoque une erreur (invisible en dev) !!
    // orders: process.env.CLIENT ? persistReducer(persistConfigInvitation, orders) : orders,
    // cart: process.env.CLIENT ? persistReducer(persistConfigCart, cart) : cart,
    addresses,
    affiliations,
    app,
    cart, // persistReducer(persistConfigCart, cart),
    config,
    cms,
    currency,
    error,
    groups,
    intl,
    orders, // persistReducer(persistConfigOrders, orders),
    products,
    reviews,
    shippings,
    tasks,
    users,
    vouchers,
});
