export default {
  '5b3a06d5c1f0ebdc0170f9c6': {
    'name': 'Chorizo',
    'priceTTC': 3.2,
    // 'companyCostHT': 1.08,
  },
  // '6124a27df6de08a1a9725b6d': {
  //   'name': 'Coffret de Noël',
  //   'priceTTC': 23.9,
  //   'companyCostHT': 10.25,
  // },
  '5fd29198d938690d8feb4cb8': {
    'name': 'Coffret découverte',
    'priceTTC': 29.79,
    'companyCostHT': 1.08 + 1.1 + 0.98 + 1.1 + 1.08 + 1.04 + 1.29 + 1.29 + 1.29,
  },
  // '5ddfb975bfae7239ff2883a9': {
  //   'name': 'Épaule Iberica José Castro',
  //   'priceTTC': 225,
  //   'companyCostHT': 119,
  // },
  // '5dde6995bfae7239ff28831f': {
  //   'name': 'Épaule Matanza Manuel Diaz',
  //   'priceTTC': 99,
  //   'companyCostHT': 48.65,
  // },
  '5b3a2881c1f0ebdc0170f9d2': {
    'name': 'Filet mignon de porc',
    'priceTTC': 13.9,
    'companyCostHT': 6.38,
  },
  '5fd29778d938690d8feb4cb9': {
    'name': 'Fou de canard',
    'priceTTC': 42.5,
    'companyCostHT': 1.04 + 1.10 +1.1 + 1.1 + 5.2 + 6.16,
  },
  '60b783a4f5bb5b4f32c10c5c': {
    'name': 'Fouet Ail',
    'priceTTC': 3.3,
    'companyCostHT': 1.10,
  },
  '5b3a0560c1f0ebdc0170f9c4': {
    'name': 'Fouet Canard',
    'priceTTC': 3.3,
    'companyCostHT': 1.04,
  },
  '61f904d399cf9b5dbea4a37e': {
    'name': "Fouet Canard à l'orange",
    'priceTTC': 3.4,
    'companyCostHT': 1.1,
  },
  '5b9649f9d91dfc467f60aed6': {
    'name': 'Fouet Canard aux Champignons',
    'priceTTC': 3.4,
    'companyCostHT': 1.1,
  },
  '5cec1c37f92d929b0fe90849': {
    'name': 'Fouet Canard aux Figues',
    'priceTTC': 3.4,
    'companyCostHT': 1.1,
  },
  '5fbd33438645f9207a9a2082': {
    'name': 'Fouet Canard aux Noisettes',
    'priceTTC': 3.4,
    'companyCostHT': 1.1,
  },
  '5fbbdc675cefb2579fc3d889': {
    'name': 'Fouet Canard légumes',
    'priceTTC': 3.4,
    'companyCostHT': 1.2,
  },
  '5b3a063ec1f0ebdc0170f9c5': {
    'name': 'Fouet Catalan',
    'priceTTC': 3.4,
    'companyCostHT': 1.1,
  },
  '5db00dbeab1275474bd06d55': {
    'name': 'Fouet Herbes',
    'priceTTC': 2.25,
    'companyCostHT': 1.03,
  },
  // '5b9563455387fb154b053014': {
  //   'name': 'Fouet Mouton',
  //   'priceTTC': 2.7,
  //   'companyCostHT': 1.31,
  // },
  '5fef20c058ae6f066cd542f9': {
    'name': 'Fouet Oignons',
    'priceTTC': 2.1,
    // 'companyCostHT': 1.02,
  },
  '5b3a0300c1f0ebdc0170f9c1': {
    'name': 'Fouet Olives',
    'priceTTC': 3.35,
    // 'specialPriceTTC': 2.99,
    // 'companyCostHT': 0.98,
  },
  '5dee25546742d380d5808038': {
    'name': 'Fouet Piment',
    'priceTTC': 3.2,
    'companyCostHT': 1.14,
  },
  '5b3a0460c1f0ebdc0170f9c2': {
    'name': 'Fouet Poivre',
    'priceTTC': 3.2,
    'companyCostHT': 1.1,
  },
  '5b3a04fac1f0ebdc0170f9c3': {
    'name': 'Fouet Roquefort',
    'priceTTC': 3.3,
    'companyCostHT': 1.08,
  },
  '5fdb2f7e7cd3db6b6ac5d94c': {
    'name': 'Fouet Truffes',
    'priceTTC': 3.4,
    'companyCostHT': 1.3,
  },
  // '5dde6850bfae7239ff28831d': {
  //   'name': "Jambon réserve Artisan à l'os",
  //   'priceTTC': 169,
  //   'companyCostHT': 77,
  // },
  // '5b3a2769c1f0ebdc0170f9d0': {
  //   'name': 'Jambon réserve Artisan désossé',
  //   'priceTTC': 145,
  //   'companyCostHT': 68,
  // },
  // '5c2e3a94abc7fb4651df1ef2': {
  //   'name': 'Jambon Serrano désossé',
  //   'priceTTC': 119,
  //   'companyCostHT': 53,
  // },
  '5b65959ae957b37cfabd4f94': {
    'name': 'Jambon Serrano en tranches',
    'priceTTC': 4.9,
    'companyCostHT': 1.99,
  },
  '601d1690e3c67e1202235571': {
    'name': 'Lomo',
    'priceTTC': 14.5,
    'companyCostHT': 4.7,
  },
  '5b3a280bc1f0ebdc0170f9d1': {
    'name': 'Magret de canard',
    'priceTTC': 17.5,
    'companyCostHT': 6.16,
  },
  '5b3a0b63c1f0ebdc0170f9cd': {
    'name': 'Noix de boeuf maigre',
    'priceTTC': 14.5,
    'companyCostHT': 5.72,
  },
  '5b3a08a7c1f0ebdc0170f9ca': {
    'name': 'Noix de jambon',
    'priceTTC': 13.9,
    'companyCostHT': 4.44,
  },
  '5fbc27d65cefb2579fc3d899': {
    'name': 'Noix de jambon au poivre',
    'priceTTC': 13.9,
    'companyCostHT': 5.16,
  },
  '60a61118b6fcba6f96a76dd4': {
    'name': "Pack l'Essentiel",
    'priceTTC': 16.29,
    'specialPriceTTC': 12.9,
    'companyCostHT': 1.08 + 0.98 + 1.1 + 1.1 + 1.29,
  },
  // '61af226374de3c032e4f8493': {
  //   'name': 'Portant à jambon',
  //   'priceTTC': 15,
  //   'companyCostHT': 4.69,
  // },
  '5b3a2674c1f0ebdc0170f9cf': {
    'name': 'Portion de jambon Serrano',
    'priceTTC': 21.9,
    'companyCostHT': 8.3,
  },
  '5fc4b579187ebe4370c70d01': {
    'name': 'Rosette',
    'priceTTC': 12.5,
    'companyCostHT': 4.9,
  },
  '5f3e95fdda40c3e58b8b2abe': {
    'name': 'Saucisson Chevreuil',
    'priceTTC': 3.5,
    'companyCostHT': 1.29,
  },
  '5e465f191b36bc4c5dbc7d5f': {
    'name': 'Saucisson Figues',
    'priceTTC': 3.5,
    'companyCostHT': 1.29,
  },
  '5b3a0836c1f0ebdc0170f9c9': {
    'name': 'Saucisson Filet de porc',
    'priceTTC': 3.6,
    // 'companyCostHT': 1.1,
  },
  '5e957ef3f9b9dbae770e9fbd': {
    'name': 'Saucisson Le Maigre',
    'priceTTC': 3.5,
    'companyCostHT': 1.29,
  },
  '5fbd3dd28645f9207a9a208a': {
    'name': 'Saucisson Magret de canard',
    'priceTTC': 11.9,
    'companyCostHT': 5.2,
  },
  '6139dbf58e973a4e5d0c27f2': {
    'name': 'Saucisson Manchego',
    'priceTTC': 3.5,
    'companyCostHT': 1.29,
  },
  '5b3a07d9c1f0ebdc0170f9c8': {
    'name': 'Saucisson Noisettes',
    'priceTTC': 3.5,
    'companyCostHT': 1.29,
  },
  '5b3a0765c1f0ebdc0170f9c7': {
    'name': 'Saucisson Sanglier',
    'priceTTC': 3.5,
    'companyCostHT': 1.29,
  },
  '5ff4ea5fc7f1f132cb62d78f': {
    'name': 'Saucisson surprise',
    'priceTTC': 0,
    'companyCostHT': 1.1,
  },
  '60b784f1f5bb5b4f32c10c5e': {
    'name': 'Soubressade douce',
    'priceTTC': 4.9,
    'companyCostHT': 1.9,
  },
  '6050c5d41b8ad56213755b45': {
    'name': 'Soubressade piquante',
    'priceTTC': 4.9,
    'companyCostHT': 1.9,
  },
};
