export default {
    'pro': {
        discount: 60,
    },
    'semi-pro': {
        discount: 45,
    },
    'mini-pro': {
        discount: 30,
    },
    'ce': {
        discount: 30,
    },
    'asso': {
        discount: 30,
    },
};
