import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { JSONLD, Generic, GenericCollection } from 'react-structured-data';

import config from '../../../../config.js';
import { capitalizeFirstLetter } from '../../../../util/text.js';

import { getLanguagePath } from '../../../Intl/IntlHelper.js';
import { getConfig } from '../../../Config/ConfigActions.js';
import { getPanelBySlugAndLanguage, getContentBlockItemValue, memoizedGetPanelBySlugAndLanguage } from '../../../Cms/CmsActions.js';

import withRouter from '../../../../components/Router/WithRouter.js';
import PredictiveLink from '../../../Cms/components/view/PredictiveLink.js';

function Breadcrumbs({ location, intl, crumbs = [], siteTitlePanel = null }) {
    const siteTitle = siteTitlePanel && getContentBlockItemValue(siteTitlePanel.blocks, 'text');

    const renderCrumb = (crumb, index) => {
        return (
            <span key={index}>
                {
                    typeof crumb === 'object'
                    ? <a href={crumb.path} className={classnames(index + 1 === crumbs.length && 'text-secondary')}>{crumb.text}</a>
                    : <span className={classnames(index + 1 === crumbs.length && 'text-secondary')}>{crumb}</span>
                }
                {index + 1 < crumbs.length && ' > '}
            </span>
        );
    };

    // console.log(location, location.pathname.split('/'));
    // Enabled on all pages except home & cart
    const pathParts = location.pathname.split('/').filter(part => part);
    const isEnabled = pathParts.length > 1 && !location.pathname.includes('/cart');

	useEffect(() => {
		if(!crumbs || !crumbs.length) {
			if(typeof window !== 'undefined' && window.document) {
				const titleParts = `${window.document.title}`.split('-');
				titleParts.pop();
				crumbs = [titleParts.join('-')];
			} else {
				crumbs = [capitalizeFirstLetter(pathParts.join(' ').replace(/-/g, ' '))];
			}
		}
	}, []);

    pathParts.shift();

    if(isEnabled && crumbs?.length > 1) {
        return (
            <Fragment>
                {crumbs.length && (
                    <JSONLD dangerouslyExposeHtml>
                        <Generic jsonldtype="BreadcrumbList">
                            <GenericCollection type="itemListElement">
                                {crumbs
                                .map(crumb => (crumb.path ? crumb : { text: crumb }))
                                .filter(crumb => crumb.text)
                                .map(crumb => ({ url: crumb.path ? `${config.url.slice(0, -1)}${crumb.path}` : '', ...crumb }))
                                .map((crumb, index) => (
                                    <Generic
                                        key={crumb.url || crumb.text}
                                        jsonldtype="ListItem"
                                        schema={{
                                            position: index + 1,
                                            item: {
                                                '@id': crumb.url || crumb.text,
                                                name: crumb.text,
                                                url: crumb.url,
                                            },
                                        }}
                                    />
                                ))}
                            </GenericCollection>
                        </Generic>
                    </JSONLD>
                )}
                <div className="breadcrumbs p-2 text-primary">
                    <a href={getLanguagePath('/', intl.locale)} className="">{siteTitle || <FormattedMessage id="appHome" defaultMessage="Home" />}</a>
                    {' > '}
                    {crumbs.map(renderCrumb)}
                </div>
            </Fragment>
        );
    }
    return null;
}

function mapStateToProps(store, props) {
    return {
        siteTitlePanel: memoizedGetPanelBySlugAndLanguage(store, 'site-title', (store.intl || {}).locale),
    };
}

Breadcrumbs.propTypes = {
    location: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    crumbs: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
    siteTitlePanel: PropTypes.object,
};

export default connect(mapStateToProps)(withRouter(injectIntl(Breadcrumbs)));
