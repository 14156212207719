import React, { useEffect } from 'react';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import { ErrorBoundary } from 'react-error-boundary';

import config from '../../config.js';

if(typeof window !== 'undefined' && config.bugsnag && config.bugsnag.key) {
	Bugsnag.start({
		apiKey: config.bugsnag.key,
		appVersion: config.version,
		enabledReleaseStages: ['production'],
		appType: 'client',
		plugins: [new BugsnagPluginReact(React)],
	});
}

// const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const bugsnagComponent = ({ errorComponent, children }) => {
	// console.log('ErrorBoundary::: Render');
    return (
        <ErrorBoundary FallbackComponent={errorComponent} onError={notifyError}>
            {children}
        </ErrorBoundary>
    );
};

export function notifyError(error, meta = {}) {
    console.error(error, meta);
    // if(process.env.NODE_ENV === 'production') {
        Bugsnag.notify(error, meta);
    // }
}

export default bugsnagComponent;
