// Import Actions
import { LOGIN_USER, LOGOUT_USER, /* CONNECTED_USER, CONNECTED_USERS, DISCONNECTED_USER, */ REGISTER_USER, SET_USERS, SET_USER_ACTIVITY, SET_USER_ACTIVITY_DATE, SET_SUBSCRIPTIONS, ADD_USER_INTERESTS } from './UserActions.js';
import { SET_IS_FETCHING } from '../App/AppActions.js';

import { unique } from '../../util/array.js';

// Initial State
export const initialState = {
    user: null,
    data: [],
    // connectedUsers: {},
    isFetching: false,
    activityDates: {},
};

const UserReducer = (state = initialState, action = {}) => {
    switch(action.type) {
        case LOGIN_USER:
        case REGISTER_USER:
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.user,
                 },
            };

        case LOGOUT_USER:
            return {
                ...state,
                user: null,
                data: [],
                // connectedUsers: [],
            };

        // case CONNECTED_USER:
        //     return {
        //         ...state,
        //         connectedUsers: [...state.connectedUsers, action.user],
        //     };
        //
        // case CONNECTED_USERS:
        //     return {
        //         ...state,
        //         connectedUsers: action.users,
        //     };
        //
        // case DISCONNECTED_USER: {
        //     const newConnectedUsers = state.connectedUsers.filter(user => user._id !== action.user._id);
        //     return {
        //         ...state,
        //         connectedUsers: newConnectedUsers,
        //     };
        // }

        case SET_USERS: {
            const existingUsers = state.data.filter(user => !action.users.find(userToAdd => user._id === userToAdd._id));
            return {
                ...state,
                data: existingUsers.concat(action.users),
                isFetching: false,
            };
        }

        // case SET_USER_ACTIVITY:
        //     return {
        //         ...state,
        //         connectedUsers: {
        //             ...state.connectedUsers,
        //             [action.userId]: action.lastActivityTimestamp,
        //         },
        //     };

        case SET_IS_FETCHING:
            if(action.dataType === 'users') {
                return {
                    ...state,
                    isFetching: action.isFetching,
                };
            }
            return state;

        case SET_USER_ACTIVITY_DATE:
            return {
                ...state,
                activityDates: {
                    ...state.activityDates,
                    [action.key]: action.value,
                },
            };

        case ADD_USER_INTERESTS:
            return {
                ...state,
                user: {
                    ...(state.user || {}),
                    interests: unique([...((state.user || {}).interests || []), ...(action.interests || [])]),
                },
            };

        case SET_SUBSCRIPTIONS:
            return {
                ...state,
                user: {
                    ...(state.user || {}),
                    newsletterSubscriptions: action.subscriptions,
                },
            };

        default:
            return state;
    }
};

export default UserReducer;
