import { formatPrice } from '../../util/price.js';

import { getCart, getCartOptions, getCartMultiorder, getCartItems, getCartShipping, getCartVouchers, getTotalQuantity, getSubtotal, getTotalTax, isConditionChecked } from './CartActions.js';
import { getShippingFee } from '../Shipping/ShippingHelpers.js';
import { checkVoucherConditions, getVoucherDiscountValue } from '../Voucher/VoucherActions.js';
import { getProducts, getProductsFromStore } from '../Product/ProductActions.js';
import { getLoggedUser, getUserOption } from '../User/UserActions.js';
import { getUserGroup } from '../User/UserGroupActions.js';
import { getOrdersByUser } from '../Order/OrderActions.js';

export function isCartValid(store, exceptions = []) {
    const cartOptions = getCartOptions(store);
    const cartMultiorder = getCartMultiorder(store);
    const user = getLoggedUser(store);
    const userGroup = getUserGroup(store) || {};
    const removeConditionsVouchers = getCartVouchers(store).filter(voucher => checkVoucherConditions(voucher, user, userGroup, user && getOrdersByUser(store, user._id, ['onpreparation', 'shipped', 'complete']), getCart(store))).filter(voucher => voucher.actions.find(action => action.key === 'conditionRemover'));
    const userGroupConditions = (userGroup.conditions || []).filter(userGroupCondition => !removeConditionsVouchers.find(voucher => voucher.actions.find(action => action.key === 'conditionRemover' && action.value === userGroupCondition.key)));
	const shipping = getCartShipping(store);
	const isShippingValid = shipping?.method?.carrier && shipping?.address?.countryCode;
    // console.log('isCartValid ::: User group conditions applied', (userGroup.conditions || []), userGroupConditions);
    return (
		(cartOptions.isMultiorder || (cartMultiorder.user && cartMultiorder.order) || !userGroup || userGroupConditions.every(condition => isConditionChecked(condition, getCartItems(store))))
		&& getTotalQuantity(getCartItems(store)) > 0
		&& getCartTotalValue(store) > 0
		&& (exceptions.includes('shipping') || isShippingValid)
	);
}

export function getCartShippingValue(store) {
    const shipping = getCartShipping(store);
    return getShippingFee(shipping.address, shipping.method, getCartItems(store), getLoggedUser(store));
}

export function getCartVouchersValue(store, user = null) {
    const userGroup = getUserGroup(store);
    const vouchers = getCartVouchers(store);
    const cartItems = getCartItems(store);
    const cart = getCart(store);
    const shippingFee = getCartShippingValue(store);
    return formatPrice((vouchers || []).reduce((discount, voucher) => discount + (checkVoucherConditions(voucher, user, userGroup, user && getOrdersByUser(store, user._id, ['onpreparation', 'shipped', 'complete']), cart) ? getVoucherDiscountValue(voucher, cartItems, shippingFee, { exemptVAT: getUserOption(user, 'exemptVAT') }) : 0), 0));
}

export function getCartTotalValue(store, user = null) {
    const products = getProductsFromStore(store);
    return formatPrice(
        getSubtotal(getCartItems(store), products, !getUserOption(user, 'exemptVAT'))
        + (getCartShippingValue(store) || 0)
        - getCartVouchersValue(store, user),
    );
}

export function getCartTotals(store, user = null) {
    if(!user) {
        user = getLoggedUser(store);
    }
    const products = getProductsFromStore(store);
    return {
        items: getSubtotal(getCartItems(store), products, !getUserOption(user, 'exemptVAT')),
        tax: getTotalTax(getCartItems(store), products, null, user),
        shippingFee: getCartShippingValue(store),
        discount: getCartVouchersValue(store, user),
        totalTTC: getCartTotalValue(store, user),
    };
}
