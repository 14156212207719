import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { memoizedGetPosts } from '../../CmsActions';

import PostsSlider from './PostsSlider';

function HomePosts({ posts = [] }) {
	return (
		<Container className="py-md-4">
			<div className="my-3 text-center">
			<span className="h3 d-inline-block border-half-dangerlight text-center"><FormattedMessage id="appBlogLastPostsTitle" defaultMessage="Last posts" /></span>
				<PostsSlider posts={(posts || []).slice(0, 4)} excerptSize={20} />
			</div>
		</Container>
	);
}

HomePosts.propTypes = {
    posts: PropTypes.arrayOf(PropTypes.object),
};

// Retrieve data from store as props
function mapStateToProps(store) {
    return {
        posts: memoizedGetPosts(store, store.intl.locale),
    };
}

export default connect(mapStateToProps)(HomePosts);
