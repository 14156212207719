export default {
    type: 'panel',
    name: 'slider',
    blocks: [
        {
            name: 'content', // name or id; MUST be unique
            label: 'Content', // Display name, if not set, name is used
            type: 'html', // || text || link || image || mixed (with children)
            // items: [{text: '<p>azerty 123456</p>'}], // values saved inserted in content model
        },
        {
            name: 'interval',
            type: 'text',
        },
    ],
};
