import slugify from 'slugify';
import ReactGA4 from 'react-ga4';

import { createSelector } from 'reselect';
import callApi from '../../util/apiCaller.js';
import { parseQuery } from '../../util/parser.js';
import storage, { storagePersistent } from '../../util/storage.js';
import { getPersistedData } from '../../util/persistor.js';

import config from '../../config.js';

export const SET_USER_GROUPS = 'SET_USER_GROUPS';
export const SET_USER_GROUP = 'SET_USER_GROUP';
export const SET_USER_GROUP_FROM_PARENT = 'SET_USER_GROUP_FROM_PARENT';

export const USER_GROUP_DEFAULT = 'individual';

export const USER_GROUP_STORAGE_KEY = `${slugify((config.application.name || '').toLowerCase())}.${process.env.NODE_ENV}.user.group`;

export function getUserGroupsRequest() {
    return dispatch => {
        return callApi('user/groups').then(res => {
            if(res.groups) {
                dispatch(setUserGroups(res.groups));
            }
            return res.groups;
        }).catch(error => {
            return null;
        });
    };
}

export function getUserGroupRequest(identifier = null, setOnCurrentUser = true) {
    return dispatch => {
        const params = (typeof window !== 'undefined' && window && parseQuery(window.location.search.substring(1))) || {};
        const persistedUserGroup = getPersistedData('userGroup');
        // console.log('1', persistedUserGroup);
        // console.log('2', identifier, params.group, (persistedUserGroup.userGroup || {}).identifier, USER_GROUP_DEFAULT);
        identifier = identifier
			|| (((params.group || '').toString().split(',') || []).shift())
			|| (persistedUserGroup.userGroup || {}).identifier
			|| USER_GROUP_DEFAULT;
        return callApi(`user/group${identifier ? `/${identifier}` : ''}`).then(res => {
            if(res.group) {
                if(setOnCurrentUser) {
                    dispatch(setUserGroup(res.group));
                    trackUserGroupAnalytics(res.group);
                }
            }
            return res.group;
        }).catch(error => {
            if(identifier !== USER_GROUP_DEFAULT) {
                return dispatch(getUserGroupRequest(USER_GROUP_DEFAULT));
            }
        });
    };
}

export function resetUserGroupRequest() {
    return dispatch => {
        dispatch(setUserGroupFromParent(false));
        return dispatch(getUserGroupRequest(USER_GROUP_DEFAULT));
    };
}

export function editUserGroupRequest(group) {
    return dispatch => {
        return callApi('user/group/edit', 'post', { group }).then(res => {
            res.group && dispatch(getUserGroupsRequest());
            return res.group;
        }).catch(err => {
            return null;
        });
    };
}

export function trackUserGroupAnalytics(group) {
    if(group && typeof window !== 'undefined') {
        window?.dataLayer && window.dataLayer.push({ user_group: group.identifier });
        window?.fbq && window.fbq('trackCustom', 'UserGroup', { user_group: group.identifier });
        config.google.analytics.trackingCodeGA4 && ReactGA4.gtag('set', 'user_properties', {
            user_group: group.identifier,
        });
    }
}

export function getUserGroups(store) {
    return (store.groups.data || []).sort((groupA, groupB) => groupA.identifier.localeCompare(groupB.identifier));
}

// export function getUserGroup(store, identifier = null) {
//     if(identifier) {
//         return store.groups.data.find(group => group.identifier === identifier);
//     }
//     return store.groups.userGroup;
// }
export const getUserGroup = createSelector(
	[
		store => store.groups,
		(store, identifier) => identifier,
	],
	(reducer, identifier = null) => {
		if(identifier) {
			return reducer.data.find(group => group.identifier === identifier);
		}
		return reducer.userGroup;
	},
);

export function getIsUserGroupFromParent(store) {
	return store.groups.isFromParent;
}

export function doesUserGroupHasCondition(store, key) {
    const group = getUserGroup(store);
    return group && getUserGroupCondition(group, key);
}

export function getUserGroupCondition(group, key) {
    return group && (group.conditions || []).find(condition => condition.key === key);
}

export function getUserGroupOption(group, option) {
    return (group && (group.options || {})[option]) || '';
}

export function setUserGroups(groups) {
    return {
        type: SET_USER_GROUPS,
        groups,
    };
}

export function setUserGroup(group) {
    return {
        type: SET_USER_GROUP,
        group,
    };
}
export function setUserGroupFromParent(isFromParent = true) {
    return {
        type: SET_USER_GROUP_FROM_PARENT,
        isFromParent,
    };
}
