import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Row, Col, ProgressBar } from 'react-bootstrap';

import { getFile } from '../../../../util/file.js';

import { getVouchers, checkVoucherConditions } from '../../VoucherActions.js';
import { getOrdersByUser } from '../../../Order/OrderActions.js';
import { getCart, getCartVouchers } from '../../../Cart/CartActions.js';
import { getCartTotals } from '../../../Cart/CartHelpers.js';
import { memoizedGetProducts } from '../../../Product/ProductActions.js';
import { getLoggedUser } from '../../../User/UserActions.js';
import { getUserGroup } from '../../../User/UserGroupActions.js';

import Icon from '../../../../components/Content/Icon.js';
import Price from '../../../Currency/components/view/Price.js';
import HtmlComponent from '../../../../components/Text/HtmlComponent.js';

function VoucherProgress({ dispatch, user = null, userGroup = null, cart = null, cartTotals = null, cartVouchers = [], vouchers = [], products = [], orders = [] }) {
    const [displayConditions, setDisplayConditions] = useState(false);
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
		setMounted(true);
    }, []);

	if(!mounted) {
		return null;
	}

    const vouchersSelected = (vouchers || [])
        .filter(voucher => voucher.isPublic || voucher.isAuto)
        .concat(cartVouchers)
        .sort((voucherA, voucherB) => {
            if(voucherA.priority || voucherB.priority) {
                return (voucherA.priority || 0) - (voucherB.priority || 0);
            }
            const getMinSubtotalCondition = voucher => voucher.conditions.filter(condition => condition.key === 'subtotal').reduce((min, condition) => Math.min(condition.value, min || condition.value), null);
            return getMinSubtotalCondition(voucherA) - getMinSubtotalCondition(voucherB);
        });
    const nextVoucher = cart.isVoucherPrioritary ? (cartVouchers || [])[0] : vouchersSelected.find(voucher => voucher.conditions.find(condition => condition.key === 'subtotal' && condition.value > cartTotals.items));

    // if(cartVouchers.length) {
    //     [nextVoucher] = cartVouchers;
    // }
    // console.log(vouchersSelected, nextVoucher);

    if(nextVoucher && checkVoucherConditions({ ...nextVoucher, conditions: nextVoucher.conditions.filter(condition => condition.key !== 'subtotal') }, user, userGroup, orders, cart)) {
        const nextVoucherStep = nextVoucher.conditions.filter(condition => condition.key === 'subtotal').reduce((step, condition) => Math.max(condition.value, step), 0);
        const nextVoucherProductAction = nextVoucher.actions.find(action => action.key === 'product');
        return (
            <div className="py-2">
                <span className="border-half-success d-block h5 mb-3 pb-2 text-center">{nextVoucher.name}</span>
                <Row>
                    {nextVoucherProductAction && (
                        <Col xs="12" md={{ span: 4, order: 2 }} lg={{ span: 3, order: 2 }}>
                            <div className="position-relative mt-2 mb-1 mb-md-0 text-center">
                                <img src={getFile(((products.find(product => product._id === nextVoucherProductAction.value) || {}).pictures || []).find(picture => picture.includes('jpg')), { width: 100 })} alt="Product offer" role="presentation" className="mw-100 w-75 w-md-100 h-auto rounded" width="150" height="100" loading="lazy" />
                                <span className="position-absolute h4 text-success text-uppercase d-none d-md-block" style={{ top: '-15px', right: '-15px' }}><Icon icon="gift" size="9x" /></span>
                            </div>
                        </Col>
                    )}
                    <Col md={{ span: nextVoucherProductAction ? 8 : 12, order: 1 }} lg={{ span: nextVoucherProductAction ? 9 : 12, order: 1 }} className="pt-3">
                        {nextVoucherStep > 0 && (
                            <div className="d-block text-center text-md-left">
                                <strong className="text-lg">
                                    {
                                        nextVoucherStep > cartTotals.items
                                        ? <FormattedMessage id="voucherProductOfferThreshold" defaultMessage="Only {amount} left to take advantage of the offer!" values={{ amount: <Price amount={nextVoucherStep - cartTotals.items} /> }} />
                                        : <FormattedMessage id="voucherProductOfferSuccess" defaultMessage="Great ! You get the offer!" />
                                    }
                                </strong>
                            </div>
                        )}
                        {nextVoucherStep > 0 && cartTotals.items > 0 && (
                            <ProgressBar
								animated
								striped
								variant="success"
								min="20"
								now={(cartTotals.items * 100) / nextVoucherStep}
								style={{ fontSize: '1rem', height: '2rem', lineHeight: '2rem' }}
								label={<span><strong className="text-lg"><Price amount={cartTotals.items} /></strong> / <Price amount={nextVoucherStep} /></span>}
                            />
                        )}

                    </Col>
                </Row>
                {
                    (displayConditions || cartTotals.items === 0 || nextVoucherStep === 0)
                    ? (
                        <div className="mt-2 ps-2 rounded" style={{ fontSize: '0.8rem', borderLeft: '4px solid var(--bs-success)' }}>
                            <HtmlComponent text={nextVoucher.description} />
                        </div>
                    ) : <em className="d-block text-end" onClick={setDisplayConditions}>{' '}<FormattedMessage id="learnMoreButton" defaultMessage="Learn more" /></em>
                }
            </div>
        );
    }
	if(process.env.NODE_ENV === 'development') {
		return <span>{nextVoucher?.label}: conditions {!checkVoucherConditions(nextVoucher, user, userGroup, orders, cart) && 'not'} checked</span>;
	}
    return null;
}

function mapStateToProps(store, props) {
    const user = getLoggedUser(store);
    return {
        user,
		userGroup: getUserGroup(store),
        cart: getCart(store),
        cartTotals: getCartTotals(store),
        cartVouchers: getCartVouchers(store),
        vouchers: getVouchers(store, true).filter(voucher => checkVoucherConditions({ ...voucher, conditions: voucher.conditions.filter(condition => condition.key !== 'subtotal') }, user, getUserGroup(store), user && getOrdersByUser(store, user._id, ['onpreparation', 'shipped', 'complete']), getCart(store))),
        products: memoizedGetProducts(store, { isActive: true }),
		orders: user ? getOrdersByUser(store, user._id) : [],
    };
}

VoucherProgress.propTypes = {
    dispatch: PropTypes.func.isRequired,
	user: PropTypes.object,
	userGroup: PropTypes.object,
    cart: PropTypes.object,
    cartTotals: PropTypes.object,
    cartVouchers: PropTypes.arrayOf(PropTypes.object),
    vouchers: PropTypes.arrayOf(PropTypes.object),
    products: PropTypes.arrayOf(PropTypes.object),
	orders: PropTypes.arrayOf(PropTypes.object),
};

export default connect(mapStateToProps)(VoucherProgress);
