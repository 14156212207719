const PageQuizModel = {
    type: 'page',
    name: 'quiz',
    blocks: [
        {
            name: 'name', // name or id; MUST be unique
            max: 1, // max same items allowed
            type: 'text', // || text || link || image || mixed (with children)
        },
        {
            name: 'dateStart', // name or id; MUST be unique
            max: 1, // max same items allowed
            type: 'text', // || text || link || image || mixed (with children)
        },
        {
            name: 'dateEnd', // name or id; MUST be unique
            max: 1, // max same items allowed
            type: 'text', // || text || link || image || mixed (with children)
        },
        {
            name: 'image', // name or id; MUST be unique
            max: 1, // max same items allowed
            type: 'image', // || text || link || image || mixed (with children)
        },
        {
            name: 'title', // name or id; MUST be unique
            max: 1, // max same items allowed
            type: 'text', // || text || link || image || mixed (with children)
        },
        {
            name: 'content',
            type: 'mixed',
            blocks: [
                {
                    name: 'text', // name or id; MUST be unique
                    max: 1, // max same items allowed
                    type: 'html', // || text || link || image || mixed (with children)
                },
                {
                    name: 'image',
                    type: 'image',
                    max: 1,
                },
                {
                    name: 'call-to-action',
                    type: 'link',
                    max: 1,
                },
                {
                    name: 'position',
                    type: 'select',
                    max: 1,
                    options: ['left', 'right'],
                },
            ],
        },
        {
            name: 'footer', // name or id; MUST be unique
            max: 1, // max same items allowed
            type: 'html', // || text || link || image || mixed (with children)
        },
        {
            name: 'conditions', // name or id; MUST be unique
            max: 1, // max same items allowed
            type: 'html', // || text || link || image || mixed (with children)
        },
        {
            name: 'confirmation', // name or id; MUST be unique
            max: 1, // max same items allowed
            type: 'html', // || text || link || image || mixed (with children)
        },
        {
            name: 'end', // name or id; MUST be unique
            max: 1, // max same items allowed
            type: 'html', // || text || link || image || mixed (with children)
        },
    ],
};

export default PageQuizModel;