// Import Actions
import { SET_PRODUCTS } from './ProductActions.js';
import { LOGOUT_USER } from '../User/UserActions.js';

// Initial State
export const initialState = {
    data: [],
};

const ProductReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_PRODUCTS:
            return {
                ...state,
                data: state.data.filter(existingProduct => !action.products.find(product => product._id === existingProduct._id)).concat(action.products),
            };

        case LOGOUT_USER:
            // return initialState;
            return state;

        default:
            return state;
    }
};

export default ProductReducer;
