import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Container, Row, Col, Card } from 'react-bootstrap';
import slugify from 'slugify';

import { getContentBlockItemsByName, getContentBlockItemByName, getContentBlockMixedItem } from '../../../CmsActions.js';

import Breadcrumbs from '../../../../App/components/view/Breadcrumbs.js';
import FileLoader from '../../../../../components/Content/FileLoader.js';
import CmsBlockItem from '../../content/CmsBlockItem.js';
import PreFooter from '../../../../App/components/PreFooter.js';

function PageMenu({ page }) {
    const image = getContentBlockItemByName(page.blocks, 'image');
    const title = getContentBlockItemByName(page.blocks, 'title');
    const contents = getContentBlockItemsByName(page.blocks, 'content');

    const titles = contents.map(content => getContentBlockMixedItem(content, 'title')).filter(title => title);

    return (
        <Fragment>
            <Container className="page-wrapper">
                {
                    image && image.path
                    ? (
                        <Card>
                            <FileLoader filename={image.path} loadonmount className="card-img" style={{ minHeight: '200px' }} />
                            <Card.ImgOverlay>
                                <Breadcrumbs crumbs={page && [page.title]} />
                            </Card.ImgOverlay>
                        </Card>
                    ) : <Breadcrumbs crumbs={page && [page.title]} />
                }
                <div className={classnames(image && image.path ? 'override-block-lg' : 'pt-3')}>
                    <Row>
                        {
                            titles && titles.length > 0 && (
                                <Col xs="12" md="3">
                                    <div className="p-1 shadow border">
                                        <div className="p-3 ps-4 bg-light">
                                            {contents && contents.length > 0 && (
                                                <ol>
                                                    {titles.filter(title => title).map((title, index) => <li key={slugify(title)}><a href={`#${slugify(title.text)}`}>{title.text}</a></li>)}
                                                </ol>
                                            )}
                                        </div>
                                    </div>
                                </Col>
                            )
                        }
                        <Col>
                            {title && <h1 className="text-dark">{title.text}</h1>}
                            {contents.map((content, index) => (
                                <div key={index} id={getContentBlockMixedItem(content, 'title') && slugify(getContentBlockMixedItem(content, 'title').text)} className={classnames('mb-5', index === 1 && 'critical-css-limiter')}>
                                    {getContentBlockMixedItem(content, 'title') && <h2>{getContentBlockMixedItem(content, 'title').text}</h2>}
                                    {getContentBlockMixedItem(content, 'text') && <CmsBlockItem item={getContentBlockMixedItem(content, 'text')} type="html" />}
                                    {getContentBlockMixedItem(content, 'image') && <div className="text-center"><CmsBlockItem item={getContentBlockMixedItem(content, 'image')} type="image" /></div>}
                                </div>
                            ))}
                        </Col>
                    </Row>
                </div>
            </Container>

            <PreFooter />
        </Fragment>
    );
}

PageMenu.propTypes = {
    page: PropTypes.object.isRequired,
};

export default PageMenu;
