import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import { USER_GROUP_DEFAULT, getUserGroup } from '../../../User/UserGroupActions';
import LazyLoader from '../../../../components/Content/LazyLoader';
import CmsPanel from '../../../Cms/components/content/CmsPanel';

function ProductShippingInformation({ userGroup }) {
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		setMounted(true);
	}, []);

	if(mounted && (!userGroup || userGroup.identifier === USER_GROUP_DEFAULT || process.env.NODE_ENV === 'development')) {
		return (
			<Container className="pt-0 pt-5 pb-5 px-0 px-lg-5 border-top">
				<CmsPanel slug="shipping-information" />
			</Container>
		);
	}
}

ProductShippingInformation.propTypes = {
	userGroup: PropTypes.object.isRequired,
};

function mapStateToProps(store, props) {
    return {
        userGroup: getUserGroup(store),
    };
}

export default connect(mapStateToProps)(ProductShippingInformation);
