import { createSelector } from 'reselect';
import callApi from '../../util/apiCaller.js';

import { defaultLanguage } from '../../../Intl/setup.js';

import { setIsFetching } from '../App/AppActions.js';
import { addError } from '../Error/ErrorActions.js';

export const SET_REVIEWS = 'SET_REVIEWS';

export const REVIEW_SCORE_MAX = 5;
const REVIEWS_FETCH_LIMIT = 1000;

// Requests
export function getReviewsRequest(status = null, onlyFirstItems = false) {
    const recursiveFetch = (limit = REVIEWS_FETCH_LIMIT, start = 0, reviews = []) => {
        return new Promise((resolve, reject) => {
            return callApi(`reviews${status ? `/${status}` : ''}?start=${start}&limit=${limit}`).then(res => {
                if(res.reviews && res.reviews.length) {
                    return onlyFirstItems ? resolve(res.reviews) : resolve(recursiveFetch(limit, start + limit, reviews.concat(res.reviews)));
                }
                resolve(reviews);
            }).catch(err => {
                reject(err);
            });
        });
    };

    return dispatch => {
        dispatch(setIsFetching('reviews'));
        return recursiveFetch().then(reviews => {
            dispatch(setReviews(reviews || []));
            return reviews;
        }).catch(err => {
            dispatch(addError(err));
        });
    };
}

export function getReviewsByUserRequest(userId = null) {
    return dispatch => {
        return callApi(`reviews/user/${userId || ''}`).then(res => {
            res.reviews && dispatch(setReviews(res.reviews));
            return res.reviews;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function getPublicReviewsRequest(language = '*', start = 0, limit = 12) {
    return dispatch => {
        return callApi(`reviews/public/${language}?start=${start * limit}&limit=${limit}`).then(res => {
            res.reviews && dispatch(setReviews(res.reviews));
            return res.reviews;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function getReviewSummaryRequest(language, productId = null, isQuestion = false) {
    return dispatch => {
        return callApi(`reviews/summary/${language}${productId ? `/${productId}` : ''}${isQuestion ? '?isQuestion=1' : ''}`).then(res => {
            return res;
        }).catch(error => {
            // dispatch(addError(error));
            return null;
        });
    };
}

export function editReviewRequest(review) {
    return dispatch => {
        return callApi('review/edit', 'post', { review }).then(res => {
            res.review && dispatch(setReviews([res.review]));
            return res.review;
        }).catch(error => {
            // dispatch(addError(error));
            return null;
        });
    };
}

export function addReviewRequest(review) {
    return dispatch => {
        return callApi('review/add', 'post', { review }).then(res => {
            res.review && dispatch(getReviewsByUserRequest(res.review.user));
            return res.review;
        }).catch(error => {
            // dispatch(addError(error));
            return null;
        });
    };
}

// GETTERS
export function getReviews(store, statuses = null) {
    if(statuses && !Array.isArray(statuses)) {
        statuses = [statuses];
    }
    return store.reviews.data.filter(review => !statuses || statuses.includes(review.status));
}

export const memoizedGetReviews = createSelector(
	[
		state => state.reviews.data,
		(store, statuses) => statuses,
	],
	(reviews, statuses) => reviews.filter(review => !statuses || statuses.includes(review.status)),
);

export function getReviewsByUser(store, userId) {
    return store.reviews.data.filter(review => !userId || getReviewUserId(review) === userId);
}

export function getReview(store, reviewId) {
    return store.reviews.data.find(review => review._id === reviewId);
}

export function getReviewUserId(review) {
    return review && review.user && review.user._id ? review.user._id : (review || {}).user;
}

export function getReviewStatuses() {
    return ['pending', 'validated', 'refused'];
}

export function getReviewStatusColor(status) {
    switch(status) {
        case 'refused':
            return 'danger';

        case 'validated':
            return 'primary';

        case 'pending':
        default:
            return 'warning';
    }
}

// Actions
export function setReviews(reviews) {
    return {
        type: SET_REVIEWS,
        reviews,
    };
}
