'use client';

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Slide, ToastContainer } from 'react-toastify';

function ToastProvider({ children }) {
    return (
        <Fragment>{children}<ToastContainer position="top-right" transition={Slide} offset={50} autoClose={10000} limit={5} /></Fragment>
    );
}

ToastProvider.propTypes = {
    children: PropTypes.any.isRequired,
};

export default ToastProvider;
