export function isMobile(check = 'userAgent') {
    if(typeof window !== 'undefined' && window.screen) {
        const screenCheck = Math.min(window.screen.width, window.screen.height) <= 576;
        const userAgentCheck = navigator && navigator.userAgent.indexOf('Mobi') > -1;
        if(check === 'screen') {
            return screenCheck || userAgentCheck;
        }
        return userAgentCheck || screenCheck;
    }
    return false;
}
