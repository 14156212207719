const PostDefaultModel = {
    type: 'post',
    name: 'default',
    slugPrefix: 'blog',
    blocks: [
        {
            name: 'breadcrumbs',
            type: 'link',
        },
        {
            name: 'image', // name or id; MUST be unique
            max: 2, // max same items allowed
            type: 'image', // || text || link || image || mixed (with children)
        },
        {
            name: 'title', // name or id; MUST be unique
            max: 1, // max same items allowed
            type: 'text', // || text || link || image || mixed (with children)
        },
        {
            name: 'date', // name or id; MUST be unique
            max: 1, // max same items allowed
            type: 'date', // || text || link || image || mixed (with children)
        },
        {
            name: 'tags', // name or id; MUST be unique
            type: 'text', // || text || link || image || mixed (with children)
        },
        {
            name: 'excerpt', // name or id; MUST be unique
            max: 1, // max same items allowed
            type: 'html', // || text || link || image || mixed (with children)
        },
        {
            name: 'content',
            type: 'mixed',
            blocks: [
                {
                    name: 'text', // name or id; MUST be unique
                    max: 1, // max same items allowed
                    type: 'html', // || text || link || image || mixed (with children)
                },
                {
                    name: 'image',
                    type: 'image',
                    max: 1,
                },
                {
                    name: 'call-to-action',
                    type: 'link',
                    max: 1,
                },
                {
                    name: 'promotion',
                    max: 1,
                    type: 'panel',
                },
                {
                    name: 'position',
                    type: 'select',
                    max: 1,
                    options: ['left', 'right'],
                },
            ],
        },
        {
            name: 'relatedProducts',
            type: 'product',
        },
		{
            name: 'relatedPanels',
            type: 'panel',
		},
    ],
};
export default PostDefaultModel;
