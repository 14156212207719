import { getPersistedData, persistData } from '../../util/persistor.js';
import { INIT_PERSISTED_DATA } from '../App/AppActions.js';
import { SWITCH_CURRENCY, SET_RATES, getEnabledCurrencies } from './CurrencyActions.js';

const initCurrency = 'EUR';
const enabledCurrencies = getEnabledCurrencies();

export const initialState = {
    currency: initCurrency,
    enabledCurrencies,
    rates: {},
};

const CurrencyReducer = (state = initialState, action = {}) => {
    switch(action.type) {
        case SWITCH_CURRENCY: {
            const newState = {
                ...state,
                currency: action.currency,
            };

            persistData('currency', newState);
			return newState;
		}

        case SET_RATES:
            return {
                ...state,
                rates: action.rates,
            };

		case INIT_PERSISTED_DATA: {
			const storedState = getPersistedData('currency', null);
			if(storedState) {
				return {
					...state,
					...storedState,
				};
			}
			persistData('currency', state);
            return state;
		}

        default:
            return state;
    }
};

export default CurrencyReducer;
