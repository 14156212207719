import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getPriceFromCent, getPriceCent } from '../../../../util/price.js';

import { getProductPrice } from '../../ProductActions.js';
import { getUserGroup } from '../../../User/UserGroupActions.js';

import Price from '../../../Currency/components/view/Price.js';
import { getReferral } from '../../../App/AppActions.js';
import { getLoggedUser, getUserOption } from '../../../User/UserActions.js';

function ProductCapacityPrice({ product, user = null, userGroup = null, hideLabel = false, referral = null }) {
    if(product.capacity && product.capacity.unit) {
        const { unit } = product.capacity;
        let { value } = product.capacity;
        if(unit === 'Kg' && !value) {
            value = product.weightNet || product.weight;
        }
        return (
            <span>{!hideLabel && <span>{unit === 'l' ? <FormattedMessage id="productCapacityPriceLiter" defaultMessage="Price per liter" /> : <FormattedMessage id="productCapacityPriceKg" defaultMessage="Price per Kg" />}: </span>}<span><Price amount={getProductPrice(product, 1, userGroup, !getUserOption(user, 'exemptVAT'), referral) / value} />/{unit}</span></span>
        );
    }
    return null;
}

function mapStateToProps(store, props) {
    return {
        user: getLoggedUser(store),
        userGroup: getUserGroup(store),
        referral: getReferral(store),
    };
}

ProductCapacityPrice.propTypes = {
    product: PropTypes.object.isRequired,
    user: PropTypes.object,
    userGroup: PropTypes.object,
    hideLabel: PropTypes.bool,
    referral: PropTypes.string,
};

export default connect(mapStateToProps)(ProductCapacityPrice);
