import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Col, Card } from 'react-bootstrap';

import { getFile } from '../../../../util/file.js';

import { getContentBlockItemByName, getContentBlockItemValue } from '../../../Cms/CmsActions.js';

import Icon from '../../../../components/Content/Icon.js';
import LazyLoader from '../../../../components/Content/LazyLoader.js';

// configReveal({ ssrFadeout: true });

function PanelCategory({ panel }) {
    const { blocks } = panel || {};
    const image = getContentBlockItemByName(blocks, 'image');
    const picto = getContentBlockItemByName(blocks, 'picto');
    const link = getContentBlockItemByName(blocks, 'link');

    if(link) {
		// const handleGoToCategory = event => link.path && navigate(link.path);
        return (
			<Card className="overflow-hidden mx-2 mx-md-0 mb-1 mb-md-4 pb-1" style={{ height: 'auto', minHeight: '280px' }}> {/* onClick={handleGoToCategory} */}
				{image && (
					<picture>
						<source media="(min-width: 800px)" srcSet={getFile(image.path)} className="card-img" width="600" height="400" loading="lazy" />
						<img src={getFile(image.path)} alt={image.text} className="card-img" width="300" height="280" loading="lazy" />
					</picture>
				)}
				<Card.ImgOverlay>
					<a href={link.path} className="d-block position-absolute text-white" style={{ bottom: '10%', left: '10%', width: '80%' }}>
						{picto && picto.path && (
							<Fragment>
								<img src={getFile(picto.path)} alt={picto.text} width="75" height="75" className="d-none d-md-inline mb-3" loading="lazy" />
								<img src={getFile(picto.path)} alt={picto.text} width="40" height="40" className="d-inline d-md-none mb-3" loading="lazy" />
							</Fragment>
						)}
						<br />
						{link && (
							<div className="position-relative">
								<span className={classnames('d-inline-block', 'pe-2', 'pe-md-0', 'w-lg-75', 'w-xl-50', 'h3', 'border-half-danger', (!picto || !picto.path) && 'mt-5')}>{link.text}</span>
								<div className="mb-3 position-absolute h5 w-25 text-end" style={{ bottom: 0, right: '-10px' }}>
									<div className="d-flex justify-content-between">
										<span><span className="d-none d-lg-inline-block me-1" style={{ lineHeight: '1.1rem' }}><FormattedMessage id="productsView" defaultMessage="View products" /> </span></span>
										<Icon icon="chevron-right" size="2x" />
									</div>
								</div>
							</div>
						)}
					</a>
				</Card.ImgOverlay>
			</Card>
		);
    }
    return null;
}

PanelCategory.propTypes = {
    // navigate: PropTypes.object.isRequired,
    // intl: PropTypes.object.isRequired,
    panel: PropTypes.object.isRequired,
};

export default PanelCategory;
