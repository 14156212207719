import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import config from '../../../../config';
import { getCountryFromLanguage, getEnabledLanguages, getLanguagePath } from '../../../Intl/IntlHelper';
import withRouter from '../../../../components/Router/WithRouter';
import { getContentBlockItemValue, getPageBySlugAndLanguage, getPanelBySlugAndLanguage, memoizedGetPanelBySlugAndLanguage } from '../../../Cms/CmsActions';
import { isCanonicalForced } from '../../AppActions';

function SeoHead({ location, search, intl, page = null, siteTitlePanel = null, siteDescriptionPanel = null, forceCanonical = false, appIsMobile = false }) {
	const siteTitle = siteTitlePanel && getContentBlockItemValue(siteTitlePanel.blocks, 'text');
	const isTitleTooShort = (siteTitle || '').length < 50;
	const siteDescription = siteDescriptionPanel && getContentBlockItemValue(siteDescriptionPanel.blocks, 'text');

	return (
		<Helmet
			defaultTitle={`${siteTitle || ''}${isTitleTooShort ? ` - ${config.application.name}` : ''}`}
			meta={[
				// { charset: 'utf-8' }, // Already set on ssr
				{
					'http-equiv': 'X-UA-Compatible',
					content: 'IE=edge',
				},
				{
					'http-equiv': 'content-language',
					content: intl.locale,
				},
				{
					name: 'viewport',
					content: 'width=device-width, initial-scale=1',
				},
				{
					name: 'robots',
					content: 'index, follow',
				},
				{
					name: 'description',
					content: siteDescription || intl.formatMessage({
						id: 'seoDescription',
						defaultMessage: 'Charcuterie, Direct producer',
					}),
				},
				{
					name: 'theme-color',
					content: '#003235',
				},
				{
					name: 'og:site_name',
					content: config.application.name,
				},
				{
					name: 'og:url',
					content: `${config.url}${location.pathname.substring(1)}`,
				},
				{
					name: 'og:type',
					content: 'website',
				},
				{
					name: 'og:locale',
					content: intl.locale,
				},
				{
					name: 'og:title',
					content: `${siteTitle}${siteTitle ? ' - ' : ''}${config.application.name}`,
				},
				{
					name: 'og:description',
					content: siteDescription || intl.formatMessage({
						id: 'seoDescription',
						defaultMessage: 'Charcuterie, Direct producer',
					}),
				},
				{
					name: 'og:image',
					content: `${config.url}assets/images/logo.png`,
				},
			]}
			link={[
				// {
				//     rel: 'canonical',
				//     href: `${config.url}${getLanguagePath(location.pathname, intl.locale).substring(1)}`,
				// }
				{
					rel: 'preload',
					href: `${config.url}assets/images/logo${appIsMobile ? '-D' : ''}.png`,
					as: 'image',
					fetchpriority: 'low',
				},
				// {
				// 	rel: 'preload',
				// 	href: `${config.url}assets/images/logo-D.png`,
				// 	as: 'image',
				// },
			].concat(
				['/user/', '/orders/', '/address', '/cart', '/page/'].some(route => location.pathname.includes(route))
				? getEnabledLanguages().map(language => ({
					href: `${config.url}${getLanguagePath(location.pathname, language).substring(1)}${location.search || ''}`,
					rel: 'alternate',
					hreflang: language,
					}))
					.concat(
						Object.keys(search).length
						? [{
							href: `${config.url}${getLanguagePath(location.pathname, intl.locale).substring(1)}`,
							rel: 'canonical',
						}]
						: [{
							href: '',
							rel: 'canonical',
						}],
					)
				: [],
			).concat(
				forceCanonical // applied to all pages (not only user/, orders/, cart/, /page)
				? [
					{
						href: `${config.url}${getLanguagePath(location.pathname, intl.locale).substring(1)}`,
						rel: 'canonical',
					},
				]
				: [],
			)}
		>
			<html lang={`${intl.locale}-${getCountryFromLanguage(intl.locale)}`} translate="no" />
			<body data-header-contrast={page ? 'true' : 'false'} />
		</Helmet>
	);
}

function mapStateToProps(store, props) {
	return {
		page: getPageBySlugAndLanguage(store, props.params.slug || props.params['*'], store.intl.locale),
        siteTitlePanel: memoizedGetPanelBySlugAndLanguage(store, 'site-title', (store.intl || {}).locale),
        siteDescriptionPanel: memoizedGetPanelBySlugAndLanguage(store, 'site-description', (store.intl || {}).locale),
        forceCanonical: isCanonicalForced(store),
		appIsMobile: store.app.isMobile,
	};
}

SeoHead.propTypes = {
	intl: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	search: PropTypes.object.isRequired,
	page: PropTypes.object,
    siteTitlePanel: PropTypes.object,
    siteDescriptionPanel: PropTypes.object,
    forceCanonical: PropTypes.bool,
    appIsMobile: PropTypes.bool,
};

export default withRouter(connect(mapStateToProps)(injectIntl(SeoHead)), ['location', 'search']);
