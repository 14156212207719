export default {
    type: 'panel',
    name: 'recommandation',
    blocks: [
        {
            name: 'cover',
            type: 'image',
			max: 2,
        },
        {
            name: 'title',
            max: 1,
            type: 'text',
        },
        {
            name: 'content', // name or id; MUST be unique
            max: 1, // max same items allowed
            type: 'html', // || text || link || image || mixed (with children)
        },
        {
            name: 'category',
            type: 'text',
        },
    ],
};
