import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider, injectIntl, FormattedMessage } from 'react-intl';
import Icon from '../../../components/Content/Icon.js';

import PageWrapper from '../../../components/Content/PageWrapper.js';
import AppLogo from '../../App/components/view/AppLogo.js';
import IntlWrapper from '../../Intl/IntlWrapper.js';

function BugPage({ error, search, locale, resetErrorBoundary }) {
    console.error(error);
    console.log('BugPage::: Displayed');

    useEffect(() => {
        if(typeof window !== 'undefined') {
            const params = new URLSearchParams(window.location.search);
            if(!params.get('reload') && process.env.NODE_ENV !== 'development') {
                setTimeout(() => {
                    let url = window.location.href;
                    const reloadParam = 'reload=1';
                    if(url.indexOf('?') > -1) {
                        url += `&${reloadParam}`;
                    } else {
                        url += `?${reloadParam}`;
                    }
                    window.location.href = url;
                }, 100);
            }
        }
    }, []);

    const handleGoToHome = () => window.open(`/${locale}/`, '_self');

    const handleReload = () => window.location.reload();
    return (
		<IntlWrapper>
			<div className="container-md page-wrapper bg-company text-center">
				<PageWrapper>
					<p>
						<AppLogo />
					</p>
					<div className="card mt-5 mb-5 shadow text-center">
						<div className="card-header">
							<h2 className="card-title">
								<Icon icon="danger" /> <FormattedMessage id="appBackHomeTitle" defaultMessage="Wrong Way..." />
							</h2>
						</div>
						<div className="card-bodypx-3">
							<FormattedMessage id="appBugErrorText" defaultMessage="Something went wrong.... SORRY ! Please click on the link below to reload this page..." /><br />
							<div className="btn-group btn-group-sm mt-2" role="group" aria-label="Basic example">
								<button type="button" className="btn btn-primary" onClick={handleGoToHome}><Icon icon="home" /> <FormattedMessage id="appHome" defaultMessage="Home" /></button>
								<button type="button" className="btn btn-outline-primary" onClick={handleReload}><Icon icon="sync" /> <FormattedMessage id="refresh" defaultMessage="Refresh" /></button>
								{false && <button type="button" className="btn btn-outline-primary" onClick={resetErrorBoundary}><Icon icon="sync" /> </button>}
							</div>
						</div>
					</div>
				</PageWrapper>
			</div>
		</IntlWrapper>
    );
}

function mapStateToProps(store, props) {
	return {
		locale: store.intl.locale,
	};
}

BugPage.propTypes = {
    search: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
    error: PropTypes.object.isRequired,
    resetErrorBoundary: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(BugPage);
